import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Address} from '../../../interfaces/address.interface';
// declare var google: any;
import { Loader } from '@googlemaps/js-api-loader';

const loader = new Loader({
  apiKey: 'AIzaSyCB0Q3cZ_Jbb8yiSHvtPAwnkx0Se3neF0Y',
  version: 'weekly',
  libraries: ['places']
});
@Component({
  selector: 'app-address-lookup',
  templateUrl: './address-lookup.component.html',
  styleUrls: ['./address-lookup.component.scss'],
  providers: []
})

export class AddressLookupComponent implements OnInit {
  @ViewChild('addressInput') addressInput: ElementRef<HTMLInputElement>;
  @ViewChild('line2Input') line2Input: ElementRef<HTMLInputElement>;
  @ViewChild('cityInput') cityInput: ElementRef<HTMLInputElement>;
  @ViewChild('stateInput') stateInput: ElementRef<HTMLInputElement>;
  @ViewChild('zipInput') zipInput: ElementRef<HTMLInputElement>;
  @ViewChild('mapElement') mapElement: ElementRef<HTMLInputElement>;
  @Input() saveOnNext = false;
  @Input() title: string;
  @Input() errors: any = {};
  @Input() mapEnabled = true;
  @Input() placeholderAddress: Address;
  @Output() onAddressChange = new EventEmitter<Address>();
  public place: any;
  public map: any;
  public marker: any;
  private autocompleteInput: HTMLInputElement;
  private mapElementInput: HTMLInputElement;
  private google: any;
  constructor(
    public toastr: ToastrService,
  ) {
    if (!this.placeholderAddress) {
      this.placeholderAddress = Address();
    }
  }
  async ngOnInit() {
    this.google = await loader.load();
    setTimeout(this.initApi.bind(this), 250);
  }
  initApi () {
    if (!this.addressInput || (this.autocompleteInput && this.autocompleteInput === this.addressInput.nativeElement)) {
      // already initialized
      return ;
    }
    this.initMap();
    const autocompleteInput = this.addressInput.nativeElement;
    this.autocompleteInput = autocompleteInput;
    const autocomplete = new this.google.maps.places.Autocomplete(autocompleteInput, {
      fields: ['address_components', 'geometry', 'name'],
      types: ['address'],
      componentRestrictions: { country: 'us' },
    });
    setTimeout(() => {
      const elems = document.querySelectorAll('.address-lookup-wrapper input');
      elems.forEach((input: any) => {
        // setting to "off" doesn't work on chrome, for now.
        input.autocomplete = Date.now();
      })
    }, 1000);
    autocomplete.addListener('place_changed', () => {
      if (this.marker) {
        this.marker.setVisible(false);
      }
      const place = autocomplete.getPlace();
      this.place = place;
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        this.toastr.error('Invalid address selected', 'Error!');
        return;
      }
      this.errors = {};
      this.renderAddress();
      this.emitAddress(true);
    });
  }
  initMap () {
    if (!this.mapEnabled || !this.mapElement || this.mapElementInput === this.mapElement.nativeElement) {
      // map disabled or already set up
      return ;
    }
    this.map = new this.google.maps.Map(this.mapElement.nativeElement, {
      zoom: 11,
      center: { lat: 37.4221, lng: -122.0841 },
      mapTypeControl: false,
      fullscreenControl: true,
      zoomControl: true,
      streetViewControl: true
    });
    this.mapElementInput = this.mapElement.nativeElement;
    this.marker = new this.google.maps.Marker({map: this.map, draggable: false});
  }
  renderAddress() {
    if (!this.map) {
      return ;
    }
    this.map.setCenter(this.place.geometry.location);
    this.map.setZoom(15);
    this.marker.setPosition(this.place.geometry.location);
    this.marker.setVisible(true);
  }
  clearAddress () {
    ['addressInput', 'cityInput', 'stateInput', 'zipInput', 'line2Input'].forEach((field) => {
      this[field].nativeElement.value = '';
    });
    this.onAddressChange.emit({
      line1: '',
      line2: '',
      city: '',
      state: '',
      zip: ''
    });
  }
  getError (field) {
    return this.errors[field] || this.errors['billingAddress,' + field] || this.errors['address,' + field];
  }
  /*
  * @Param {bool} overwriteInputs - if true, then autocomplete place object will overwrite all other inputs
  * */
  emitAddress (overwriteInputs = false) {
    if (!this.place || !this.place.address_components) {
      return this.clearAddress();
    }
    const addressNameFormat = {
      'street_number': 'short_name',
      'route': 'long_name',
      'locality': 'long_name',
      'administrative_area_level_1': 'long_name',
      'postal_code': 'short_name',
    };
    const getAddressComp = (type) => {
      for (const component of this.place.address_components) {
        if (component.types[0] === type) {
          return component[addressNameFormat[type]];
        }
      }
      return '';
    };
    let addressInfo;
    if (overwriteInputs) {
      addressInfo = {
        line1: getAddressComp('street_number') + ' ' + getAddressComp('route'),
        line2: this.line2Input.nativeElement.value,
        city: getAddressComp('locality'),
        state: getAddressComp('administrative_area_level_1'),
        zip: getAddressComp('postal_code'),
      }
    } else {
      // this allows users to change autocomplete results if something isn't right.
      addressInfo = {
        line1: getAddressComp('street_number') + ' ' + getAddressComp('route'),
        line2: this.line2Input.nativeElement.value,
        city: this.cityInput.nativeElement.value,
        state: this.stateInput.nativeElement.value,
        zip: this.zipInput.nativeElement.value,
      }
    }
    this.setAddressInputValues(addressInfo);
    this.onAddressChange.emit(addressInfo);
  }
  private setAddressInputValues (address: Address) {
    const {line1, line2, city, state, zip} = address;
    this.addressInput.nativeElement.value = line1;
    this.line2Input.nativeElement.value = line2;
    this.cityInput.nativeElement.value = city;
    this.stateInput.nativeElement.value = state;
    this.zipInput.nativeElement.value = zip;
  }
}
