import {Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter
} from 'rxjs/operators';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[appDebounce]'
})
export class DebounceDirective {
  @Input() minLength = 0;
  @Input() appDebounce = 250;
  @Output() public debouncedKeyUp = new EventEmitter<any>();
  el: ElementRef;
  constructor (el: ElementRef) {
    this.el = el;
  }
  ngOnInit() {
    fromEvent(this.el.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      })
      // if character length greater then 2
      , filter((res) => {
        // !length allows clearing the input
        return !res.length || res.length >= this.minLength;
      })

      // Time in milliseconds between key events
      , debounceTime(this.appDebounce)

      // If previous query is different from current
      , distinctUntilChanged()

      // subscription for response
    ).subscribe((text: string) => {
      this.debouncedKeyUp.emit(text);
    });
  }
}
