<section class="customer-register">
  <h1 style="text-align: center;" *ngIf="steps[step].title.length < 50">
    {{steps[step].title}}
  </h1>
  <h5 style="text-align: center; margin-top:2em" *ngIf="steps[step].title.length >= 50">
    {{steps[step].title}}
  </h5>
  <section class="wrapper" *ngIf="!submitting">
    <app-customer-form
      *ngIf="step === 'user'"
      [classes]="' full-page '"
      [customer]="customer"
      [deliveryCompanyId]="companyId"
      [loginExistingUser]="loginExistingUser"
      [userRole]="userRole"
      [validate]="true"
      [nextBtnText]="'Next'"
      (onNext)="next('tank')"
    >
    </app-customer-form>
    <div *ngIf="step === 'tank'">
      <app-customer-gas-tank
        *ngIf="product === 'propane'"
        [classes]="' full-page '"
        [customer]="customer"
        [userRole]="userRole"
        [nextBtnText]="'Next'"
        [saveOnNext]="false"
        [sizeInputDisabled]="tankSizeInputDisabled"
        (onNext)="next(!skipCC ? 'cc' : 'submit')"
      >
      </app-customer-gas-tank>
      <app-customer-tank
        *ngIf="product !== 'propane'"
        [classes]="' full-page '"
        [customer]="customer"
        [userRole]="userRole"
        [nextBtnText]="'Next'"
        [saveOnNext]="false"
        [sizeInputDisabled]="tankSizeInputDisabled"
        [askEstimatedTankLevel]="askEstimatedTankLevel"
        (onNext)="next(!skipCC ? 'cc' : 'submit')"
      >
      </app-customer-tank>
    </div>
    <app-stripe-card
      *ngIf="step === 'cc'"
      [achDiscount]="achDiscount"
      [customer]="customer"
      [nextBtnText]="'Next'"
      [skipCCEnabled]="skipCCEnabled"
      (onNext)="next('submit', $event)"
      (onSkip)="next('submit')"
    >
    </app-stripe-card>
  </section>
  <section class="wrapper" *ngIf="submitting">
    <br>
    <div style="text-align: center; margin-top: 4em;">
      <app-loader [loading]="true"></app-loader>
    </div>
  </section>
</section>
