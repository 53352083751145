<section class="cc-info {{classes}}" [class.has-title]="title" style="margin-top: 1em;">
  <div class="row">
    <div class="col-md-4 mt-4 mb-2"  data-toggle="tooltip" data-placement="top" title="We do not store your credit card information.  It is safely encrypted by a third party processing company.">
      <h6>{{title ? title : 'Billing Info'}} <span class="icon icn-information-circled"></span></h6>
    </div>
    <div class="col-md-8 mt-4 mb-2">
      <div>
        <img height="24px" class="d-inline-block float-right" src="assets/img/img-payment.svg" alt="">
        <a href="https://stripe.com/docs/security/stripe" target='_blank'>
          <img height="24px" class="d-inline-block float-right mr-3" src="assets/img/powered_by_stripe.svg" alt="">
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="skipCCEnabled" style="text-align: center;">You can do this later if you’d like.</div>
  <div class="billing-info">
    <div id="card-element">
      <!-- a Stripe Element will be inserted here. -->
    </div>

    <!-- Used to display Element errors -->
    <div *ngIf="cardError" id="card-errors" role="alert">{{cardError}}</div>
    <ng-container *ngIf="achDiscountEnabled">
      <hr>
      <div style="margin: 0.4em auto;cursor: pointer" (click)="connectPlaid()">
        Or click here to connect your bank account and save {{achDiscount.value | currency}} on your first order
      </div>
    </ng-container>
  </div>
  <button
    class="btn btn-default-dark no-radius btn-lg w-100"
    style="margin-top: 1em;"
    [disabled]="!cardIsValid"
    *ngIf="nextBtnText"
    (click)="emitCard()">
    {{nextBtnText}}
  </button>
  <button
    class="btn btn-default-dark no-radius btn-lg w-100"
    style="margin-top: 1em;"
    *ngIf="skipCCEnabled"
    (click)="skip()">
    {{skipBtnText}}
  </button>
</section>
