import {Component, Input, Output, EventEmitter} from '@angular/core';
interface Tile {
  label: string,
  value: any,
  routerLink?: string
}
@Component({
  selector: 'toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  providers: [],
})
export class ToggleSwitchComponent {
  @Input() value: boolean;
  @Output() onToggle = new EventEmitter();
  constructor() {}
  toggleSwitch () {
    this.value = !this.value;
    this.onToggle.emit(this.value);
  }
}
