import {Address} from './address.interface';
import {TankInspection} from './tank-inspection.interface';

type TankLocation = 'left' | 'back' | 'right' | 'front';
export interface Tank {
  id?: number,
  size: number | null,
  userId?: number,
  location: TankLocation,
  description: string,
  estimatedOilAmount: number | null,
  daysSinceChurned: number | null,
  manuallyAdjusted: boolean,
  estimatedAt?: string,
  createdAt?: string,
  updatedAt?: string,
  address?: Address,
  inspected?: boolean,
  tankInspection?: TankInspection,
}
export const Tank = (fieldValues?): Tank => {
  const tank: Tank = {
    size: 0,
    userId: null,
    location: 'left',
    description: '',
    estimatedOilAmount: null,
    daysSinceChurned: null,
    manuallyAdjusted: false,
    address: Address()
  };
  if (fieldValues) {
    Object.assign(tank, fieldValues)
  }
  return tank;
};
