import { Injectable } from '@angular/core';
import { API } from './api.provider';
export interface CustomerLead {
  id?: number;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  amount?: number;
  fill?: boolean;
  zip?: string;
}
@Injectable()
export class CustomerLeadProvider {
  constructor(
    private api: API
  ) {}
  getCustomerLead(token: string, companyId: number): Promise<CustomerLead> {
    return this.api.get(`/customer-lead/c/${companyId}/t/${token}`, {}) as any;
  }
  createCustomerLead(customer: CustomerLead): Promise<CustomerLead> {
    return this.api.post('/customer-lead', customer) as any;
  }
}
