import {Component, Input, OnInit} from '@angular/core';
import {GridAlgorithm, MarkerClusterer} from '@googlemaps/markerclusterer';
import { Loader } from '@googlemaps/js-api-loader';

const loader = new Loader({
  apiKey: 'AIzaSyCB0Q3cZ_Jbb8yiSHvtPAwnkx0Se3neF0Y',
  version: 'weekly',
  libraries: ['places']
});
export interface MapMarker {
  title: string,
  placeId: string,
  location: {
    lat: number,
    lng: number
  },
  info?: string;
  context?: any;
  mapMarker?: any; // google map Marker object
}
// declare var google;
// declare var MarkerClusterer;
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  providers: [],
})
export class MapComponent implements OnInit {
  private _markers: MapMarker[];
  private map: any;
  private mapMarkers = [];
  private bounds: any;
  private google: any;
  private markerCluster: MarkerClusterer;
  private populateMapTimeout;
  @Input() set markers (_markers: MapMarker[]) {
    this._markers = _markers;
    // remove previous markers, if any
    this.deleteMapMarkers();
    this.populateMap();
  }
  constructor() {}
  public async ngOnInit() {
    this.google = await loader.load();
  }

  public populateMap() {
    if (!this.google) {
      // retry until maps api finishes loading
      if (this.populateMapTimeout) {
        clearTimeout(this.populateMapTimeout);
      }
      this.populateMapTimeout = setTimeout(this.populateMap.bind(this), 250);
      return;
    }
    if (!this.map) {
      this.map = new this.google.maps.Map(document.getElementById('map-container'), {
        zoom: 12
      });
    }
    console.log('Google Map Created', document.getElementById('map-container'));
    this.bounds = new this.google.maps.LatLngBounds();
    this._markers.forEach((marker) => {
      if (!marker || !marker.location) {
        return ;
      }
      if (marker.mapMarker) {
        return ;
      }
      this.bounds.extend(marker.location);
      const mapMarker = new this.google.maps.Marker({
        position: marker.location,
        map: this.map,
        title: marker.title
      });
      marker.mapMarker = mapMarker;
      mapMarker.addListener('click', () => {
        if (marker.info) {
          const infoWindow = new this.google.maps.InfoWindow({
            content: marker.info,
          });
          infoWindow.open({
            anchor: mapMarker,
            map: this.map,
            shouldFocus: false,
          });
        }
        const currentZoom = this.map.getZoom();
        if (currentZoom < 12) {
          this.map.setZoom(12);
        } else if (currentZoom + 1 < 18) {
          this.map.setZoom(currentZoom + 1);
        }
        this.map.setCenter(mapMarker.getPosition());
      });
      this.mapMarkers.push(mapMarker);
      if (!this.markerCluster) {
        this.markerCluster = new MarkerClusterer({
          map: this.map,
          markers: this.mapMarkers,
          algorithm: new GridAlgorithm({}),
          renderer: {
            render: ({ count, position }) => {
              return new this.google.maps.Marker({
                label: { text: String(count), color: 'white', fontSize: '10px' },
                position,
                // adjust zIndex to be above other markers
                zIndex: Number(this.google.maps.Marker.MAX_ZINDEX) + count,
              })
            }
          }
        });
      } else {
        this.markerCluster.addMarkers(this.mapMarkers);
      }
    });
    this.map.fitBounds(this.bounds, 50);
    if (this.mapMarkers.length < 2) {
      this.map.setZoom(14);
    }
  }
  private deleteMapMarkers () {
    if (!this.mapMarkers) {
      return ;
    }
    if (this.markerCluster) {
      this.markerCluster.clearMarkers();
    }
    this.mapMarkers.forEach((marker) => {
      marker.setMap(null);
    });
    this.mapMarkers.splice(0);
  }
}
