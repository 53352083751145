import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { TitleCasePipe } from "@angular/common";
import { PhoneNumberPipe } from './pipes/phone-number.pipe';

import {TypeaheadModule} from 'ngx-bootstrap/typeahead';

// 3rd Party Modules
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { PaginationModule } from "ngx-bootstrap/pagination";


// Components
import { AppHeaderComponent } from "./components/app-header/app-header.component";
import { SidebarOverlayComponent } from "./components/sidebar-overlay/sidebar-overlay.component";
import { PeriodSelectorComponent } from "./components/period-selector/period-selector.component";
import { SearchComponent } from "./components/search/search.component";
import { TilesComponent } from "./components/tiles/tiles.component";
import { DataTableComponent } from "./components/data-table/data-table.component";
import { ProgressIndicatorComponent } from "./components/progress-indicator/progress-indicator.component";
import { TankInfoComponent } from "./components/tank-info/tank-info.component";

// Unverified (not used in current?) components etc.
import { AppHeaderService } from "./components/app-header/app-header.service";
import { ToggleSwitchComponent } from "./components/toggle-switch/toggle-switch.component";
import { OrderAmountsDropdownComponent } from "./components/order-amounts-dropdown/order-amounts-dropdown.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { NewOrderComponent } from "./components/new-order/new-order.component";
import { CustomerFormComponent } from "./components/customer-form/customer-form.component";
import { CustomerRegisterComponent } from "./components/customer-register/customer-register.component";
import { CustomerTankComponent } from "./components/customer-tank/customer-tank.component";
import { CustomerGasTankComponent } from "./components/customer-gas-tank/customer-gas-tank.component";
import { StripeCardComponent } from "./components/stripe-card/stripe-card.component";
import { StripeCheckoutComponent } from './components/stripe-checkout/stripe-checkout.component';
import { AddressLookupComponent } from "./components/address-lookup/address-lookup.component";
import { DebounceDirective } from "./directives/debounce.directive";
import { DefaultPipe } from "./pipes/default.pipe";
import { CapitalizeFirstLetterPipe } from "./pipes/capitalize-first-letter.pipe";
import { API } from "./providers/api.provider";
import { OrderProvider } from "./providers/order.provider";
import { ProductOrderProvider } from './providers/product-order.provider';
import { RouterModule } from "@angular/router";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import {TankLocationDropdownComponent} from './components/tank-location-dropdown/tank-location-dropdown.component';
import {OrderUtilsComponent} from './components/order-utils/order-utils.component';
import {AdditionalProductsDropdownComponent} from './components/additional-products-dropdown/additional-products-dropdown.component';
import {StripeCardSelectorComponent} from './components/stripe-card-selector/stripe-card-selector.component';
import {MapComponent} from './components/map/map.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {CompanyTerritoriesComponent} from './components/company-territories/company-territories.component';
import {TagsProvider} from './providers/tags.provider';
import {CustomerLeadProvider} from './providers/customer-lead.provider';
import {AffiliateProvider} from './providers/affiliate.provider';
import {ProductOrdersTableComponent} from './components/product-orders-table/product-orders-table.component';

const components = [
  AppHeaderComponent,
  SidebarOverlayComponent,
  PeriodSelectorComponent,
  SearchComponent,
  TilesComponent,
  DataTableComponent,
  ProgressIndicatorComponent,
  TankInfoComponent,
  TankLocationDropdownComponent,
  StripeCardSelectorComponent,
  AddressLookupComponent,
  OrderUtilsComponent,
  AdditionalProductsDropdownComponent,
  MapComponent,
  CompanyTerritoriesComponent,
  ProductOrdersTableComponent,
];

const pages = [];

@NgModule({
  declarations: [
    ...components,
    ...pages,

    // Unverified (not used in current?) components etc.
    DebounceDirective,
    ToggleSwitchComponent,
    OrderAmountsDropdownComponent,
    DefaultPipe,
    PhoneNumberPipe,
    CapitalizeFirstLetterPipe,
    NewOrderComponent,
    CustomerFormComponent,
    LoaderComponent,
    CustomerRegisterComponent,
    CustomerTankComponent,
    CustomerGasTankComponent,
    StripeCardComponent,
    StripeCheckoutComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TimepickerModule.forRoot(),
    PaginationModule.forRoot(),
    TypeaheadModule.forRoot(),
    ModalModule.forRoot(),
  ],
  providers: [
    API,
    AppHeaderService,
    OrderProvider,
    ProductOrderProvider,
    TitleCasePipe,
    TagsProvider,
    CustomerLeadProvider,
    AffiliateProvider,
  ],
  exports: [
    ...components,
    ...pages,

    // Unverified
    DebounceDirective,
    DefaultPipe,
    PhoneNumberPipe,
    CapitalizeFirstLetterPipe,
    NewOrderComponent,
    CustomerFormComponent,
    AddressLookupComponent,
    LoaderComponent,
    CustomerRegisterComponent,
    CustomerTankComponent,
    CustomerGasTankComponent,
    TankInfoComponent,
    TankLocationDropdownComponent,
    StripeCardComponent,
    StripeCheckoutComponent,
    OrderAmountsDropdownComponent,
    OrderUtilsComponent,
    AdditionalProductsDropdownComponent,
    StripeCardSelectorComponent,
    MapComponent,
    ProductOrdersTableComponent,
  ],
})
export class SharedModule {}
