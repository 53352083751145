<section class="address-lookup-wrapper">
  <div class="row">
    <div class="col-12" [class.col-sm-6]="mapEnabled">
      <div *ngIf="map && !title" style="margin-top: 2.4em;"></div>
      <div *ngIf="title" style="text-align: center">
        <img class="sb-title-icon" src="https://fonts.gstatic.com/s/i/googlematerialicons/location_pin/v5/24px.svg" alt="">
        <span class="sb-title">{{title}}</span>
      </div>
      <input
        id="location"
        class="form-control"
        type="text"
        placeholder="Address"
        autocomplete="disabled"
        #addressInput
        (focus)="initApi()"
        [class.error]="getError('line1')"
      >
      <input
        class="form-control"
        type="text"
        placeholder="Apt, Suite, etc (optional)"
        autocomplete="disabled"
        #line2Input
        (change)="emitAddress()"
        [class.error]="getError('line2')"
      >
      <input
        id="locality"
        class="form-control"
        type="text"
        placeholder="City"
        autocomplete="disabled"
        #cityInput
        (change)="emitAddress()"
        [class.error]="getError('city')"
      >
      <div class="half-input-container">
        <input
          id="administrative_area_level_1"
          type="text"
          class="form-control half-input"
          placeholder="State"
          autocomplete="disabled"
          #stateInput
          (change)="emitAddress()"
          [class.error]="getError('state')"
        >
        <input
          id="postal_code"
          type="text"
          class="form-control half-input"
          placeholder="Zip"
          style="max-width: 4.5em;"
          autocomplete="disabled"
          #zipInput
          (change)="emitAddress()"
          [class.error]="getError('zip')"
        >
      </div>
    </div>
    <div class="col-12 col-sm-6" *ngIf="mapEnabled">
      <div #mapElement class="map" id="map"></div>
    </div>
  </div>
</section>
