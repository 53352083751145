import {Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef} from '@angular/core';
import {ConfigProvider} from '../../../providers/config.provider';
@Component({
  selector: 'app-order-amounts-dropdown',
  templateUrl: './order-amounts-dropdown.component.html',
  styleUrls: ['./order-amounts-dropdown.component.scss'],
  providers: [ConfigProvider],
})
export class OrderAmountsDropdownComponent implements OnInit {
  @ViewChild('otherAmountInput', { static: false }) otherAmountInput: ElementRef;
  /*
  * @Input companyId - if provided, will load all order amounts from company config.
  * */
  @Input() initialValue: number | 'choose' | 'other';
  @Input() companyId: number;
  @Input() fillOptionEnabled = true;
  @Input() otherOptionEnabled = true;
  @Input() showOnlyEnabledOptions = true;
  public orderAmounts: Array<{ amount: number; enabled: boolean }> = [];
  @Output() onSelect = new EventEmitter();
  @Output() onOrderAmountsLoaded = new EventEmitter<{ amount: number; enabled: boolean }[]>();
  public value: any;
  public loadingAmounts = false;
  public otherOptionSelected = false;
  constructor(private configProvider: ConfigProvider) {}
  ngOnInit() {
    this.loadOrderAmounts();
  }
  onValueSelect (event) {
    if (this.value === 'other' || this.value === 'choose') {
      if (this.value === 'other') {
        this.otherOptionSelected = true;
        // remove value so user can type in custom value
        this.value = undefined;
        setTimeout(() => {
          if (this.otherAmountInput) {
            this.otherAmountInput.nativeElement.focus();
          }
        }, 150);
      }
      return;
    }
    this.onSelect.emit(this.value);
  }
  async loadOrderAmounts() {
    if (!this.companyId) {
      console.error('Load order amounts is missing parameter companyId');
      return;
    }
    this.loadingAmounts = true;
    try {
      const res: any = await this.configProvider
        .getOne({
          name: 'orderAmounts',
          companyId: this.companyId,
        });
      if (res.data && res.data.value) {
        if (this.showOnlyEnabledOptions) {
          this.orderAmounts = res.data.value.filter((amount) => amount.enabled);
        } else {
          this.orderAmounts = res.data.value;
        }
        this.onOrderAmountsLoaded.emit(this.orderAmounts);
      }
    } catch (error) {
      console.error('Could not load order amounts', error.message);
    }
    this.loadingAmounts = false;
    this.value = this.initialValue;
  };
}
