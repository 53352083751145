import { Injectable } from '@angular/core';
import { API } from './api.provider';
import { AuthProvider } from './auth.provider';
import { ApiListResponse } from '../../interfaces/api-list-response.interface';

@Injectable()
export class ProductOrderProvider {
  constructor(private api: API) {}

  post({companyId, productId, userId}: { companyId: number; productId: number; userId?: number; }) {
    return this.api.post(`/company/${companyId}/product-order`, {userId, productId});
  }
  get({companyId, orderId, intentId}: { companyId: number; orderId?: number; intentId?: string; }) {
    return this.api.get(`/company/${companyId}/product-order`, {intentId, orderId});
  }
  list(
    {companyId, keyword, startDate, endDate, customerId, _limit, _offset }:
    { companyId: number; keyword?: string; startDate?: any; endDate?: any; customerId?: number; _limit?: number; _offset?: number; }
  ) {
    return this.api.get(`/company/${companyId}/product-order/list`, {
      keyword,
      startDate,
      endDate,
      customerId,
      _limit,
      _offset,
    }) as Promise<ApiListResponse>
  }
  setStatus(
    {companyId, orderId, status, cancellationReason}:
    { companyId: number; orderId: number; status: string; cancellationReason?: string; }) {
    return this.api.patch(
      `/company/${companyId}/product-order/${orderId}/status/${status}`,
      {cancellationReason}
    );
  }
  setNotes(
    {companyId, orderId, notes}:
    { companyId: number; orderId: number; notes?: string; }) {
    return this.api.patch(
      `/company/${companyId}/product-order/${orderId}/notes`,
      {notes}
    );
  }
  /*
  * Sets shipping address for orderId. If no address info is provided, defaults to users billing/tank address.
  * */
  updateShippingAddress(
    {companyId, orderId, line1, line2, city, state, zip}:
    { companyId: number; orderId: number; line1?: string; line2?: string, city?: string, state?: string, zip?: string }) {
    return this.api.patch(
      `/company/${companyId}/product-order/${orderId}/shipping`,
      {line1, line2, city, state, zip}
    );
  }
}
