import { Component, OnInit, Input } from '@angular/core';
import {Company} from '../../../interfaces/company.interface';
import {Territory} from '../../../interfaces/territory.interface';
import {CompanyProvider} from '../../../providers/company.provider';
import {ToastrService} from 'ngx-toastr';
@Component({
  selector: 'company-territories',
  templateUrl: './company-territories.component.html',
  styleUrls: ['./company-territories.component.scss'],
  providers: [CompanyProvider],
})
export class CompanyTerritoriesComponent implements OnInit {
  @Input() company: any;
  public newTerritoryZip: string;
  public savingNewTerritory: boolean;
  public removingTerritories: boolean;
  public removingZones: boolean;
  public savingNewZone = false;
  public newZoneName = '';
  public selectedZone: any;
  constructor(
    private companyProvider: CompanyProvider,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
  }
  public addZipKeyUp (event) {
    if (event.keyCode === 13 || event.code === 'Enter') {
      this.addTerritory();
    }
  }
  public addTerritory () {
    const zip = this.newTerritoryZip;
    if (!zip || zip.length !== 5 || this.savingNewTerritory) {
      return ;
    }
    this.newTerritoryZip = '';
    this.savingNewTerritory = true;
    this.companyProvider.bindTerritory(this.company.id, zip).then((territory: Territory) => {
      if (territory && territory.id) {
        this.company.territories.push(territory);
        this.savingNewTerritory = false;
      }
    }).catch(() => {
      this.savingNewTerritory = false;
    });
  }
  public get territoriesPrimaryList () {
    if (!this.company || !this.company.territories) {
      return [];
    }
    if (this.selectedZone && this.selectedZone.id) {
      // split territories into those that are in selected zones and those that aren't
      return this.company.territories.filter(territory => territory.zoneId !== this.selectedZone.id);
    } else {
      // no sub-selection, return all territories
      return this.company.territories;
    }
  }
  public get territoriesInSelectedZone () {
    if (!this.company || !this.company.territories || !this.selectedZone || !this.selectedZone.id) {
      return [];
    }
    return this.company.territories.filter(territory => territory.zoneId === this.selectedZone.id);
  }
  public get selectedTerritories () {
    if (!this.company || !this.company.territories) {
      return [];
    }
    return this.company.territories.filter(territory => territory._selected);
  }
  public async removeSelectedTerritories () {
    const selectedTerritories = this.selectedTerritories.slice();
    if (!selectedTerritories.length || this.removingTerritories) {
      return;
    }
    this.removingTerritories = true;
    try {
      await Promise.all(selectedTerritories.map((territory) => {
        return this.companyProvider.unbindTerritory(this.company.id, territory.zip);
      }));
      this.company.territories = this.company.territories.filter((cTerritory) => {
        return selectedTerritories.indexOf(cTerritory) === -1;
      });
    } catch (error) {
      console.error('Failed to remove selected territories:', error);
    }
    this.removingTerritories = false;
  }
  public addZoneKeyUp (event) {
    if (event.keyCode === 13 || event.code === 'Enter') {
      this.addZone();
    }
  }
  public async addZone () {
    this.savingNewZone = true;
    try {
      const zone: any = await this.companyProvider.addZone({
        companyId: this.company.id,
        name: this.newZoneName,
        state: null
      });
      this.newZoneName = '';
      this.company.zones.push(zone);
    } catch (error) {
      console.error('Add zone error:', error);
    }
    this.savingNewZone = false;
  }
  public async removeSelectedZone () {
    if (!this.selectedZone || this.removingZones) {
      return;
    }
    this.removingZones = true;
    try {
      await this.companyProvider.deleteZone(this.company.id, this.selectedZone.id);
      this.company.zones = this.company.zones.filter((cZone) => {
        return cZone !== this.selectedZone;
      });
      this.selectedZone = undefined;
    } catch (error) {
      console.error('Remove selected zones error', error);
    }
    this.removingZones = false;
  }
  public async addZoneTerritories () {
    const selectedTerritories = this.selectedTerritories.slice();
    if (!this.selectedZone || !selectedTerritories.length || this.selectedZone._saving) {
      return;
    }
    this.selectedZone._saving = true;
    try {
      await Promise.all(selectedTerritories.map((territory) => {
        return this.companyProvider.addZoneTerritory({
          companyId: this.company.id,
          zoneId: this.selectedZone.id,
          territoryId: territory.id,
        }).then(() => {
          territory.zoneId = this.selectedZone.id;
          territory._selected = false;
        })
      }));
      this.toastr.success('Territory added to zone', 'Success!');
    } catch (error) {
      console.error('Adding selected territories to zones failed', error);
    }
    this.selectedZone._saving = false;
  }
  public async removeZoneTerritories () {
    const selectedTerritories = this.selectedTerritories.slice();
    if (!this.selectedZone || !selectedTerritories.length || this.selectedZone._saving) {
      return;
    }
    this.selectedZone._saving = true;
    try {
      await Promise.all(selectedTerritories.map((territory) => {
        return this.companyProvider.removeZoneTerritory({
          companyId: this.company.id,
          zoneId: this.selectedZone.id,
          territoryId: territory.id,
        }).then(() => {
          territory.zoneId = undefined;
          territory._selected = false;
        })
      }));
      this.toastr.success('Territory removed from zone', 'Success!');
    } catch (error) {
      console.error('Removing selected territories from zone failed', error);
    }
    this.selectedZone._saving = false;
  }
}
