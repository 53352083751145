<section class="order-page {{ currentPageName }}">
  <!--
    Overview of this Component flow:
      https://docs.google.com/drawings/d/1fVhG_XYCFgTefxUQbzJTyhjNDa0HDEdIU20M7XULNIk/edit?usp=sharing

    Collapse <section> tags for easier reading
    Order flow goes top down as:
      page-landing -> page-select-product(optional) -> page-enter-zip -> page-enter-amount
        -> page-company-offer -> page-ask-previous-order
          -> page-ask-phone-nr -> page-confirm-last-cc-digits
            -> page-confirm-personal-info -> page-order-summary -> page-order-completed

    If something goes wrong/customer has questions then alternative pages are:
    page-area-not-serviced <- when company does not deliver to customer address
    page-contact-company <- customer problem requires contacting the company
    page-additional-help <- user has finished ordering but still needs something
    page-not-sure <- user is uncertain about some input/info
    page-try-again <- user entered phone nr and cc number but does not recognize the matching user info
  -->

  <app-progress-indicator
    class="d-none d-sm-block"
    *ngIf="progressIndicatorVisible"
    [steps]="progressSteps"
    [currentStep]="currentStep"
  ></app-progress-indicator>

  <div
    *ngIf="company && company.logoUrl"
    id="company-logo"
    [style.background-image]="'url(' + company.logoUrl + ')'"
  ></div>
  <div style="text-align: center; position: relative">
    <app-loader [loading]="loading"></app-loader>
  </div>
  <section id="page-landing" *ngIf="page === pages.landing">
    <h3>How can we help you today?</h3>
    <button
      class="btn btn-default no-radius btn-lg w-100"
      (click)="startOrderProcess('checkPrice')"
    >
      Check Price
    </button>
    <!--button
      class="btn btn-default no-radius btn-lg w-100"
      (click)="startOrderProcess('orderAgain')"
    >
      Order Again
    </button-->
    <button
      class="btn btn-default no-radius btn-lg w-100"
      (click)="page = pages.contact_company"
    >
      Something Else
    </button>
  </section>
  <section id="page-select-product" *ngIf="page === pages.select_product">
    <h3>Select Your Option</h3>
    <div class="section-wrapper rounded">
      <table>
        <tbody>
          <tr *ngFor="let fuelProduct of enabledFuelProducts">
            <td>
              <label for="product-{{fuelProduct.name}}">{{fuelProduct.label}}</label>
            </td>
            <td>
              <input
                type="checkbox"
                id="product-{{fuelProduct.name}}"
                (change)="toggleFuelProduct(fuelProduct.name)"
                [checked]="selectedFuelProduct?.name === fuelProduct.name"
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <button
      [disabled]="!selectedFuelProduct"
      class="btn btn-default no-radius btn-lg w-100"
      (click)="page = pages.enter_zip"
    >
      Get My Price
    </button>
    <button class="btn btn-default no-radius btn-lg w-100" (click)="goBack()">
      Go Back
    </button>
  </section>
  <section id="page-enter-zip" *ngIf="page === pages.enter_zip">
    <h3>
      <span *ngIf="!loadingTerritories">What zip code do you need oil delivered to?</span>
      <span *ngIf="loadingTerritories">Loading...</span>
    </h3>
    <input
      id="zip-input"
      inputmode="numeric"
      class="form-control"
      type="text"
      title="enter zip code, i.e 01430"
      placeholder="5 Digit Zip"
      [(ngModel)]="deliveryZip"
    >
    <button
      class="btn btn-default no-radius btn-lg w-100"
      [disabled]="!isZipValid() || territoryNotServiced"
      (click)="goPageEnterAmount()"
    >
      Check Price
    </button>
    <button class="btn btn-default no-radius btn-lg w-100" (click)="goBack()">
      Go Back
    </button>
  </section>
  <section id="page-enter-amount" *ngIf="page === pages.enter_amount">
    <h3>
      Great, we deliver to your area!<br>
      How much {{selectedFuelProduct.label.toLowerCase() }} do you need?
    </h3>
    <div id="price-overview" *ngIf="pricing && pricing.ranges">
      <table>
        <tr>
          <th>Delivered Amount(gal)</th>
          <th>Price</th>
        </tr>
        <tr
          *ngFor="let range of pricing.ranges"
          [class.highlight]="getActiveRange() === range"
        >
          <td>
            {{ getPriceRangeText(range) }}
          </td>
          <td>
            <span
              >${{
                getPriceRangePPG(range, pricing.defaultPricePerGallon)
              }}</span
            >/gal
            <div *ngIf="isGreaterThan(range.flatFee, 0)">
              + ${{ range.flatFee }} fixed fee
            </div>
          </td>
        </tr>
      </table>
      <div style="text-align: center; margin-top: 1em; font-size: 0.9em">
        PLEASE NOTE: The price you will pay is determined by the DELIVERED amount.<br>
        FILL orders may not show the discounted price at checkout but will be adjusted automatically after the delivery.<br>
        Additionally, prices are set at the time of checkout not delivery.
      </div>
    </div>
    <div class="section-wrapper">
      <button
        style="margin-bottom: 0.4em"
        class="btn btn-default no-radius btn-lg w-100 inverted"
        *ngIf="order.orderedAmountType !== 'gallons'"
        (click)="onFillOptionSelect()"
      >
        Fill My Tank
      </button>
      <button
        class="btn btn-default no-radius btn-lg w-100 inverted"
        *ngIf="order.orderedAmountType !== 'gallons'"
        (click)="order.orderedAmountType = 'gallons'"
      >
        Other Amount
      </button>
      <section *ngIf="order.orderedAmountType === 'gallons'">
        <h6 style="text-align: center">Select Amount</h6>
        <div
          class="amount-option"
          *ngFor="let opt of orderAmounts"
          (click)="order.orderedAmount = opt.amount"
          [class.selected]="order.orderedAmount === opt.amount"
        >
          {{ opt.amount }} Gal
        </div>
      </section>
      <div class="error" *ngIf="orderAmountError">
        {{ orderAmountError }}
      </div>
      <button
        [disabled]="!order.orderedAmount"
        *ngIf="order.orderedAmountType === 'gallons'"
        class="btn btn-default no-radius btn-lg w-100"
        (click)="goPageCompanyOffer()"
      >
        Next
      </button>
      <button
        class="btn btn-default no-radius btn-lg w-100"
        (click)="
          order.orderedAmountType === 'gallons'
            ? (order.orderedAmountType = '')
            : goBack()
        "
      >
        Go Back
      </button>
    </div>
  </section>
  <section id="page-company-offer" *ngIf="page === pages.company_offer">
    <h3>
      Good news!<br>
      Here's your price:
    </h3>
    <div class="section-wrapper" style="text-align: center">
      We’ll
      <span *ngIf="order.orderedAmountType !== 'fill'">
      deliver {{order.orderedAmount}} gallons
      </span>
      <span *ngIf="order.orderedAmountType === 'fill'">
        FILL your tank
      </span>
        by {{ formatDate(deliveryDates.date, "MMMM Do") }}
      for an estimated total of {{ getEstimatedTotal() | currency }}
      <span *ngIf="order.orderedAmountType === 'fill'">
        , assuming order size of {{ order.orderedAmount }} gal
      </span>
      <button
        style="margin-bottom: 1em"
        class="btn btn-default no-radius btn-lg w-100"
        (click)="goPageUpsell()"
      >
        Great - let's do it!
      </button>
      <button
        class="btn btn-default no-radius btn-lg w-100"
        (click)="page = pages.delivery_options"
        *ngIf="companyOffersFasterDelivery()"
      >
        I need it sooner!
      </button>
      <button class="btn btn-default no-radius btn-lg w-100" (click)="goBack()">
        Go Back
      </button>
    </div>
  </section>
  <section id="page-delivery-options" *ngIf="page === pages.delivery_options">
    <h3>Select Your Option</h3>
    <div class="section-wrapper rounded">
      <app-additional-products-dropdown
        [alwaysOpen]="true"
        [products]="deliveryOptions"
        [hideDisabledOptions]="true"
      ></app-additional-products-dropdown>
    </div>
    <button
      class="btn btn-default no-radius btn-lg w-100"
      (click)="goPageUpsell()"
    >
      Yes - Let's Do It.
    </button>
    <button class="btn btn-default no-radius btn-lg w-100" (click)="goBack()">
      Go Back
    </button>
  </section>
  <section id="page-select-upsell" *ngIf="page === pages.select_upsell">
    <h3>Additional Products</h3>
    <div class="section-wrapper rounded">
      <app-additional-products-dropdown
        [alwaysOpen]="true"
        [products]="upsellProducts"
        [hideDisabledOptions]="true"
      ></app-additional-products-dropdown>
    </div>
    <button
      class="btn btn-default no-radius btn-lg w-100"
      (click)="goPageAskPreviousOrder()"
    >
      Continue
    </button>
    <button class="btn btn-default no-radius btn-lg w-100" (click)="goBack()">
      Go Back
    </button>
  </section>
  <section
    id="page-ask-previous-order"
    *ngIf="page === pages.ask_previous_order"
  >
    <h3>
      Awesome.<br>
      Have you ordered with us before?
    </h3>
    <button
      class="btn btn-default no-radius btn-lg w-100"
      (click)="page = pages.ask_phone_nr"
    >
      Yes
    </button>
    <button
      class="btn btn-default no-radius btn-lg w-100"
      (click)="page = pages.register"
    >
      No
    </button>
    <button class="btn btn-default no-radius btn-lg w-100" (click)="goBack()">
      Go Back
    </button>
  </section>
  <section id="page-register" *ngIf="page === pages.register">
    <app-customer-register
      [companyId]="companyId"
      [product]="selectedFuelProduct.name"
      [tankSizeInputDisabled]="true"
      [achDiscount]="achDiscount"
      (onRegisterSuccess)="customerRegistered($event)"
    >
    </app-customer-register>
    <button class="btn btn-default no-radius btn-lg w-100" (click)="goBack()">
      Go Back
    </button>
  </section>
  <section id="page-ask-phone-nr" *ngIf="page === pages.ask_phone_nr">
    <h3>
      Welcome back!<br>
      What phone number did you use?
    </h3>
    <div class="row">
      <div class="col-8">
        <input
          id="phone-input"
          class="form-control"
          type="tel"
          title="enter your phone number"
          placeholder="Phone Number"
          [(ngModel)]="customerPhoneNr"
        >
      </div>
      <div class="col-4">
        <button class="btn btn-default no-radius btn-md" (click)="checkPhoneNr()">
          Next
        </button>
      </div>
    </div>
    <button
      class="btn btn-default no-radius btn-lg w-100"
      (click)="page = pages.customer_not_sure"
    >
      I'm not sure...
    </button>
    <button
      class="btn btn-default no-radius btn-lg w-100"
      (click)="page = pages.email_login"
    >
      Login using email instead
    </button>
    <button class="btn btn-default no-radius btn-lg w-100" (click)="goBack()">
      Go Back
    </button>
  </section>
  <section
    id="page-confirm-last-cc-digits"
    *ngIf="page === pages.confirm_last_cc_digits"
  >
    <h3>Can you confirm the last 4 digits of the credit card on the file?</h3>
    <div class="row">
      <div class="col-8">
        <input
          id="cc-input"
          class="form-control"
          type="number"
          inputmode="numeric"
          title="enter last 4 digits of your credit card"
          placeholder="Last 4 digits"
          [(ngModel)]="customerCCDigits"
          (blur)="checkCCDigits()"
        >
      </div>
      <div class="col-4">
        <button class="btn btn-default no-radius btn-md" (click)="checkCCDigits()">
          Next
        </button>
      </div>
    </div>
    <button
      class="btn btn-default no-radius btn-lg w-100"
      (click)="page = pages.customer_not_sure"
    >
      I'm not sure...
    </button>
    <button class="btn btn-default no-radius btn-lg w-100" (click)="goBack()">
      Go Back
    </button>
  </section>
  <section
    id="page-confirm-personal-info"
    *ngIf="page === pages.confirm_personal_info"
  >
    <h3>Is this You?</h3>
    <div
      class="section-wrapper"
      style="margin: 1em 0; padding: 1em; font-size: 1.4em"
    >
      {{ customer.firstName }} {{ customer.lastName }}<br>
      <div *ngIf="customer.address">
        {{ customer.address.line1 }}<br>
        {{ customer.address.city }} {{ customer.address.state }}
        {{ customer.address.zip }}
      </div>
      <div *ngIf="!customer.address" class="error">
        <!-- ToDo: how possible is this scenario, ask for address ? -->
        Error: Your account is missing delivery address
      </div>
    </div>
    <button
      class="btn btn-default no-radius btn-lg w-100"
      (click)="showOrderSummary()"
    >
      Yes - That's correct
    </button>
    <button
      class="btn btn-default no-radius btn-lg w-100"
      (click)="handleWrongIdentity()"
    >
      No!
    </button>
    <button class="btn btn-default no-radius btn-lg w-100" (click)="goBack()">
      Go Back
    </button>
  </section>
  <section id="page-email-login" *ngIf="page === pages.email_login">
    <h3>
      Login using your email and password
    </h3>
    <div class="row">
      <div class="col-4">
        <label for="email-input">Email</label>
      </div>
      <div class="col-8">
        <input
          id="email-input"
          class="form-control"
          type="email"
          title="your email address"
          placeholder="Email Address"
          [(ngModel)]="loginEmail"
        >
      </div>
    </div>
    <div class="row" style="margin-top: 1em; margin-bottom: 1em;">
      <div class="col-4">
        <label for="email-input">Password</label>
      </div>
      <div class="col-8">
        <input
          id="pwd-input"
          class="form-control"
          type="password"
          title="enter your login password"
          placeholder="Password"
          [(ngModel)]="loginPwd"
        >
      </div>
    </div>
    <button
      class="btn btn-default no-radius btn-lg w-100"
      (click)="login()"
    >
      Login
    </button>
    <button class="btn btn-default no-radius btn-lg w-100" (click)="goBack()">
      Go Back
    </button>
  </section>
  <section id="page-setup-tank" *ngIf="page === pages.setup_tank">
    <!-- if a returning user switched fuel type and has not setup a tank yet -->
    <!-- ToDo: regular oil tank -->
    <app-customer-gas-tank
      *ngIf="selectedFuelProduct.name === 'propane'"
      [title]="'Please describe in detail where your tank is located, including any helpful hints! Please provide the color of your home and the closest cross street.'"
      [classes]="' full-page '"
      [customer]="customer"
      [userRole]="'customer'"
      [nextBtnText]="'Next'"
      [saveOnNext]="true"
      [sizeInputDisabled]="true"
      (onNext)="page = pages.order_summary"
    >
    </app-customer-gas-tank>
    <app-customer-tank
      *ngIf="selectedFuelProduct.name !== 'propane'"
      [title]="'Please describe in detail where your fill pipe is located.'"
      [classes]="' full-page '"
      [customer]="customer"
      [userRole]="'customer'"
      [nextBtnText]="'Next'"
      [saveOnNext]="true"
      [sizeInputDisabled]="true"
      (onNext)="page = pages.order_summary"
    >
    </app-customer-tank>
    <button class="btn btn-default no-radius btn-lg w-100" (click)="goBack()">
      Go Back
    </button>
  </section>
  <section id="page-order-summary" *ngIf="page === pages.order_summary">
    <h3>Okay, this look good?</h3>
    <div class="section-wrapper">
      <span *ngIf="order.orderedAmountType === 'fill'">
        We will <span class="highlight">FILL</span> your tank with
        {{ selectedFuelProduct.label.toLowerCase() }} located at
      </span>
      <span *ngIf="order.orderedAmountType === 'gallons'">
        We will deliver
        <span class="highlight">{{ order.orderedAmount }}/gal</span> of
        {{ selectedFuelProduct.label.toLowerCase() }} to
      </span>
      <span class="highlight">{{ customer.address.line1 }}</span>
      by the end of the day on {{ formatDate(deliveryDates.date, "MMMM Do") }}.
      <br>
      You'll pay
      <span class="highlight">
        {{ getPriceSummaryItem('companyPrice').pricePerGallon }}
      </span>
      /gal on your card ending in
      <span class="highlight">{{ customerCCDigits }}</span>.

      <br>
      <div *ngIf="order.orderedAmountType === 'fill'">
        <div>Please note: You will see (2) separate charges:</div>
        <div style="padding-left: 0.6em;">
          1) For {{ order.orderedAmount }} gallons now ({{ getPriceSummaryItem('companyPrice').sum | currency }}) and<br>
          2) The balance due after the delivery.<br>
             This charge will be made after we know how much oil your tank will take.<br>
             You will only pay for the oil delivered.<br>
          Please note: the price you pay is set at the time of purchase and not the delivery date, regardless of any price change either up or down.
        </div>
      </div>
      <span *ngIf="order.orderedAmountType !== 'fill'">
        <span *ngIf="getPriceSummaryItem('achDiscount').sum">
          Your One Time ACH Discount:
          {{getPriceSummaryItem('companyPrice').sum | currency}} -
          {{getPriceSummaryItem('achDiscount').sum | currency}} =
          {{getCompanyPriceWithDiscounts() | currency}}
        </span>
        <span *ngIf="!getPriceSummaryItem('achDiscount').sum">
          Estimated total: {{ getEstimatedTotal() | currency }}
        </span>
      </span>
      <div *ngIf="order.orderedAmountType !== 'fill'">
        Please note: the price you pay is set at the time of purchase and not the delivery date, regardless of any price change either up or down.
      </div>
    </div>
    <button
      [disabled]="submittingOrder"
      class="btn btn-default no-radius btn-lg w-100"
      (click)="submitOrder()"
    >
      <span *ngIf="!submittingOrder">Yes - That's correct</span>
      <span *ngIf="submittingOrder">Creating Order</span>
    </button>
    <button
      [disabled]="submittingOrder"
      class="btn btn-default no-radius btn-lg w-100"
      (click)="page = pages.contact_company"
    >
      No!
    </button>
    <button class="btn btn-default no-radius btn-lg w-100" (click)="goBack()">
      Go Back
    </button>
  </section>
  <section id="page-order-completed" *ngIf="page === pages.order_completed">
    <h3>Great? You're all set.</h3>

    <div class="section-wrapper" style="border: 0">
      We'll see you by the end of the day on
      {{ formatDate(deliveryDates.date, "MMMM Do") }}. <br>
      Is there anything else we can help you with?
    </div>
    <button
      class="btn btn-default no-radius btn-lg w-100"
      [routerLink]="'/customer/dashboard'"
    >
      No, I'm Good
    </button>
    <button
      class="btn btn-default no-radius btn-lg w-100"
      (click)="page = pages.additional_help"
    >
      Yes Please!
    </button>
  </section>

  <!--  Secondary pages: when user clicks No/needs to talk to the company for example -->
  <section id="page-area-not-serviced" *ngIf="page === pages.area_not_serviced">
    <div class="text-center section-wrapper">
      Sorry, but {{ company?.name ? company.name : '"Current"' }} hasn't reached
      your neighborhood yet!<br>
      Don't stress though...I'm sure we're coming soon. We'll let you know as
      soon as you can start saving!
    </div>
  </section>
  <section id="page-contact-company" *ngIf="page === pages.contact_company">
    <div class="section-wrapper">
      Looks like we may need to speak to you to help you out.<br>
      Please call us at
      <span class="highlight">
        <a href="tel:{{company.phone}}">{{ company.phone }}</a>
      </span> or
      email us at
      <span class="highlight">
        <a href="mailto:{{company.email}}">{{ company.email }}</a>
      </span>
    </div>
  </section>
  <section id="page-additional-help" *ngIf="page === pages.additional_help">
    <!-- shown when user has finished ordering but still wants something -->
    <div class="section-wrapper">
      We’d be happy to help you. <br>
      You can reach us at
      <span class="highlight">
        <a href="tel:{{company.phone}}">{{ company.phone }}</a>
      </span
      > or
      email us at
      <span class="highlight">
        <a href="mailto:{{company.email}}">{{ company.email }}</a>
      </span>
    </div>
  </section>
  <section id="page-not-sure" *ngIf="page === pages.customer_not_sure">
    <!-- shown when user is not sure about what to enter/has problems -->
    <div class="section-wrapper">
      No problem. <br>
      Looks like we probably should talk to you over the phone! <br>
      You can reach us at
      <span class="highlight">
        <a href="tel:{{company.phone}}">{{ company.phone }}</a>
      </span
      >.
    </div>
  </section>
  <section id="page-try-again" *ngIf="page === pages.customer_try_again">
    <!-- customer entered phone nr and 4 CC digits but did not recognize the matching user -->
    <div class="section-wrapper" *ngIf="wrongIdentityAttempts === 1">
      Hmmm.<br>
      Let’s try again.<br><br>
      <button
        class="btn btn-default no-radius btn-lg w-100"
        (click)="page = pages.ask_phone_nr"
      >
        Ok
      </button>
    </div>
    <div class="section-wrapper" *ngIf="wrongIdentityAttempts > 1">
      Sorry.<br>
      Looks like we can’t find your account.<br>
      You can call us at <span class="highlight">{{ company.phone }}</span> and
      we’d be happy to help!
    </div>
  </section>
  <section id="page-nav-to-frontend" *ngIf="page === pages.nav_to_frontend">
    <!-- shown for a few seconds before user is redirected to alternative checkout at frontend -->
    <div class="section-wrapper">
      Redirecting to new customer checkout in
      {{ redirectCountdown }}s<br><br>
    </div>
  </section>

  <app-progress-indicator
    style="margin-top: 1em;"
    class="d-block d-sm-none"
    *ngIf="progressIndicatorVisible"
    [steps]="progressSteps"
    [currentStep]="currentStep"
  ></app-progress-indicator>

  <section
    class="order-page__notes mt-2 mb-1"
    *ngIf="!contactPages.includes(page) && checkoutNotesConfig?.value"
    [innerHTML]="checkoutNotesConfig.value"
  >
  </section>
</section>
