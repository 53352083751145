import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-progress-indicator",
  templateUrl: "./progress-indicator.component.html",
  styleUrls: ["./progress-indicator.component.scss"],
})
export class ProgressIndicatorComponent implements OnChanges {
  @Input() steps: any[] = [];
  @Input() currentStep: number = 1;

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.steps.length) {
      let i = 0;
      for (const step of this.steps) {
        if (i === 0) {
          step.left = 0;
        } else {
          const part = 100 / this.steps.length;

          step.left = (i * (100 + part)) / this.steps.length;
        }

        i++;
      }
    }
  }
}
