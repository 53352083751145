<div class="input-group period-selector d-none d-md-flex {{classes}}" dropdown>
  <span class="input-group-text" dropdownToggle>
    {{selectedPeriodText}} <i class="fas fa-angle-down ms-2"></i>
  </span>
  <ul *dropdownMenu class="dropdown-menu">
    <li role="menuitem"><a class="dropdown-item" (click)="selectAllTime()">All time</a></li>
    <li role="menuitem"><a class="dropdown-item" (click)="selectCurrentMonth()">Current month</a></li>
    <li role="menuitem"><a class="dropdown-item" (click)="selectLastMonth()">Last month</a></li>
  </ul>
  <input
    type="text"
    class="form-control calendar"
    placeholder="Select period..."
    #daterangepicker="bsDaterangepicker"
    bsDaterangepicker
    [bsConfig]="{
      rangeInputFormat: 'MMM D, YYYY',
      showWeekNumbers: false,
      containerClass: 'theme-dark-blue',
      adaptivePosition: true
    }"
    (bsValueChange)="onDateChange($event)"
    [(ngModel)]="dateRange"
  />
</div>

<div class="mobile-period-selector d-md-none">
  <input
    type="text"
    class="form-control calendar"
    #daterangepicker="bsDaterangepicker"
    bsDaterangepicker
    [bsConfig]="{
      rangeInputFormat: 'MMM D, YYYY',
      showWeekNumbers: false,
      containerClass: 'theme-dark-blue'
    }"
    placeholder="Select period..."
  />
</div>
