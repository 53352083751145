import {Component, OnInit} from '@angular/core';
import {UserProvider} from '../../providers/user.provider';

import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {CompanyProvider} from '../../providers/company.provider';
import {CustomerLead, CustomerLeadProvider} from '../../shared/providers/customer-lead.provider';

@Component({
  selector: 'app-customer-lead',
  templateUrl: './customer-lead.component.html',
  styleUrls: ['./customer-lead.component.scss'],
  providers: [UserProvider, CompanyProvider]
})

export class CustomerLeadComponent implements OnInit {
  public customerLead: CustomerLead
  public loadingCustomer = false;
  public customerLeadExpired = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private customerLeadProvider: CustomerLeadProvider
  ) {

  }
  ngOnInit(): void {
    this.route.paramMap.subscribe(async (params) => {
      const token = params.get('token');
      const companyId = parseInt(params.get('companyId'), 10);
      this.loadingCustomer = true;
      try {
        this.customerLead = await this.customerLeadProvider.getCustomerLead(token, companyId);
        if (!this.customerLead) {
          this.customerLeadExpired = true;
        }
      } catch (error) {
        console.log('lead error', error.message);
        this.customerLeadExpired = true;
      }
      this.loadingCustomer = false;
    });
  }
}
