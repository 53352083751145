import {Component, Injector, OnInit} from '@angular/core';
import { CustomerProvider } from "../../../providers/customer.provider";
import { CompanyProvider } from "../../../providers/company.provider";
import { AuthProvider } from "../../../shared/providers/auth.provider";
import { Customer } from "../../../interfaces/customer.interface";
import { Company } from "../../../interfaces/company.interface";
import { Territory } from "../../../interfaces/territory.interface";
import { AppHeaderService } from "../../../shared/components/app-header/app-header.service";
import { OrderProvider } from "../../../shared/providers/order.provider";
import { TerritoryProvider } from "../../../shared/providers/territory.provider";
import { PriceProvider } from "../../../shared/providers/price.provider";
import { ConfigProvider } from "../../../providers/config.provider";
import { Router } from '@angular/router';
import { UserProvider } from '../../../providers/user.provider';
import {OrderUtilsComponent, FuelProductName, FuelProduct} from '../../../shared/components/order-utils/order-utils.component';


@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.scss"],
  providers: [
    OrderProvider,
    PriceProvider,
    TerritoryProvider,
    CustomerProvider,
    CompanyProvider,
    ConfigProvider,
    UserProvider,
  ],
})
export class OrderComponent extends OrderUtilsComponent implements OnInit {
  /*
   * List of sub-pages, each asking 1-2 questions or displaying a line of text
   * Component flow chart: https://docs.google.com/drawings/d/1fVhG_XYCFgTefxUQbzJTyhjNDa0HDEdIU20M7XULNIk/edit?usp=sharing
   * */
  public pages = {
    landing: 1,
    enter_zip: 2,
    enter_amount: 3, // <- ask for how many gallons
    company_offer: 4, // <- show price per gallon offered and delivery date
    order_summary: 5,
    area_not_serviced: 6,
    ask_phone_nr: 7,
    ask_previous_order: 8,
    confirm_last_cc_digits: 9,
    confirm_personal_info: 10,
    order_completed: 11,
    contact_company: 12,
    additional_help: 13,
    customer_not_sure: 14,
    customer_try_again: 15,
    nav_to_frontend: 16,
    delivery_options: 17,
    select_product: 18,
    register: 19, // if customer answer "No" to the question "Have you ordered with us before"
    setup_tank: 20, // returning user who switched fuel type needs to go through this step before order summary
    email_login: 21,
    select_upsell: 22
  };
  /* pages that show contact info */
  public contactPages = [
    this.pages.area_not_serviced,
    this.pages.contact_company,
    this.pages.additional_help,
    this.pages.customer_not_sure,
  ];
  private _page = this.pages.landing;
  private history: number[] = [];
  public landingPageSelection: string;
  public orderAmountError: string;
  public territories: Territory[];
  public territory: Territory; // <- matching user zip
  public loadingTerritories = false;
  public territoryNotServiced = false; // set to true if user entered zip returns no territory
  public customerPhoneNr: string;
  public customerCCDigits: number;
  public loading: boolean | Promise<any>;
  public wrongIdentityAttempts = 0;
  public redirectCountdown = 4;
  public orderAmounts: Array<{ amount: number; enabled: boolean }> = [];
  public loginEmail = '';
  public loginPwd = '';
  public progressSteps = [
    { label: "Price Check" },
    { label: "View Offer" },
    { label: "Confirm Delivery" },
  ];
  public selectedAutoFill = false;
  constructor(
    private headerService: AppHeaderService,
    private territoryProvider: TerritoryProvider,
    private customerProvider: CustomerProvider,
    private router: Router,
    private authProvider: AuthProvider,
    private injector: Injector,
  ) {
    super(injector);
    this.route.params.subscribe((data: any) => {
      this.companyId = parseInt(data.companyId, 10);
      if (!this.companyId) {
        this.toastr.error(
          "Error: Invalid oil company - check your URL",
          "Whoops!"
        );
      } else {
        this.loadOrderAmounts();
        this.loading = this.loadCompany(this.companyId);
      }
    });
  }
  ngOnInit() {
    super.ngOnInit();
  }
  get progressIndicatorVisible () {
    return [
      this.pages.area_not_serviced,
      this.pages.area_not_serviced,
      this.pages.additional_help,
      this.pages.customer_not_sure,
      this.pages.contact_company,
    ].includes(this._page) === false;
  }
  get currentStep () {
    if (!this.priceSummary || !this.priceSummary.name) {
      // still collecting info for "check price"
      return 0;
    }
    return this._page === this.pages.order_summary || this._page === this.pages.order_completed ? 2 : 1;
  }
  get currentPageName() {
    return Object.keys(this.pages).filter((pageName) => {
      return this.pages[pageName] === this.page;
    })[0];
  }
  get page() {
    return this._page;
  }
  set page(value) {
    this.history.push(this._page);
    this._page = value;
  }
  toggleFuelProduct (productName: FuelProductName) {
    if (productName === 'propane') {
      this.order.orderedAmount = 150; // Propane is always fill/150
      this.order.orderedAmountType = 'fill';
    }
    if (this.selectedFuelProduct && this.selectedFuelProduct.name === productName) {
      return this.selectedFuelProduct = undefined;
    }
    this.selectedFuelProduct = this.getFuelProduct(productName);
    this.order.product = this.selectedFuelProduct.name;
    this.loadPriceConfig(productName);
  }
  goBack() {
    if (!this.history.length) {
      return;
    }
    this._page = this.history.pop();
  }
  async login() {
    localStorage.removeItem('JWT');
    try {
      const response: any = await this.userProvider.signIn({
        email: this.loginEmail,
        password: this.loginPwd,
        companyId: this.companyId,
      });
      if (!response.err) {
        this.authProvider.setToken(response.data.token);
        const user: Customer = await this.userProvider.getUserInfo() as Customer;
        if (user.role) {
          this.customer = user;
          this.authProvider.setUser(user);
          const cards: any = await this.userProvider.getUserCards();
          const defaultCard = cards && cards.list ? cards.list.filter(c => c.isDefault)[0] : undefined;
          if (!defaultCard) {
            this.toastr.error('Your account is missing credit card information', 'Error!');
            setTimeout(() => {
              // user can order from dashboard - after filling in CC info
              this.router.navigate(['customer/dashboard']);
            }, 3000);
            return;
          } else {
            this.customerCCDigits = defaultCard.last4;
            // order summary will ask for more info if needed, then proceed to payment
            this.showOrderSummary();
          }
        } else {
          this.toastr.error('Invalid user, please contact us for more info', 'Error!');
          setTimeout(() => {
            this.page = this.pages.additional_help;
          }, 3000);
        }
      } else {
        this.toastr.error('Invalid username or password', 'Error!');
      }
    } catch (error) {
      this.toastr.error('Login failed: ' + error.message, 'Error!');
    }
  }
  loadOrderAmounts() {
    return this.configProvider
      .getOne({
        name: "orderAmounts",
        companyId: this.companyId,
      })
      .then((res: any) => {
        if (res.data && res.data.value) {
          this.orderAmounts = res.data.value.filter((amount) => amount.enabled);
        }
      });
  }

  startOrderProcess(selection: string) {
    // if company offers only 1 product, skip the product select screen
    // otherwise select product first
    this.landingPageSelection = selection;
    if (selection === "orderAgain") {
      this.page = this.pages.ask_phone_nr;
      return;
    }
    if (this.enabledFuelProducts.length > 1) {
      return (this.page = this.pages.select_product);
    }
    this.selectedFuelProduct = this.enabledFuelProducts[0];
    this.loadPriceConfig(this.selectedFuelProduct.name);
    this.page = this.pages.enter_zip;
  }
  getPriceRangePPG(range, defaultPPG) {
    return this.isGreaterThan(range.pricePerGallon, 0)
      ? parseFloat(range.pricePerGallon).toFixed(2)
      : parseFloat(defaultPPG).toFixed(2);
    // const flatFee = !Number.isNaN(parseFloat(range.flatFee)) ? parseFloat(range.flatFee) : 0;
  }
  getPriceRangeText(range) {
    return parseFloat(range.max) !== Infinity
      ? range.min + ' to ' + range.max
      : range.min + ' and up';
  }
  onFillOptionSelect() {
    // user clicked "Fill My Tank" when selecting order amount
    this.order.orderedAmount = this.fillOrderAmount;
    this.order.orderedAmountType = "fill";
    this.goPageCompanyOffer();
  }
  goPageAskPreviousOrder() {
    if (this.customer && this.customer.id) {
      // customer account is already set up
      // skip asking about previous order and skip customer setup steps.
      this.showOrderSummary();
      return;
    }
    this.page = this.pages.register;
    // ToDo: we currently disable ask previous order due to confusion on customer side
    // this.page = this.pages.ask_previous_order;
  }
  async goPageUpsell () {
    await this.getAdditionalOptions({
      companyId: this.getCompanyId(),
      zip: this.deliveryZip,
    });
    // getAdditionalOptions populates upsellProducts list.
    if (!this.upsellProducts.filter(p => p.enabled).length) {
      // skip upsell item selection since there are no options enabled
      this.goPageAskPreviousOrder();
    } else {
      this.page = this.pages.select_upsell;
    }
  }

  /*
   * If customer didnt have an account yet, then they were asked to setup a new account
   * on "register" page. This function is called once their account is set up and order
   * flow can continue.
   * Note: New Customer will skip 3 pages
   *   ask phone number
   *   enter credit card digits
   *   confirm personal info
   * */
  async customerRegistered(event: { customer: Customer; last4CCDigits: number }) {
    this.customer = event.customer;
    this.customerPhoneNr = event.customer.phone;
    this.customerCCDigits = event.last4CCDigits;
    if (this.customer._token) {
      // log user in
      this.authProvider.setToken(this.customer._token);
      this.authProvider.setUser(this.customer);
    }
    // update price since customer registration may add ach discount.
    await this.checkPrice();
    this.page = this.pages.order_summary;
  }
  async goPageEnterAmount() {
    // checks if user zip area is serviced by the company.
    // If yes, navigates to the next page where user can fill in order amount.
    // If no, user is shown area not serviced page.
    this.loadingTerritories = true;
    this.territoryNotServiced = false;
    try {
      this.loading = this.territoryProvider.getCompanyTerritories(
        this.companyId
      );
      const res: { list: Territory[] } = await this.loading;
      this.loadingTerritories = false;
      this.territory = undefined;
      if (res && res.list && res.list.length) {
        this.territory = res.list.filter(
          (territory) => territory.zip === this.deliveryZip
        )[0];
        this.territories = res.list;
      }
      this.territoryNotServiced = !this.territory;
    } catch (err) {
      this.loadingTerritories = false;
    }
    if (!this.territoryNotServiced) {
      // reload price config based on zip/zone input
      await this.loadPriceConfig(this.selectedFuelProduct.name);
      // propane tanks are always filled, no need to ask for amount
      if (this.selectedFuelProduct.name === 'propane') {
        this.goPageCompanyOffer();
      } else {
        this.page = this.pages.enter_amount;
      }
    } else {
      this.page = this.pages.area_not_serviced;
    }
  }

  /*
   * Check that all the pre-requisite data has been entered.
   * If not, send user back to the page where the missing
   * data is collected.
   * If all data is collected, show the user "order summary"
   * */
  async showOrderSummary() {
    if (
      !this.deliveryZip &&
      this.customer &&
      this.customer.address &&
      this.customer.address.zip
    ) {
      // prefill order zip if we know customers' address
      this.deliveryZip = this.customer.address.zip;
    }
    if (!this.selectedFuelProduct) {
      if (this.enabledFuelProducts.length > 1) {
        // company offers more than 1 product, so user needs to select one.
        this.page = this.pages.select_product;
        return;
      } else {
        // set product to the only thing that the company offers
        this.selectedFuelProduct = this.enabledFuelProducts[0];
      }
    }
    // Check if user has not switched fuel types, and that they have set up
    // an appropriate fuel tank.
    const productName = this.selectedFuelProduct.name;
    if (
      ((productName === 'oil' || productName === 'offsite_diesel') && !this.customer.tank) ||
      (productName === 'propane' && !this.customer.gasTank)
    ) {
      return (this.page = this.pages.setup_tank);
    }
    if (!this.deliveryZip) {
      this.page = this.pages.enter_zip;
      return;
    }
    if (
      this.customer.id &&
      (!this.order.orderedAmount || !this.order.orderedAmountType)
    ) {
      // user hasn't entered order amount info - send them back to that stage.
      this.page = this.pages.enter_amount;
      return;
    }
    // check price again - user may have selected additional delivery options
    // which would have changed pricing
    // update delivery dates( user may have chosen optional rush delivery options )
    try {
      this.loading = true;
      this.priceSummary = await this.checkPrice();
      this.deliveryDates = (await this.getDeliveryDate()) as any;
      this.loading = false;
      this.page = this.pages.order_summary;
    } catch (error) {
      this.loading = false;
      this.toastr.error("Error: Could not load price data", "Whoops!");
    }
  }

  async goPageCompanyOffer() {
    // check price/date and ask confirmation
    this.orderAmountError = this.isOrderAmountValid()
      ? ""
      : "Order amount must be between 100 and 300";
    if (this.orderAmountError) {
      console.error("invalid order amount at goPageCompanyOffer");
      return;
    }
    this.loading = true;
    try {
      await this.getDeliveryOptions({
        companyId: this.company.id,
        zip: this.deliveryZip
      });
      this.deliveryOptions.forEach((option) => {
        option.label = option.label.replace('Delivery', '');
      });
      this.loading = false;
      this.priceSummary = await this.checkPrice();
      this.deliveryDates = (await this.getDeliveryDate()) as any;
      this.page = this.pages.company_offer;
      await this.loadPriceConfig(this.selectedFuelProduct.name);
    } catch (error) {
      this.toastr.error("Error: Could not load price data", "Whoops!");
      this.loading = false;
    }
  }

  parsePhoneNr(phone) {
    if (!phone) {
      return;
    }
    phone = phone.replace(/\-/g, "").replace(/\./g, "").replace(/\s/g, "");
    if (phone.length === 10) {
      phone = "+1" + phone;
    }
    return phone;
  }

  async checkPhoneNr() {
    try {
      this.loading = this.customerProvider.getCustomerByPhone({
        phone: this.parsePhoneNr(this.customerPhoneNr),
      });
      const customer: Customer = await this.loading;
      if (customer && customer.id) {
        this.page = this.pages.confirm_last_cc_digits;
      } else {
        this.toastr.error(
          "Error: No previous orders found for this phone number",
          "Whoops!"
        );
      }
    } catch (error) {
      console.error("Check phone number error", error);
    }
  }

  async checkCCDigits() {
    try {
      this.loading = this.customerProvider.getCustomerByLastCCDigits({
        phone: this.parsePhoneNr(this.customerPhoneNr),
        ccDigits: this.customerCCDigits,
      });
      const response: any = await this.loading;
      const customer: Customer = response.customer;
      const token = response.token;
      this.authProvider.setToken(token);
      this.authProvider.setUser(customer);
      if (customer && customer.id) {
        this.customer = customer;
        this.page = this.pages.confirm_personal_info;
      } else {
        this.toastr.error("Error: No matching CC found", "Whoops!");
      }
    } catch (error) {
      console.error("Check credit card digits error", error);
    }
  }

  async submitOrder() {
    try {
      await super.submitOrder();
      this.page = this.pages.order_completed;
    } catch (err) {
      console.error(err);
      this.submittingOrder = false;
    }
  }
  /*
   * User has entered phone number + 4 CC digits
   * but they do not recognize the matching user.
   * Ask them to reenter the phone + CC once more.
   * If it still returns wrong persons' info - contact company
   * */
  handleWrongIdentity() {
    this.wrongIdentityAttempts += 1;
    this.page = this.pages.customer_try_again;
    if (this.wrongIdentityAttempts === 1) {
      setTimeout(() => {
        if (this.page === this.pages.customer_try_again) {
          // navigate customer to phone nr page if they haven't done
          // so themselves already
          this.page = this.pages.ask_phone_nr;
        }
      }, 3000);
    }
  }
}
