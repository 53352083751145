import { Component, OnInit } from '@angular/core';
import { AuthProvider } from '../../../shared/providers/auth.provider';
import { AffiliateProvider } from '../../../shared/providers/affiliate.provider';
import { ActivatedRoute } from '@angular/router';
import { API } from '../../../shared/providers/api.provider';
import { ToastrService } from 'ngx-toastr';
import { Affiliate } from '../../../interfaces/affiliate.interface';

@Component({
  selector: 'app-affiliate-setup',
  templateUrl: './affiliate-setup.component.html',
  styleUrls: ['./affiliate-setup.component.scss'],
  providers: [AffiliateProvider, AuthProvider],
})
export class AffiliateSetupComponent implements OnInit {
  public errors: any = {};
  public loading = false;
  public affiliate: Affiliate = {
    name: '',
    email: '',
    phone: '',
    user: {
      firstName: '',
      lastName: '',
      password: '',
      phone: '',
      email: '',
      status: 'unverified',
      role: 'affiliate',
      active: false,
    }
  };
  public confirmPassword: string;
  constructor(
    private route: ActivatedRoute,
    private api: API,
    private toastr: ToastrService,
    private authProvider: AuthProvider,
    private affiliateProvider: AffiliateProvider,
  ) {}

  public ngOnInit() {}
  public async saveAffiliate() {
    this.errors = {};
    this.affiliate.user.password = this.affiliate.user.password.trim();
    this.confirmPassword = this.confirmPassword.trim();
    if (this.affiliate.user.password !== this.confirmPassword) {
      this.errors.password = 'Passwords do not match';
      return this.toastr.error('Passwords do not match', 'Error!');
    }
    this.affiliate._saving = true;
    try {
      this.affiliate = await this.affiliateProvider.create({
        name: this.affiliate.name,
        phone: this.affiliate.phone,
        email: this.affiliate.email,
        firstName: this.affiliate.user.firstName,
        lastName: this.affiliate.user.lastName,
        password: this.affiliate.user.password,
      });
      this.toastr.success('Account setup successful. Redirecting to Stripe', 'Success!');
      await this.stripeConnect();
    } catch (error) {
      this.errors = error.error && error.error.errors ? error.error.errors : {};
    }
    this.affiliate._saving = false;
  }
  private async stripeConnect() {
    this.loading = true;
    this.errors = {};
    try {
      const response: any = await this.api.post('/stripe/connect/affiliate', {
        affiliateId: this.affiliate.id,
      });
      if (response && response.data.redirectUrl) {
        this.toastr.success('Redirecting to Stripe', 'Success!');
        setTimeout(() => {
          window.location.href = response.data.redirectUrl;
        }, 2000);
      } else {
        this.toastr.error(
          'Error: Something went wrong. Please try again later.'
        );
      }
    } catch (error) {
      this.errors = error.error && error.error.errors ? error.error.errors : {};
    }
    this.loading = false;
  }
}
