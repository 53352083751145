import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.scss"],
})
export class DataTableComponent {
  @Input() tableClasses = '';
  @Input() loading = true;
  @Input() minWidth = 1000;
  @Input() showEmptyTable = false; // if true, we show "No data to be displayed" instead of empty table headers
  @Input() expandedTemplate: any = null;
  @Input() expandedColspan = 5;
  @Input() headers: Array<{
    name: string;
    field?: string;
    template?: any;
    headerTemplate?: any;
    headerContext?: any;
    isLink?: boolean;
    title?: string;
    class?: string;
    style?: Object;
    currency?: boolean;
    number?: boolean;
    capitalizeFirst?: boolean;
    click?: any;
    sort?: '' | 'asc' | 'desc';
    sortable?: boolean;
    date?: string;
  }> = [];
  @Input() rows: any[] = [];
  @Input() pagination: {
    currentPage: number,
    pageSize: number,
    total: number,
  };
  @Output() onCellClick = new EventEmitter<{ event: any, field: string, row: any }>();
  @Output() onPageChange = new EventEmitter<any>();
  @Output() onSort = new EventEmitter<any>();
  sortedHeaders: Array<any> = [];
  private previousPage: {
    page: number;
    itemsPerPage: number;
  };
  constructor() {
    this.loading = false;
  }
  cellClicked (event, field, row) {
    this.onCellClick.next({event, field, row});
  }
  pageChanged (event) {
    if (
      this.previousPage &&
      (this.previousPage.page === event.page && this.previousPage.itemsPerPage === event.itemsPerPage)
    ) {
      // don't emit when nothing has actually changed.
      // angular if/loading may trigger pageChange even if nothing has changed
      return ;
    }
    this.previousPage = event;
    this.onPageChange.emit(event);
  }
  onHeaderClick (header) {
    if (typeof header.click === 'function') {
      return header.click(header);
    }
    if (header.sortable) {
      if (header.sort === 'desc') {
        header.sort = undefined;
      } else {
        header.sort = header.sort === 'asc' ? 'desc' : 'asc';
      }
      const sortIndex = this.sortedHeaders.indexOf(header);
      if (!header.sort) {
        this.sortedHeaders.splice(sortIndex, 1);
      } else if (sortIndex === -1) {
        this.sortedHeaders.push(header);
      }
      this.onSort.next(this.sortedHeaders.map(({field, sort}) => {
        return {field, sort};
      }));
    }
  }
}
