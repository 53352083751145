import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AffiliateRoutingModule } from './affiliate-routing.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { SharedModule } from '../../shared/shared.module';

import { AffiliateSetupComponent } from '../pages/affiliate-setup/affiliate-setup.component';
import { AffiliateDashboardComponent } from '../pages/dashboard/affiliate-dashboard.component';
const pages = [
  AffiliateSetupComponent,
  AffiliateDashboardComponent,
];
const components = [];
@NgModule({
  declarations: [
    ...pages,
    ...components,
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    AffiliateRoutingModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
  ],
  providers: [],
  exports: [],
})
export class AffiliateModule {}
