<section class="company-territories-wrapper">
  <div class="territories">
    <div class="w-100 text-center mb-3 mt-1">
      <h3>Territory</h3>
    </div>
    <div class="zip-codes">
      <div
        class="zip"
        [class.selected]="territory._selected"
        *ngFor="let territory of territoriesPrimaryList"
        (click)="territory._selected = !territory._selected"
      >
        {{territory.zip}}
      </div>
      <div class="zip input">
        <span *ngIf="savingNewTerritory">Saving </span>
        <app-loader [loading]="savingNewTerritory" [style]="'width: 1.4em; height: 1.4em;'"></app-loader>
        <input
          *ngIf="!savingNewTerritory"
          id="new-zip-code"
          type="text"
          inputmode="numeric"
          placeholder="add zip"
          [(ngModel)]="newTerritoryZip"
          (keyup)="addZipKeyUp($event)"
          (blur)="addTerritory()"
        >
      </div>
    </div>
    <h4 *ngIf="selectedZone?.name" style="margin-top: 1em;">
      {{!territoriesInSelectedZone.length ? 'No' : ''}}
      Territories in {{selectedZone.name}}
    </h4>
    <div *ngIf="selectedZone?.id" class="zip-codes">
      <div
        class="zip"
        [class.selected]="territory._selected"
        *ngFor="let territory of territoriesInSelectedZone"
        (click)="territory._selected = !territory._selected"
      >
        {{territory.zip}}
      </div>
    </div>
    <hr>
    <button
      class="btn btn-warning"
      [disabled]="!selectedTerritories.length || removingTerritories"
      (click)="removeSelectedTerritories()"
    >
      {{removingTerritories ? 'Removing Territories...': 'Remove Selected Territories'}}
    </button>
    <button
      class="btn btn-default-dark"
      *ngIf="selectedZone"
      [disabled]="!selectedTerritories.length"
      (click)="addZoneTerritories()"
    >
      {{removingTerritories ? 'Adding Territories...': 'Add To ' + selectedZone.name}}
    </button>
    <button
      class="btn btn-default-dark"
      *ngIf="selectedZone"
      [disabled]="!selectedTerritories.length"
      (click)="removeZoneTerritories()"
    >
      {{removingTerritories ? 'Removing Territories...': 'Remove From ' + selectedZone.name}}
    </button>
  </div>
  <div class="territories" style="margin-top: 4em;">
    <div class="w-100 text-center mb-3 mt-1">
      <h3>Zone</h3>
    </div>
    <div class="zones">
      <div
        class="zone"
        [class.selected]="selectedZone === zone"
        *ngFor="let zone of company?.zones"
        (click)="selectedZone = selectedZone === zone ? undefined : zone"
      >
        {{zone.name}}
      </div>
      <div class="zone input">
        <span *ngIf="savingNewZone">Saving </span>
        <app-loader [loading]="savingNewZone" [style]="'width: 1.4em; height: 1.4em;'"></app-loader>
        <input
          *ngIf="!savingNewZone"
          id="new-zone-name"
          type="text"
          inputmode="text"
          placeholder="add zone"
          [(ngModel)]="newZoneName"
          (keyup)="addZoneKeyUp($event)"
          (blur)="addZone()"
        >
      </div>
    </div>
    <button
      class="btn btn-warning"
      [disabled]="!selectedZone || removingZones"
      (click)="removeSelectedZone()"
    >
      {{removingZones ? 'Removing Zone...': 'Remove Selected Zone'}}
    </button>
  </div>
</section>
