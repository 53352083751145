export const environment = {
  requireAuth: false,
  production: false,
  apiURL: 'https://dev.api.kingfishtech.com',
  frontendUrl: 'https://dev.api.kingfishtech.com',
  Stripe : {
    API_KEY : 'pk_test_JUn4NQdB4auBibMhBnlLnioz',
  },
  Plaid : {
    PUBLIC_KEY : 'bf2d2f17b5e25e087a57311e64b659'
  },
};
