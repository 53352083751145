import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserProvider} from '../../../providers/user.provider';
import {AuthProvider} from '../../providers/auth.provider';
import {Customer} from '../../../interfaces/customer.interface';
import {ToastrService} from 'ngx-toastr';
import {OrderProvider} from '../../providers/order.provider';
import {Card} from '../../../interfaces/card.interface';
import {FuelProductName} from '../order-utils/order-utils.component';

@Component({
  selector: 'app-customer-register',
  templateUrl: './customer-register.component.html',
  styleUrls: ['./customer-register.component.scss'],
  providers: [UserProvider]
})

export class CustomerRegisterComponent implements OnInit {
  @Input() achDiscount;
  @Input() public skipCCEnabled = false;
  @Input() loginExistingUser = false;
  @Input() public skipCC = false;
  @Input() public askEstimatedTankLevel = false;
  @Input() public tankSizeInputDisabled = false;
  @Input() public companyId: number;
  @Input() public product: FuelProductName;
  @Input()
  public userRole: 'admin' | 'customer' | 'company'; // who is currently filling the form
  @Input() public customer: Customer = Customer();
  @Output()
  public onRegisterSuccess = new EventEmitter<{ customer: Customer, last4CCDigits: number }>();
  @Output()
  public onRegisterError = new EventEmitter<Error>();
  public step: 'user' | 'tank' | 'cc' | 'submit' = 'user';
  public steps = {
    user: {title: 'Setup your account', name: 'user'},
    tank: {
      title: 'Please describe in detail where your fill pipe is located.',
      name: 'tank'
    },
    cc: {title: 'Payment Information', name: 'cc'},
    submit: {title: 'Setting up account', name: 'submit'}
  }
  public cardInfo: {card: any, token: any};
  public submitting = false;
  constructor(
    private userProvider: UserProvider,
    private toastr: ToastrService,
    private orderProvider: OrderProvider,
    public authProvider: AuthProvider,
  ) {
  }
  ngOnInit () {
    if (!this.product) {
      // sets up oil tank by default
      this.product = 'oil';
    }
  }
  /*
  * submit step is called with event {card, token}
  * */
  next (step, event?: any) {
    this.step = step;
    if (step === 'submit') {
      return this.submit(event);
    }
  }
  private getAddressPayload () {
    // pick only relevant fields( excluding id, created_at etc )
    const address = this.customer.address;
    return {
      line1: address.line1,
      line2: address.line2,
      city: address.city,
      zip: address.zip,
      state: address.state,
    }
  }
  async submit (event?: any) {
    let card: any;
    let token: any;
    if (event) {
      // event is false if user skipped CC step
      card = event.card;
      token = event.token;
    }
    try {
      this.submitting = true;
      this.cardInfo = card;
      const tank: any = this.product === 'propane' ? this.customer.gasTank : this.customer.tank;
      const address = this.getAddressPayload();
      const tankPayload: any = {
        type: this.product === 'propane' ? 'gas' : 'oil',
        size: tank.size,
        location: tank.location,
        description: tank.description,
        address,
      }
      if (tank.estimatedOilAmount) {
        tankPayload.estimatedOilAmount = tank.estimatedOilAmount;
      }
      if (tank.id) {
        tankPayload.id = tank.id;
      }
      if (tankPayload.type === 'gas') {
        // add propane specific fields
        tankPayload.groundLevel = tank.groundLevel;
        tankPayload.gas = this.product;
        tankPayload.ownership = tank.ownership;
      }
      tank.address = address;
      const payload: any = {
        email: this.customer.email,
        firstName: this.customer.firstName,
        lastName: this.customer.lastName,
        phone: this.customer.phone,
        sendSms: true,
        billingAddress: address,
        deliveryCompanyId: this.companyId,
        tank: tankPayload,
      };
      if (typeof this.customer.sendSms !== 'undefined') {
        payload.sendSms = this.customer.sendSms;
      }
      let customer: Customer;
      if (this.customer.id) {
        delete payload.deliveryCompanyId;
        if (token) {
          payload._stripe = {
            token: token.id
          };
        }
        if (this.customer.password && this.customer.nonce) {
          payload.password = this.customer.password;
          payload.nonce = this.customer.nonce;
        }
        customer = await this.userProvider.updateCustomer(this.customer.id, payload);
      } else {
        if (token) {
          payload._stripe = {
            token: token.id
          };
        }
        payload._activationUrl = window.location.origin + '/customer/setup/password';
        customer = await this.userProvider.addCustomer(payload);
      }
      if (!card && customer.cards) {
        card = customer.cards.filter(c => c.isDefault)[0];
        console.log(' customer.cards',  customer.cards, 'card', card);
      }
      this.onRegisterSuccess.next({
        customer,
        last4CCDigits: card ? card.last4 : ''
      });
      this.submitting = false;
    } catch (error) {
      this.onRegisterError.next(error);
      this.submitting = false;
      this.step = 'cc';
      console.error('Customer register error:', error);
    }
  }
}
