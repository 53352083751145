<section class="tiles-wrapper">
  <div class="row">
    <div class="col-12 col-md-6 col-xl-3" *ngFor="let tile of _tiles">
      <div
        [attr.title]="tile.title"
        class="tile-item {{tileClass}}"
        [ngClass]="{link: tile.routerLink}"
        [routerLink]="tile.routerLink"
        [queryParams]="tile.queryParams ? tile.queryParams : {}"
      >
        <div class="header {{headerClass}}">
          {{ tile.label }}
        </div>

        <div class="body {{valueClass}}" style="{{valueStyle}}">
          {{ tile.isCurrency ? (tile.value | currency) : tile.value }}
        </div>
      </div>
    </div>
  </div>
</section>
