<div class="modal" data-backdrop="static" data-keyboard="false" id="{{id}}" tabindex="-1" role="dialog" aria-labelledby="confirmModal" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body text-center">
        <h4 class="mt-4 mb-4">Confirm Your action</h4>
        <p>
        	{{confirmText}}
        </p>

      <button type="button" class="btn btn-primary btn-block mt-4" (click)="submit()">Confirm</button>
      <button type="button" class="btn btn-block mt-4" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </div>
</div>
