import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { AuthProvider } from '../../../shared/providers/auth.provider';
import { AffiliateProvider, AffiliateDashboard } from '../../../shared/providers/affiliate.provider';
import { ActivatedRoute } from '@angular/router';
import { API } from '../../../shared/providers/api.provider';
import { ToastrService } from 'ngx-toastr';
import { Affiliate, AffiliateLink } from '../../../interfaces/affiliate.interface';
import { User } from '../../../interfaces/user.interface';
import {CompanyProvider} from '../../../providers/company.provider';
import {Company} from '../../../interfaces/company.interface';
import {BsModalService} from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-affiliate-dashboard',
  templateUrl: './affiliate-dashboard.component.html',
  styleUrls: ['./affiliate-dashboard.component.scss'],
  providers: [AffiliateProvider, AuthProvider, CompanyProvider],
})
export class AffiliateDashboardComponent implements OnInit {
  @ViewChild('nameColumn') nameColumn: ElementRef<any>;
  @ViewChild('companyInfoModalTemplate', { static: true }) companyInfoModalTemplate;
  public companyInfoModalRef: any = null;
  public user: User;
  public affiliate: Affiliate | undefined;
  public tiles: Array<{ label: string; value: any; routerLink?: string, isCurrency?: boolean }> = [];
  public loading = true;
  public dashboard: AffiliateDashboard | undefined;
  public tableHeaders: any[];
  public tableRows: any[];
  public company: Company;
  public loadingCompany = false;
  constructor(
    private route: ActivatedRoute,
    private api: API,
    private toastr: ToastrService,
    private authProvider: AuthProvider,
    private affiliateProvider: AffiliateProvider,
    private companyProvider: CompanyProvider,
    private modalService: BsModalService,
  ) {}

  public async ngOnInit() {
    this.user = this.authProvider.getUser();
    this.dashboard = await this.affiliateProvider.getDashboard(this.user.affiliate.id);
    this.createTiles();
    this.tableHeaders = [
      {name: 'Company', field: 'label', template: this.nameColumn},
      {name: 'Link', field: 'url'},
      {name: 'Total Earnings', field: 'earnings', currency: '$'},
      {name: 'Current Reward', field: '_rewardSummary', capitalizeFirst: true},
      {name: 'Clicks', field: 'clicks'},
    ];
    this.tableRows = [];
    if (this.dashboard.affiliateLinks) {
      let minReward;
      let maxReward: any = 0;
      const platformLink = this.dashboard.affiliateLinks[0];
      const platformReward = platformLink.reward;
      const platformRewardReoccurring = platformLink.reward;
      this.dashboard.affiliateLinks.slice(1).forEach(({reward}) => {
        if (typeof minReward === 'undefined' || minReward > reward) {
          minReward = reward;
        }
        if (typeof maxReward === 'undefined' || maxReward < reward) {
          maxReward = reward;
        }
      });
      minReward = (minReward || 0).toFixed(2);
      maxReward = maxReward.toFixed(2);
      this.tableRows = this.dashboard.affiliateLinks.map((link, index) => {
        const row: AffiliateLink = {
          id: link.id,
          label: link.label.charAt(0).toUpperCase() + link.label.slice(1),
          reward: link.reward,
          rewardReoccurring: link.rewardReoccurring,
          url: link.url,
          earnings: link.earnings,
          clicks: link.clicks,
          _rewardSummary: '',
        };
        if (!index) {
          row._rewardSummary = '$' + row.reward.toFixed(2);
          if (minReward || maxReward) {
            let companyReward;
            if (minReward && maxReward) {
              companyReward = '$' + minReward + ' - ' + '$' + maxReward;
            } else {
              companyReward = minReward ? '$' + minReward : '$' + maxReward;
            }
            row._rewardSummary += ` plus company reward (${companyReward})`;
          }
          return row;
        }
        if (platformReward > 0) {
          // combine platform reward with company reward.
          row.reward += platformReward;
        }
        if (platformRewardReoccurring > 0) {
          row.rewardReoccurring += platformRewardReoccurring;
        }
        if (row.reward && row.rewardReoccurring) {
          row._rewardSummary = `$${row.reward.toFixed(2)} for first order and $${row.rewardReoccurring.toFixed(2)} for subsequent orders`;
        } else if (row.reward) {
          row._rewardSummary = `$${row.reward.toFixed(2)} for first order`;
        } else if (row.rewardReoccurring) {
          row._rewardSummary = '$' + row.rewardReoccurring.toFixed(2) + ' for reoccurring order';
        }
        return row;
      });
    }
    this.loading = false;
  }
  cellClicked ({field, event, row}) {
    if (field === 'url') {
      this.copyToClipboard(row.url);
    }
  }
  copyToClipboard (text: string) {
    navigator.clipboard.writeText(text).then(() => {
      this.toastr.success('Affiliate link copied to clipboard', 'Success!');
    });
  }
  private createTiles() {
    const statistics = this.dashboard.statistics;
    this.tiles = [
      {
        label: 'Link Clicks',
        value: statistics.linkClicks,
      },
      {
        label: 'Price Checks',
        value: statistics.priceChecks,
      },
      {
        label: 'Orders',
        value: statistics.ordersDelivered,
      },
      {
        label: 'Total Earnings',
        value: statistics.companyRewards + statistics.platformRewards,
        isCurrency: true,
      },
    ];
  }
  public async showCompanyInfo(companyId: number) {
    try {
      if (!companyId) {
        return;
      }
      this.loadingCompany = true;
      this.companyInfoModalRef = this.modalService.show(
        this.companyInfoModalTemplate,
        {
          ignoreBackdropClick: false,
        }
      );
      this.company = await this.companyProvider.getCompany(companyId) as Company;
    } catch (error) {
      console.error('Failed to load company data', error.message);
    }
    this.loadingCompany = false;
  }
  public getTerritories () {
    return this.company && this.company.territories ? this.company.territories.map(t => t.zip) : '';
  }
  public openCheckout () {
    const link = this.dashboard.affiliateLinks.filter((l: any) => l.id === this.company.id)[0];
    if (link && link.url) {
      window.open(link.url);
    } else {
      this.toastr.error('Company checkout link not available');
    }
  }
}
