<section class="page page-upsell" [class.power_user]="isPowerUser">
  <div
    id="company-logo"
    *ngIf="company && company.logoUrl"
    [style.background-image]="'url(' + company.logoUrl + ')'"
  ></div>
  <div style="text-align: center; position: relative">
    <app-loader [loading]="loadingProduct || loadingOrderIntent || updatingShipping"></app-loader>
  </div>
  <ng-container *ngIf="product && product.id">
    <section id="page-landing" *ngIf="page === 'landing'">
      <h3 [class.editable]="isPowerUser" (click)="isPowerUser ? edit='label' : ''">
        <span *ngIf="edit !=='label'">{{product.label}}</span>
        <input
          id="product-label"
          class="form-control"
          type="text"
          title="Enter short label for the product."
          placeholder="product label"
          [(ngModel)]="product.label"
          *ngIf="edit ==='label'"
        >
      </h3>
      <div class="carousel-container" *ngIf="product?.images?.length || isPowerUser">
        <div
          style="margin: 2em 0;"
          *ngIf="!product?.images?.length && isPowerUser && !edit"
          (click)="edit = 'images'"
        >
          click here to add images
        </div>
        <carousel
          class="mb-3"
          *ngIf="edit !== 'images' && product?.images?.length"
          [isAnimated]="true"
          [class.editable]="isPowerUser"
          (click)="isPowerUser ? edit = 'images' : ''"
        >
          <slide *ngFor="let image of product?.images">
            <img [src]="image.url" alt="{{image.title || 'product image'}}" style="display: block; width: 100%;">
          </slide>
        </carousel>
      </div>
      <section id="image-upload" class="editing" *ngIf="edit === 'images'">
        <h5>Edit Product Images</h5>
        <div class="images">
          <div
            *ngFor="let image of product?.images"
            [style.background-image]="'url(' + image.url + ')'"
            class="image-wrapper"
          >
            <div class="delete-btn" (click)="removeProductImage(image)">x</div>
          </div>
          <div class="image-wrapper add-image" (click)="openFileSelect()">
            <app-loader [loading]="uploadingImage" class="size-1"></app-loader>
            <span *ngIf="!uploadingImage">+</span>
          </div>
        </div>
      </section>
      <h6
        class="title"
        *ngIf="product?.title"
        [class.editable]="isPowerUser"
        (click)="isPowerUser ? edit='title' : ''"
      >
        <span *ngIf="edit !=='title'">{{product.title}}</span>
        <input
          id="product-title"
          class="form-control"
          type="text"
          title="Enter short title for the product sale page."
          placeholder="page title"
          [(ngModel)]="product.title"
          *ngIf="edit ==='title' && isPowerUser"
        >
      </h6>
      <div
        class="description"
        *ngIf="product.description"
        [class.editable]="isPowerUser"
        (click)="isPowerUser ? edit='description' : ''"
      >
        <ng-container *ngIf="edit !=='description' || !isPowerUser">
          <div
            [innerHTML]="descriptionHtml"
            (click)="edit='description'"
          ></div>
        </ng-container>
        <textarea
          id="product-description"
          class="form-control"
          title="Enter product description"
          placeholder="description"
          style="min-height: 6em;"
          [(ngModel)]="product.description"
          *ngIf="edit ==='description' && isPowerUser"
        ></textarea>
      </div>
      <button
        class="mt-4 btn btn-warning btn-lg w-50"
        *ngIf="hasUnsavedChanges && isPowerUser"
        (click)="saveProductChanges()"
        [disabled]="savingChanges"
      >
        {{edit !== '' ? 'Preview and ' : ''}}Save
      </button>
      <button
        class="mt-4 btn btn-default-dark no-radius btn-lg"
        [class.w-50]="hasUnsavedChanges"
        [class.w-100]="!hasUnsavedChanges"
        (click)="nextPage()"
      >
        Check Availability
      </button>
    </section>
    <section id="page-enter-zip" *ngIf="page === 'zip'">
      <h3>
        Please enter your zip code.
      </h3>
      <input
        id="zip-input"
        inputmode="numeric"
        class="form-control"
        type="text"
        title="enter zip code, i.e 01430"
        placeholder="5 Digit Zip"
        [(ngModel)]="zip"
      >
      <button
        class="mt-4 btn btn-default-dark no-radius btn-lg w-100"
        [disabled]="!isZipValid() || territoryNotServiced"
        (click)="nextPage()"
      >
        Next
      </button>
    </section>
    <section id="product-available" *ngIf="page === 'product_available'">
      <h3>Good news!</h3>
      <h4>{{product.label}} is available in your area for {{product?.companyProduct?.price | currency}}</h4>
      <button
        class="mt-4 btn btn-default-dark no-radius btn-lg w-100"
        (click)="nextPage()"
      >
        Proceed
      </button>
    </section>
    <section id="page-register" *ngIf="page === 'register'">
      <app-customer-register
        [companyId]="company.id"
        [tankSizeInputDisabled]="true"
        [loginExistingUser]="true"
        [skipCC]="true"
        (onRegisterSuccess)="customerRegistered($event)"
      >
      </app-customer-register>
    </section>
    <section id="page-payment-info" *ngIf="page === 'payment_info' && !updatingShipping">
      <h3>Setup Payment Method!</h3>
      <br>
      <div style="text-align: center; margin: 1em;" *ngIf="creatingOrderIntent">
        <app-loader [loading]="true"></app-loader>
      </div>
      <app-stripe-checkout
        *ngIf="!creatingOrderIntent && productOrder?.paymentIntent"
        [paymentIntent]="productOrder.paymentIntent"
        [returnUrl]="getCheckoutReturnUrl()"
        [submitBtnText]="checkoutButtonText"
      ></app-stripe-checkout>
    </section>
  </ng-container>
  <section id="page-order-success" *ngIf="page === 'page_order_success'">
    <div class="text-center section-wrapper mt-4">
      Your order has been processed successfully!
      <br><br>
      You may close this window now.
    </div>
  </section>
  <section id="page-order-failed" *ngIf="page === 'page_order_failed'">
    <div class="text-center section-wrapper">
      Whoops!<br>
      Looks like an error occurred whilst processing your order.
      <br><br>
      <span class="error">
        Error: {{productOrder?.paymentIntent?.lastPaymentError?.message || 'Processing payment failed'}}
      </span>
      <br>
      <span class="link" (click)="page = 'payment_info'">
        Click here to try another payment method.
      </span> or contact us at <a href="tel:{{company.phone}}">{{company.phone}}</a> and submit your
      order via phone.
    </div>
  </section>
  <section id="page-area-not-serviced" *ngIf="page === 'area_not_serviced'">
    <div class="text-center section-wrapper">
      Sorry, but {{ company?.name ? company.name : '"Current"' }} hasn't reached
      your neighborhood yet!<br><br>
      Don't stress though...I'm sure we're coming soon.
    </div>
  </section>
</section>
<input
  #fileUpload
  type="file"
  id="fileUpload"
  name="fileUpload"
  accept="image/*"
  style="display: none"
/>
