<!-- CURRENT -->
<section class="container-fluid">
  <div class="sidebar-desktop d-none d-lg-block" *ngIf="!isPlainPage">
    <div
      *ngFor="let navLink of sideMenuLinks"
      [attr.id]="navLink.id"
      class="menu-item"
      routerLink="{{ navLink.href }}"
    >
      <img
        *ngIf="navLink.icon"
        class="img-fluid"
        src="assets/img/{{ navLink.icon }}"
        alt="{{ navLink.label }}"
      />

      <span class="label">{{ navLink.label }}</span>
    </div>
  </div>

  <div class="page-content px-0 px-lg-1 px-xl-5" [class.plain-page]="isPlainPage">
    <app-header *ngIf="!isPlainPage"></app-header>
    <router-outlet></router-outlet>
  </div>
</section>
