<section class="tank-info {{classes}} " *ngIf="customer && customer.gasTank" (keyup)="keyUp()">
  <h5 *ngIf="title">{{title}}</h5>
  <div class="row" *ngIf="!sizeInputDisabled">
    <div class="col-6">
      <label for="tank-size">Size</label>
    </div>
    <div class="col-6">
      <input
        id="tank-size"
        class="form-control"
        type="number"
        placeholder="nr of gallons"
        inputmode="numeric"
        [(ngModel)]="customer.gasTank.size"
      >
      <span class="error" *ngIf="validationErrors.description">{{validationErrors.description.size}}</span>
    </div>
  </div>

  <div class="location-wrapper">
    <div class="row">
      <div class="col-12">
        <label>Location</label>
      </div>
    </div>
    <div class="tank-location-options">
      <div class="location-option" *ngFor="let option of locationOptions">
        <img
          class="house"
          src="assets/img/{{option.icon}}"
          alt="{{option.label}}"
          height="50px"
          (click)="customer.gasTank.location = option.value"
        >
        <label>
          <input
            type="radio"
            name="tank"
            id="tank-{{option.value}}"
            value="front"
            (click)="customer.gasTank.location = option.value"
            [checked]="customer.gasTank.location === option.value"
          >
          {{option.label}}
        </label>
      </div>
      <span class="error" *ngIf="validationErrors.description">{{validationErrors.description.location}}</span>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <label for="tank-level">Is {{isPowerUser ? 'the' : 'your'}} tank above or below ground?</label>
    </div>
    <div class="col-6">
      <select id="tank-level" [(ngModel)]="customer.gasTank.groundLevel" class="form-control">
        <option value="">Choose</option>
        <option value="above">Above</option>
        <option value="below">Below</option>
      </select>
      <span class="error" *ngIf="validationErrors.description">{{validationErrors.description.groundLevel}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <label for="tank-ownership">
        <span *ngIf="!isPowerUser">Do you own your propane tank/s?</span>
        <span *ngIf="isPowerUser">Customer is the owner of propane tanks/s?</span>
      </label>
    </div>
    <div class="col-6">
      <input id="tank-ownership" type="checkbox" class="form-check-inline" [(ngModel)]="customer.gasTank.ownership">
      <span class="error" *ngIf="validationErrors.description">{{validationErrors.description.ownership}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <label for="tank-description">Description</label>
    </div>
    <div class="col-12">
      <textarea
        placeholder="Tell us about your tank and where it’s located."
        id="tank-description"
        class="form-control"
        rows="2"
        [(ngModel)]="customer.gasTank.description"
      ></textarea>
      <span class="error" *ngIf="validationErrors.description">{{validationErrors.description.message}}</span>
    </div>
  </div>
  <button *ngIf="nextBtnText" class="btn btn-default-dark no-radius btn-lg w-100" (click)="nextBtnClick()">
    {{nextBtnText}}
  </button>
</section>
