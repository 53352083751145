<section class="add-customer">
  <div class="row">
    <div class="col-12 col-lg-6">
      <section class="customer-form">
        <h5 class="d-block d-lg-none">Customer Info</h5>
        <app-customer-form [customer]="customer" (onChange)="customerChanged($event)"></app-customer-form>
      </section>
      <section class="cc-info" style="margin-top: 1em;">
        <h5>CC Info</h5>
        <div class="billing-info">
          <div id="card-element">
            <!-- a Stripe Element will be inserted here. -->
          </div>

          <!-- Used to display Element errors -->
          <div id="card-errors" role="alert"></div>
        </div>
      </section>
    </div>
    <div class="col-12 col-lg-6">
      <section class="product mt-4 mt-lg-0 mb-4">
        <h5 class="d-block d-lg-none">Product Info</h5>
        <div class="row">
          <div class="col-5 col-sm-6">
            <label for="product">Product:</label>
          </div>
          <div class="col-7 col-sm-6">
            <select id="product" class="form-control" [(ngModel)]="order.product">
              <option value="{{product.name}}" *ngFor="let product of products">{{product.label}}</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-5 col-sm-6">
            <label for="orderedAmount">Order Amount (Gal):</label>
          </div>
          <div class="col-7 col-sm-6">
            <input id="orderedAmount" class="form-control" type="number" [(ngModel)]="order.orderedAmount">
            <span class="error" *ngIf="validationErrors['orderedAmount']">{{validationErrors['orderedAmount']}}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-5 col-sm-6">
            <label for="pricePerGallon">Price Per Gal:</label>
          </div>
          <div class="col-7 col-sm-6">
            <input id="pricePerGallon" class="form-control" type="number" [(ngModel)]="order.pricePerGallon">
            <span class="error" *ngIf="validationErrors['pricePerGallon']">{{validationErrors['pricePerGallon']}}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-5 col-sm-6">
            <label for="delivery-notes">Delivery Notes:</label>
          </div>
          <div class="col-7 col-sm-6">
            <input id="delivery-notes" class="form-control" type="text" [(ngModel)]="order.deliveryNotes">
            <span class="error" *ngIf="validationErrors['deliveryNotes']">{{validationErrors['deliveryNotes']}}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-5 col-sm-6">
            <label for="delivery-date">Delivery By:</label>
          </div>
          <div class="col-7 col-sm-6">
            <input
              type="text"
              id="delivery-date"
              class="form-control calendar"
              placeholder="pick a date"
              bsDatepicker
              [(ngModel)]="order.deliveryDate"
              [bsConfig]="bsConfig"
              (bsValueChange)="onDateChange($event)"
            >
            <span class="error" *ngIf="validationErrors['deliveryDate']">{{validationErrors['deliveryDate']}}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-4 col-sm-6">
            <label for="product-additional">Additional Products:</label>
          </div>
          <div class="col-8 col-sm-6">
            <div class="btn-group" dropdown [insideClick]="true" (onShown)="onShowAdditionalProducts()">
              <button
                id="button-basic"
                dropdownToggle
                type="button"
                class="btn btn-primary dropdown-toggle"
                aria-controls="dropdown-basic"
              >
                {{additionalProductBtnText}}
                <span class="caret"></span>
              </button>
              <ul id="product-additional"
                  *dropdownMenu
                  class="dropdown-menu dropdown-menu-right"
                  role="menu"
                  aria-labelledby="button-basic"
              >
                <li role="menuitem" *ngFor="let product of additionalProducts">
                  <label for="additional-{{product.name}}">{{product.label}}</label>
                  <input id="additional-{{product.name}}" type="checkbox" [(ngModel)]="product.enabled">
                </li>
              </ul>
            </div>
          </div>
        </div>

      </section>
      <app-customer-tank
        [classes]="' order-page align-mid default-border '"
        [customer]="customer"
        [userRole]="this.user.role"
        [title]="'Tank Info'"
      >
      </app-customer-tank>
    </div>
    <div class="row">
      <div class="col-12" style="text-align: right;">
        <button class="btn btn-current-order lg default" (click)="cancel()">
          Cancel
          <span class="shadow"></span>
        </button>
        <button class="btn btn-current-order lg" (click)="processOrder()">
          Submit
          <span class="shadow"></span>
        </button>
      </div>
    </div>
  </div>
</section>
