<div class="tank-location-dropdown" [class.open]="locationDropdownOpen">
  <div *ngIf="!locationDropdownOpen" [class.location-option]="editEnabled">
    <img
      class="house"
      src="assets/img/{{locationOptions[value].icon}}"
      alt="{{locationOptions[value].label}}"
      [attr.height]="locationOptionIconHeight"
      (click)="locationDropdownOpen = editEnabled ? !locationDropdownOpen : false"
    >
    <div>
      {{locationOptions[value].label}}
    </div>
  </div>
  <div class="location-dropdown" *ngIf="locationDropdownOpen">
    <div
      class="location-option"
      [class.selected]="value === option.value"
      *ngFor="let option of locationOptionsArray"
      (click)="selectValue(option.value)"
    >
      <img
        class="house"
        src="assets/img/{{option.icon}}"
        alt="{{option.label}}"
        [attr.height]="locationOptionIconHeight"
      >
      <div>
        {{option.label}}
      </div>
    </div>
  </div>
</div>
