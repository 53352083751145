<section
  class="customer-form {{classes}}"
  *ngIf="customer && (!existingUser?.id || !loginExistingUser)"
  (keyup)="keyUp()"
>
  <h3 *ngIf="title">{{title}}</h3>
  <div class="row">
    <div class="col-6 label">
      <label for="customer-first-name">First Name</label>
    </div>
    <div class="col-6">
      <input
        id="customer-first-name"
        class="form-control"
        type="text"
        [class.error]="errors.firstName"
        [(ngModel)]="customer.firstName"
      >
      <span *ngIf="errors.firstName" class="error">{{errors.firstName.message}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-6 label">
      <label for="customer-last-name">Last Name</label>
    </div>
    <div class="col-6">
      <input
        id="customer-last-name"
        class="form-control"
        type="text"
        [class.error]="errors.lastName"
        [(ngModel)]="customer.lastName"
      >
      <span *ngIf="errors.lastName" class="error">{{errors.lastName.message}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-6 label">
      <label for="customer-phone">Phone</label>
    </div>
    <div class="col-6">
      <input id="customer-phone" [class.error]="errors.phone" class="form-control" type="text" [(ngModel)]="customer.phone">
      <span *ngIf="errors.phone" class="error">{{errors.phone.message}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-6 label">
      <label for="customer-email">Email</label>
    </div>
    <div class="col-6">
      <input
        id="customer-email"
        class="form-control"
        type="email"
        (change)="onEmailChange.emit(customer.email)"
        [class.error]="errors.email"
        [(ngModel)]="customer.email"
      >
      <span *ngIf="errors.email" class="error">{{errors.email.message}}</span>
    </div>
  </div>
  <ng-container *ngIf="customer.nonce">
    <div class="row">
      <div class="col-6 label">
        <label for="customer-password">Password</label>
      </div>
      <div class="col-6">
        <input
          id="customer-password"
          class="form-control"
          type="password"
          [class.error]="errors.password"
          [(ngModel)]="customer.password"
        >
        <span *ngIf="errors.password" class="error">{{errors.password.message}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-6 label">
        <label for="customer-confirm-password">Confirm Password</label>
      </div>
      <div class="col-6">
        <input
          id="customer-confirm-password"
          class="form-control"
          type="password"
          [(ngModel)]="customer.passwordConfirm"
        >
        <span *ngIf="customer.password && customer.passwordConfirm !== customer.password" class="error">
        Passwords don't match
      </span>
      </div>
    </div>
  </ng-container>
  <div class="row" *ngIf="!editingAddress">
    <div class="col-6 label">
      <label>Address</label>
    </div>
    <div class="col-6">
      <div>
        {{customer.address.zip}},
        {{customer.address.line1}},
        <span *ngIf="customer.address.line2">{{customer.address.line2}},</span>
        {{customer.address.city}},
        {{customer.address.state}}
      </div>
      <button
        style="margin-top: 1em;"
        class="btn btn-default-dark no-radius btn-sm"
        (click)="editingAddress = true"
      >
        change address
      </button>
    </div>
  </div>
  <section style="background: #f9fbfd;padding: 0.4em;" *ngIf="editingAddress">
    <app-address-lookup
      [title]="'Delivery Address'"
      [errors]="errors"
      [placeholderAddress]="customer?.address"
      (onAddressChange)="onAddressChange($event)">
    </app-address-lookup>
  </section>
  <ng-container *ngIf="userRole !== 'admin' && userRole !== 'company'">
    <div class="row" style="margin-top: 1em;">
      <div class="col-12" style="text-align: center;">
        <input id="customer-notifications" type="checkbox" [(ngModel)]="customer.sendSms">
        <span style="font-size: 0.8em;">I would like to receive notifications regarding the status of my order as well as future communications regarding my service address.</span>
      </div>
    </div>
  </ng-container>
  <button
    style="margin-top: 1em;"
    class="btn btn-default-dark no-radius btn-lg w-100"
    *ngIf="nextBtnText"
    (click)="nextBtnClick()">
    {{nextBtnText}}
  </button>
</section>
<ng-template #existingAccountModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Good News!
    </h4>
    <button
      type="button"
      class="btn-close close pull-right"
      (click)="existingAccountModalModalRef?.hide()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div
    class="modal-body"
    *ngIf="!loginExistingUser || !existingUser?.id"
    (click)="gotoLogin()"
  >
    Looks like you already have a customer account with us!<br>
    <span style="color: blue" class="link">Click here</span> to log in and submit your order via your customer dashboard.<br>
  </div>
  <div
    class="modal-body"
    *ngIf="existingUser?.id && loginExistingUser"
  >
    Looks like you already have a customer account with us!<br>
    Please login to continue.
    <div class="row mt-3">
      <div class="col-6 label">
        <label for="login-email">Email</label>
      </div>
      <div class="col-6">
        <input
          id="login-email"
          class="form-control"
          type="text"
          [disabled]="true"
          [(ngModel)]="existingUser.email"
        >
        <span *ngIf="errors.password" class="error">
        {{errors.password.message}}
      </span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-6 label">
        <label for="login-password">Password</label>
      </div>
      <div class="col-6">
        <input
          id="login-password"
          class="form-control"
          type="password"
          [class.error]="errors.password"
          [(ngModel)]="existingUser.password"
        >
        <span *ngIf="errors.password" class="error">{{errors.password.message}}</span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="w-100 text-end">
      <button
        type="button"
        class="btn btn-default-dark no-radius w-100 px-4 me-2"
        (click)="onLoginBtnClick()"
        [disabled]="(loginExistingUser && !existingUser.password) || loggingIn"
      >
        Login
      </button>
    </div>
  </div>
</ng-template>
<ng-template #unverifiedAccountModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Verification Needed!
    </h4>
    <button
      type="button"
      class="btn-close close pull-right"
      (click)="unverifiedAccountModalRef?.hide()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <ng-container *ngIf="!invitationResent">
      <div *ngIf="!sendingInvitation">
        Looks like you're in our system but you still need to activate your account.<br>
        Click on the button below to do that.<br>
      </div>
      <app-loader [loading]="sendingInvitation" class="size-2"></app-loader>
    </ng-container>
    <ng-container *ngIf="invitationResent">
      We have sent an activation link to your email.<br>
      Please check your email/sms messages and follow the setup instructions.<br>
    </ng-container>
  </div>

  <div class="modal-footer">
    <div class="w-100 text-end">
      <button
        type="button"
        class="btn btn-default-dark no-radius w-100 px-4 me-2"
        (click)="resendActivationLink()"
        [disabled]="sendingInvitation || invitationResent"

      >
        {{invitationResent ? 'Check Your Email' : 'Activate'}}
      </button>
    </div>
  </div>
</ng-template>
