import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserProvider } from '../../../providers/user.provider';
import { Customer } from '../../../interfaces/customer.interface';
import { StaticProvider } from '../../../providers/static.provider';
import {Address} from '../../../interfaces/address.interface';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AuthProvider} from '../../providers/auth.provider';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss'],
  providers: [
    UserProvider,
    StaticProvider,
    AuthProvider,
  ]
})
export class CustomerFormComponent implements OnInit {
  @ViewChild('existingAccountModalTemplate', {static: true}) existingAccountModalTemplate;
  public existingAccountModalModalRef: any;
  @ViewChild('unverifiedAccountModalTemplate', {static: true}) unverifiedAccountModalTemplate;
  public unverifiedAccountModalRef: any;
  @Input() nextBtnText = '';
  @Input() title = '';
  @Input()
  public userRole: 'admin' | 'customer' | 'company'; // who is currently filling the form
  @Input()
  public classes: string;
  @Input() validate: boolean;
  @Input() loginExistingUser = false; // triggers page reload if true
  @Input() deliveryCompanyId: number;
  @Output()
  public onChange = new EventEmitter<Customer>();
  @Output()
  public onEmailChange = new EventEmitter<string>();
  @Output()
  public onZipChange = new EventEmitter<string>();
  @Output()
  public onNext = new EventEmitter<Customer>();
  public formValid: boolean;
  public errors: any = {}; // i.e {email: {message: 'Email is required', phone: 'Phone is required' } }
  public states: any[];
  public editingAddress = true;
  public existingUser: Customer | undefined;
  public sendingInvitation = false;
  public invitationResent = false;
  public loggingIn = false;
  private _customer: Customer;
  constructor (
    private userProvider: UserProvider,
    public toastr: ToastrService,
    private staticProvider: StaticProvider,
    private modalService: BsModalService,
    private authProvider: AuthProvider,
  ) {
  }

  ngOnInit() {
    this.staticProvider.getStates().then((states) => {
      this.states = states;
    });
    if (!this.customer) {
      this.customer = Customer();
    }
  }
  @Input() set customer (c: Customer) {
    this._customer = c;
    if (c && c.address) {
      const {line1, city, state, zip} = c.address;
      // if we don't have all the required address fields - trigger edit mode.
      this.editingAddress = !(line1 && city && state && zip);
    }
  };
  get customer () {
    return this._customer;
  };
  nextBtnClick () {
    if (this.validate) {
      if (!this.customer.password && this.customer.nonce) {
        return this.toastr.error('Password must be at least 10 characters', 'Error!');
      }
      this.validateForm().then((valid) => {
        if (valid) {
          this.onNext.next(this.customer);
        }
      });
    } else {
      this.onNext.next(this.customer);
    }
  }
  private getAddressPayload () {
    // pick only relevant fields( excluding id, created_at etc )
    const address = this.customer.address;
    return {
      line1: address.line1,
      line2: address.line2,
      city: address.city,
      zip: address.zip,
      state: address.state,
    }
  }
  async validateForm () {
    try {
      this.errors = {};
      this.formValid = false;
      const c = this.customer;
      const payload: any = {
        firstName: c.firstName,
        lastName: c.lastName,
        email: c.email,
        phone: c.phone,
        billingAddress: this.getAddressPayload(),
      };
      if (c.nonce) {
        // customer arrived via activation link
        payload.password = c.password;
        payload.nonce = c.nonce;
      }
      if (c.id) {
        payload.id = c.id;
      }
      // check if this company has an "unverified" customer account already.
      // If so, ask the customer to re-activate their account.
      if (
        !window.location.href.includes('register') &&
        !window.location.href.includes('activate')
      ) {
        try {
          this.existingUser = await this.userProvider.getByEmail(payload.email, this.deliveryCompanyId) as Customer;
          if (this.existingUser) {
            if (this.existingUser.status === 'active') {
              this.openExistingAccountModal();
            } else if (this.existingUser.status === 'unverified') {
              this.openUnverifiedAccountModal();
            } else {
              console.error('Customer account already exists with status: ' + this.existingUser.status);
            }
            return;
          }
        } catch (error) {
          // this is expected
          console.error('Existing user not found:', error.message);
        }
      }
      payload.deliveryCompanyId = this.deliveryCompanyId;
      await this.userProvider.validateUser(payload);
      this.formValid = true;
      return true;
    } catch (error) {
      if (error.error && error.error.errors) {
        this.errors = error.error.errors;
      }
    }
  }
  async submitExistingUserLogin () {
    localStorage.removeItem('JWT');
    this.loggingIn = true;
    try {
      const response: any = await this.userProvider.signIn({
        email: this.existingUser.email,
        password: this.existingUser.password,
        companyId: this.existingUser.deliveryCompanyId,
      });
      if (!response.err) {
        this.toastr.success('Login successful', 'Success!')
        await this.authProvider.setToken(response.data.token);
        const user: any = await this.userProvider.getUserInfo();
        if (user.role) {
          this.authProvider.setUser(user);
        }
        window.location.reload();
      } else {
        console.log('response.err.errors', response.err.errors);
      }
    } catch (error) {
      console.error('Failed to log in:' + error.message);
    }
    this.loggingIn = false;
  }
  keyUp() {
    this.errors = {};
    setTimeout(() => {
      this.onChange.emit(this.customer);
    }, 32);
  }
  onAddressChange (address: Address) {
    // clear address related errors
    if (this.errors) {
      Object.keys(this.errors).forEach((field) => {
        // i.e billingAddress,line1
        if (field.match(/address/)) {
          delete this.errors[field];
        }
      });
    }
    Object.keys(address).forEach((field) => {
      this.customer.address[field] = address[field];
    });
  }
  async resendActivationLink () {
    this.sendingInvitation = true;
    try {
      await this.userProvider.resendInvitation(this.existingUser.id);
      this.toastr.success('Activation link sent successfully', 'Success!')
      this.invitationResent = true;
    } catch (error) {
      console.error('Failed to resend customer invitation');
    }
    this.sendingInvitation = false;
  }
  public openExistingAccountModal(): void {
    this.existingAccountModalModalRef = this.modalService.show(
      this.existingAccountModalTemplate,
      {
        ignoreBackdropClick: true,
      }
    );
  }
  public openUnverifiedAccountModal(): void {
    this.unverifiedAccountModalRef = this.modalService.show(
      this.unverifiedAccountModalTemplate,
      {
        ignoreBackdropClick: true,
      }
    );
  }
  public gotoLogin() {
    this.existingAccountModalModalRef.hide();
    window.location.href = '/' + this.deliveryCompanyId + '/login';
  }
  public onLoginBtnClick() {
    if (!this.loginExistingUser) {
      // we don't want to log the user in during this order flow.
      // redirect to login page and from there to their dashboard.
      this.existingAccountModalModalRef.hide();
      window.location.href = '/' + this.deliveryCompanyId + '/login';
    } else {
      this.submitExistingUserLogin();
    }
  }
}
