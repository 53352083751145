import { Injectable } from '@angular/core';
import { API } from './api.provider';
import { Territory } from '../../interfaces/territory.interface';

@Injectable()
export class TerritoryProvider {
  constructor(
    private api: API
  ) {}

  createTerritory(territory) {
    return this.api.post('/territory', territory);
  }

  deleteTerritory(id) {
    return this.api.delete(`/territory/${id}`);
  }

  getTerritories(query) {
    return this.api.get('/territory', query);
  }
  getCompanyTerritories(companyId): Promise<{list: Territory[]}> {
    return this.api.get(`/company/${companyId}/territory`)
      .then((res: {list: Territory[]}) => {
      return res;
    });
  }
  updateTerritory(id, body) {
    return this.api.patch(`/territory/${id}`, body);
  }
}
