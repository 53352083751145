<div class="tank-info {{classes}} " *ngIf="selectedTank">
  <div *ngIf="tanks && tanks.length > 1" style="font-size: 1.25rem;margin-bottom: 0.5rem">
    {{userRole !== 'customer' ? 'Their' : 'My'}}
    <select
      id="select-tank"
      class="form-control"
      title="Click to select which tank information to view"
      style="padding: 0;text-align: center;font-size: 1.25rem;"
      [(ngModel)]="selectedTank"
    >
      <option [ngValue]="tank" *ngFor="let tank of tanks">
        {{tank.product | titlecase}}
      </option>
    </select>
    Tank
  </div>
  <h5 *ngIf="tanks && tanks.length === 1">
    {{userRole !== 'customer' ? 'Their' : 'My'}} {{selectedTank.product | titlecase}} Tank
  </h5>
  <div *ngIf="selectedTank.product === 'propane'">
    <div class="row mb-2">
      <div class="col prop-name">Tank Size:</div>
      <div class="col" *ngIf="!edit">{{tankSize}}</div>
      <div class="col" *ngIf="edit">
        <input
          type="number"
          inputmode="numeric"
          title="enter tank size in gallons"
          style="width: 4em;"
          [(ngModel)]="selectedTank.tank.size"
        >
      </div>
    </div>
    <div class="row mb-2">
      <div class="col prop-name">Ground Level:</div>
      <div class="col" *ngIf="!edit">
        {{groundLevel !== 'n/a' ? (groundLevel | titlecase) : 'n/a'}}
      </div>
      <div class="col" *ngIf="edit">
        <select id="tank-ground-level" class="form-control" [(ngModel)]="selectedTank.tank.groundLevel">
          <option value="">Choose</option>
          <option value="above">Above</option>
          <option value="below">Below</option>
        </select>
      </div>
    </div>
    <div class="row mb-2 tank-location" *ngIf="selectedTank.tank.location">
      <div class="col prop-name">Location:</div>
      <div class="col" style="text-align: center;">
        <app-tank-location-dropdown
          [locationOptionIconHeight]="'30px'"
          [value]="selectedTank.tank.location"
          [editEnabled]="edit"
          (onSelect)="onTankLocationSelect($event)"
        >
        </app-tank-location-dropdown>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col prop-name">
        Tank
        <span *ngIf="!edit">{{selectedTank.tank.ownership ? 'is' : 'is not' }}</span>
        <div *ngIf="edit" style="display: inline-block;">
          <select #select (change)="onTankOwnershipChange(select.value)">
            <option [selected]="selectedTank.tank.ownership" value="true">is</option>
            <option [selected]="!selectedTank.tank.ownership" value="false">is not</option>
          </select>
        </div>
        owned by {{isPowerUser ? 'customer' : 'me'}}
      </div>
    </div>
    <button
      style="margin-top: 0.4em;"
      class="btn btn-default-dark no-radius w-100"
      (click)="saveTank()"
      *ngIf="edit"
    >
      Save
    </button>
  </div>

  <div *ngIf="selectedTank.product === 'oil'">
    <div class="row mb-2" *ngIf="isPowerUser">
      <div class="col prop-name">Tank Inspection:</div>
      <div class="col">
        <div>
          <button
            class="btn btn-default-dark btn-sm"
            title="Click to mark customer tank inspection as finished"
            *ngIf="isPowerUser && selectedTank.tank?.tankInspection?.status !== 'completed'"
            (click)="openInspectionModal()"
          >
            Complete Inspection
          </button>
          <div *ngIf="selectedTank.tank?.tankInspection?.status === 'completed'">
            <span *ngIf="!selectedTank.tank.tankInspection.tankTrackerInspectionId">Completed</span>
            <span class="link" (click)="openInspectionOnTankTracker()" *ngIf="selectedTank.tank.tankInspection.tankTrackerInspectionId">
              Completed
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2" *ngIf="selectedTank.tank?.tankInspection?.status === 'completed'">
      <div class="col prop-name">
        Date Completed:
      </div>
      <div class="col">
        {{selectedTank.tank?.tankInspection?.completedAt | date:'MM/d/y'}}
      </div>
    </div>

    <div class="row mb-2" *ngIf="userRole !== 'customer'">
      <div class="col prop-name">Est. Tank Level:</div>
      <div class="col" *ngIf="!edit">
        {{adjustedOilAmountPercent}}{{adjustedOilAmountPercent !== 'n/a' ? '%' : ''}}
      </div>
      <div class="col" *ngIf="edit && selectedTank.product === 'oil'">
        <input
          type="number"
          inputmode="numeric"
          title="enter estimated oil amount percentage"
          style="width: 3.3em;"
          [(ngModel)]="adjustedOilAmountPercent"
          (ngModelChange)="setAdjustedOilAmount($event)"
        >
        %
        <div style="font-size: 0.8em" *ngIf="selectedTank.tank.estimatedOilAmount >= 0">
          {{selectedTank.tank.estimatedOilAmount}}gal
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col prop-name">Tank Size:</div>
      <div class="col" *ngIf="!edit">{{tankSize}}</div>
      <div class="col" *ngIf="edit">
        <select
          id="tank-size"
          class="form-control"
          style="width: 6em;"
          *ngIf="!customTankSize"
          [(ngModel)]="selectedTank.tank.size"
          (ngModelChange)="onTankSizeChange($event)"
        >
          <option value="{{option.value}}" *ngFor="let option of tankSizeOptions">{{option.label}}</option>
        </select>
        <input
          type="number"
          inputmode="numeric"
          title="enter tank size"
          style="width: 6em;"
          placeholder="enter size"
          *ngIf="customTankSize"
          [(ngModel)]="selectedTank.tank.size"
        >
      </div>
    </div>
    <div class="row mb-2">
      <div class="col prop-name">K-Factor:</div>
      <div class="col" *ngIf="!edit || (userRole !== 'admin' && userRole !== 'company')">{{kFactor}}</div>
      <div class="col" *ngIf="edit && (userRole === 'admin' || userRole === 'company')">
        <input
          type="number"
          inputmode="numeric"
          title="enter k factor (optional)"
          style="width: 4em;"
          [(ngModel)]="newKFactor"
        >
      </div>
    </div>
    <div class="row mb-2 tank-location" *ngIf="selectedTank.tank.location">
      <div class="col prop-name">Location:</div>
      <div class="col" style="text-align: center;">
        <app-tank-location-dropdown
          [locationOptionIconHeight]="'30px'"
          [value]="selectedTank.tank.location"
          [editEnabled]="edit"
          (onSelect)="onTankLocationSelect($event)"
        >
        </app-tank-location-dropdown>
      </div>
    </div>
    <div class="row" *ngIf="showDescription">
      <div class="col prop-name">Description:</div>
      <div class="col" *ngIf="!edit">{{selectedTank.tank?.description}}</div>
      <div class="col" *ngIf="edit">
        <textarea
          type="text"
          title="describe your tank(optional)"
          [(ngModel)]="selectedTank.tank.description"
        ></textarea>
      </div>
    </div>
    <button
      style="margin-top: 0.4em;"
      class="btn btn-default-dark no-radius w-100"
      (click)="saveTank()"
      *ngIf="edit"
    >
      Save
    </button>
  </div>
</div>
<ng-template #inspectionModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Complete Tank Inspection</h4>
    <button
      type="button"
      class="btn-close close pull-right"
      (click)="inspectionModalRef?.hide()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="!tankInspectionMethod">
    <div class="row align-items-center mb-2">
      <div class="col-8">
        <label for="remote-inspection">
          Inspect the tank remotely, using our remote "Tank Tracker" tool.
        </label>
      </div>
      <div class="col-4">
        <button
          id="remote-inspection"
          type="button"
          class="btn btn-default-dark no-radius w-100 btn-sm"
          (click)="onSelectRemoteInspection()"
          [disabled]="loadingSubscription"
        >
          {{loadingSubscription ? '...loading' : 'Remote Inspection'}}
        </button>
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-8">
        <label for="manual-inspection">
          Complete inspection by visiting the customer and checking their tank in person.
        </label>
      </div>
      <div class="col-4">
        <button
          id="manual-inspection"
          type="button"
          class="btn btn-default-dark no-radius w-100 btn-sm"
          (click)="tankInspectionMethod = 'manual'"
        >
          Manual Inspection
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="tankInspectionMethod === 'manual'">
    <div class="row align-items-center mb-2">
      <div class="col-4">
        <label for="inspection-completed-at"> Date Completed: </label>
      </div>
      <div class="col-8">
        <input
          id="inspection-completed-at"
          class="form-control"
          type="text"
          placeholder="Pick date"
          title="When was the inspection completed"
          bsDatepicker
          [(ngModel)]="tankInspectionCompletedAt"
          [bsConfig]="{ adaptivePosition: true, containerClass: 'theme-dark-blue' }"
          [maxDate]="tomorrowDate"
        >
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="tankInspectionMethod === 'remote' && activatingTankTrackerRequired">
    <div class="row align-items-center mb-2">
      <div class="col-12" *ngIf="tankTrackerFee">
        Looks like its your first time using TankTracker.<br>
        Please activate the product by clicking the button below.
        <br>You will be charged ${{tankTrackerFee}} per month.
      </div>
      <div class="col-12" *ngIf="!tankTrackerFee && tankTrackerFeeLoaded">
        Looks like its your first time using TankTracker.<br>
        Please activate the product by clicking the button below.
        <!-- ToDo: free subscription -->
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="tankInspectionMethod === 'remote' && !activatingTankTrackerRequired">
    <div class="row align-items-center mb-2">
      <div class="col-12">
        <div *ngIf="loadingTankTrackerCustomer">Loading...</div>
        <div *ngIf="!loadingTankTrackerCustomer">
          <span *ngIf="!selectedTank.tank.tankInspection">
            Click start inspection to open tank tracker inspection window.
          </span>
          <span *ngIf="selectedTank.tank.tankInspection && selectedTank.tank.tankInspection?.status !== 'completed'">
            Tank inspection status: {{selectedTank.tank.tankInspection.status}}<br>
            Notes: {{selectedTank.tank.tankInspection.statusNotes}}<br>
            Inspection started at: {{formatDate(selectedTank.tank.tankInspection.createdAt, "MMMM Do")}}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="w-100 text-end">
      <button
        type="button"
        class="btn btn-cancel btn-sm px-4"
        (click)="cancelTankInspection()"
        [disabled]="savingTankInspection"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-save btn-sm px-4 me-2"
        *ngIf="tankInspectionMethod === 'manual'"
        (click)="completeTankInspection()"
        [disabled]="savingTankInspection || !tankInspectionCompletedAt"
      >
        {{savingTankInspection ? 'Saving...' : 'Complete Inspection'}}
      </button>
      <button
        type="button"
        class="btn btn-save btn-sm px-4 me-2"
        *ngIf="activatingTankTrackerRequired"
        (click)="activateTankTracker()"
        [disabled]="activatingTankTracker"
      >
        {{activatingTankTracker ? 'Activating...' : 'Activate TankTracker Subscription'}}
      </button>
      <button
        type="button"
        class="btn btn-save btn-sm px-4 me-2"
        (click)="navigateToRemoteInspection()"
        *ngIf="!activatingTankTrackerRequired && tankTrackerCustomer && tankInspectionMethod === 'remote'"
        [disabled]="loadingTankTrackerToken"
      >
        <span *ngIf="loadingTankTrackerToken">Loading...</span>
        <span *ngIf="canStartTankInspection">
          Start Inspection
        </span>
        <span *ngIf="canOpenTankInspection">
          Open Inspection
        </span>
        <span
          *ngIf="inspectionPendingCustomerInitialization"
        >
          Inspection Pending Customer Action
        </span>
      </button>
    </div>
  </div>
</ng-template>
