import {Component, EventEmitter, Output, Input} from '@angular/core';
interface DateRange { startDate: Date, endDate: Date }
import moment from "moment";
@Component({
  selector: "app-period-selector",
  templateUrl: "./period-selector.component.html",
  styleUrls: ["./period-selector.component.scss"],
})
export class PeriodSelectorComponent {
  @Input() group: string; // allows grouping period selectors on different pages together, so they can share same timeframe
  @Input() classes = '';
  @Output() onChange: EventEmitter<DateRange> = new EventEmitter<DateRange>();
  dateRange: Array<Date>;
  selectedPeriodText = 'Current month';
  constructor() {
    setTimeout(() => {
      if (this.group) {
        let period = localStorage.getItem(this.group + '_period_selector');
        try {
          period = JSON.parse(period);
          if (this.periodIsAllTime(period)) {
            this.selectAllTime();
          } else if (this.periodIsLastMonth(period[0], period[1])) {
            this.selectLastMonth();
          } else if (this.periodIsCurrentMonth(period[0], period[1])) {
            this.selectCurrentMonth();
          } else {
            this.selectedPeriodText = 'Custom';
            this.dateRange = [
              new Date(period[0]),
              new Date(period[1])
            ];
          }
        } catch (error) {
          console.error('Could not parse preferred period', error.message);
        }
      } else {
        this.selectCurrentMonth();
      }
    }, 100);
  }
  periodIsAllTime(period) {
    return !period || !period.length;
  }
  periodIsLastMonth(startDate, endDate) {
    return moment().subtract(1, 'month').startOf('month').isSame(startDate, 'day') &&
      moment().subtract(1, 'month').endOf('month').isSame(endDate, 'day')
  }
  periodIsCurrentMonth (startDate, endDate) {
    return moment().startOf('month').isSame(startDate, 'day') &&
      moment().endOf('month').isSame(endDate, 'day')
  }
  periodIsCustom(period) {
    if (this.periodIsAllTime(period)) {
      return false;
    }
    return !this.periodIsCurrentMonth(period[0], period[1]) && !this.periodIsLastMonth(period[0], period[1]);
  }
  onDateChange (event) {
    if (this.periodIsCustom(event)) {
      this.selectedPeriodText = 'Custom';
    }
    const key = this.group + '_period_selector';
    if (!event || !event.length) {
      localStorage.removeItem(key);
    }
    localStorage.setItem(key, JSON.stringify(event));
    this.onChange.emit({startDate: event[0], endDate: event[1]});
  }
  selectAllTime () {
    this.selectedPeriodText = "All time";
    this.dateRange = [];
    this.onChange.emit({startDate: null, endDate: null});
  }
  selectCurrentMonth () {
    this.selectedPeriodText = "Current month";
    const payload = {
      startDate: moment().startOf("month").toDate(),
      endDate: moment().endOf("month").toDate()
    };
    this.dateRange = [payload.startDate, payload.endDate];
    this.onChange.emit(payload);
  }
  selectLastMonth () {
    this.selectedPeriodText = "Last month";
    const lastMonth = moment().startOf("month").subtract(10, "days");
    const payload = {
      startDate: moment(lastMonth).startOf("month").toDate(),
      endDate: moment(lastMonth).endOf("month").toDate(),
    };
    this.dateRange = [payload.startDate, payload.endDate];
    this.onChange.emit(payload);
  }
}
