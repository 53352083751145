import {Component, OnInit} from '@angular/core';
import {UserProvider} from '../../providers/user.provider';
import {AuthProvider} from '../../shared/providers/auth.provider';

import {Router} from '@angular/router';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  providers: [UserProvider]
})

export class PasswordResetComponent implements OnInit {
  public form: any = {
    passwordConfirm: '',
    password: ''
  };
  public formErrors: any = {};
  public passwordSet = false;
  public nonce: string;
  public saving = false;
  constructor(
    public authProvider: AuthProvider,
    private userProvider: UserProvider,
    private router: Router,
    private toastr: ToastrService
  ) {

  }

  ngOnInit() {
    this.checkForNonce();
  }

  checkForNonce() {
    const search = window.location.search.substring(1);
    const nonce = /nonce=([^&]+)/.exec(search);
    if (nonce && nonce[1]) {
      this.nonce = nonce[1];
    } else {
      setTimeout(() => {
        // send back to login where they can request new recovery
        window.location.href = '/login';
      }, 3000);
      this.toastr.error('Password reset code is missing or expired', 'Error!');
    }
  }
  async submitForm(event) {
    if (this.form.password) {
      if (this.form.password.length < 10) {
        return this.formErrors = {
          password: {message: 'Minimum password length is 10 characters'}
        };
      }
      if (this.form.password !== this.form.passwordConfirm) {
        this.formErrors = {
          password: {message: 'Passwords don\'t match'}
        };
      } else {
        try {
          this.saving = true;
          const response: any = await this.userProvider.resetPassword(this.nonce, this.form.password);
          if (!response.err) {
            this.toastr.success('Password reset successfully', 'Success!');
            setTimeout(() => {
              window.location.href = '/login';
            }, 3000);
          } else {
            this.toastr.error('Password reset failed: ' + (response.err.message || response.err.msg), 'Error!');
          }
        } catch (error) {
          this.toastr.error('Password reset failed: ' + error.message, 'Error!')
        }
        this.saving = false;
      }
    }
  }
}
