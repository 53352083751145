import { Injectable } from '@angular/core';
import { AuthProvider } from '../shared/providers/auth.provider';
import { CanActivate } from '@angular/router';

@Injectable()
export class AffiliateCanActivateGuard implements CanActivate {
  constructor(public authProvider: AuthProvider) {}

  canActivate() {
    if (this.authProvider.hasUser()) {
      const role = this.authProvider.getUser().role;
      if (role === 'affiliate' || role === 'admin') {
        return true;
      } else {
        window.localStorage.setItem('postLoginRedirect', window.location.href);
        window.location.pathname = '/login';
      }
    } else {
      window.localStorage.setItem('postLoginRedirect', window.location.href);
      window.location.pathname = '/login';
    }
  }
}
