import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'capitalizefirst' })
export class CapitalizeFirstLetterPipe implements PipeTransform {
  transform(value: any): any {
    if (!value || typeof value !== 'string') {
      return value;
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
