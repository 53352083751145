<div class="password-reset">
  <img src="/assets/img/current.png" alt="company logo" class="logo img-fluid" />

  <section>
    <div class="input-group">
      <input
        type="password"
        class="form-control mb-3"
        placeholder="New Password"
        [(ngModel)]="form.password"
        [ngClass]="{ 'error': formErrors['password'] }"
      />
    </div>
    <label *ngIf="formErrors.password">{{ formErrors.password.message }}</label>

    <div class="input-group">
      <input
        type="password"
        class="form-control mb-3"
        placeholder="Confirm Password"
        [(ngModel)]="form.passwordConfirm"
        [ngClass]="{ 'error': formErrors.passwordConfirm }"
      />
    </div>
    <label *ngIf="formErrors.password">{{ formErrors.passwordConfirm.message }}</label>

    <button
      class="btn btn-submit btn-block w-100 mb-3"
      (click)="submitForm($event)"
      [disabled]="saving"
    >
      {{saving ? 'Please wait...': 'Reset Password'}}
    </button>
  </section>
</div>
