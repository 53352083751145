<section class="page dashboard">
  <tiles [tiles]="tiles" *ngIf="!loading"></tiles>
  <h5 class="mt-3 mb-3">Below is a list of currently available affiliate links and bonuses offered</h5>
  <div class="affiliate-links" *ngIf="dashboard?.affiliateLinks?.length">
    <app-data-table
      [headers]="tableHeaders"
      [rows]="tableRows"
      [loading]="loading"
      (onCellClick)="cellClicked($event)"
    ></app-data-table>
  </div>
</section>
<ng-template #nameColumn let-value="value">
  <span
    class="link"
    (click)="showCompanyInfo(value.id)"
    *ngIf="value.id !== 'platform'"
  >
    {{ value.label }}
  </span>
  <span *ngIf="value.id === 'platform'">
    {{ value.label }}
  </span>
</ng-template>
<ng-template #companyInfoModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Company Info</h4>
    <button
      type="button"
      class="btn-close close pull-right"
      (click)="companyInfoModalRef?.hide()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="loadingCompany" style="text-align: center; padding: 2em;">
    <app-loader [loading]="true"></app-loader>
  </div>
  <div class="modal-body" *ngIf="!loadingCompany">
    <div class="row align-items-center mb-2">
      <div class="col-4">Name</div>
      <div class="col-8">{{company.name}}</div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-4">Phone</div>
      <div class="col-8">{{company.phone | phone}}</div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-4">Email</div>
      <div class="col-8">{{company.email}}</div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-4">Address</div>
      <div class="col-8">
        {{company.billingAddress.zip}}
        {{company.billingAddress.line1}} {{company.billingAddress.line2}},
        {{company.billingAddress.state}}
      </div>
    </div>
    <div class="row align-items-center mb-2">
      <div class="col-4">Territories</div>
      <div class="col-8">{{getTerritories()}}</div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="w-100 text-end">
      <button
        type="button"
        class="btn btn-cancel btn-sm px-4"
        (click)="companyInfoModalRef.hide()"
      >
        Close
      </button>
    </div>
  </div>
</ng-template>
