import {Component, Input, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, Output, EventEmitter} from '@angular/core';
import {Company} from '../../../interfaces/company.interface';
import {Customer} from '../../../interfaces/customer.interface';
import moment from 'moment/moment';
import {ProductOrderProvider} from '../../providers/product-order.provider';
import {ProductOrder} from '../../../interfaces/product-order.interface';
import {ToastrService} from 'ngx-toastr';
import {User} from '../../../interfaces/user.interface';

@Component({
  selector: 'app-product-orders-table',
  templateUrl: './product-orders-table.component.html',
  styleUrls: ['./product-orders-table.component.scss'],
})
export class ProductOrdersTableComponent implements OnInit, AfterViewInit {
  @ViewChild('productOrderActionColumn', {static: true}) productOrderActionColumn;
  @ViewChild('productOrderNotesColumn', {static: true}) productOrderNotesColumn;
  @ViewChild('expandedTemplate', {static: true}) expandedTemplate;
  @Input() tableClasses = '';
  @Output() onCellClick = new EventEmitter<{ event: any, field: string, row: any }>();
  @Output() onOrdersLoaded = new EventEmitter<{ list: ProductOrder[], _metadata: any }>();
  loadingProductOrders = false;
  productOrderTableHeaders: any[] = []; // standalone orders, separate from fuel order flow.
  productOrderTableRows: any[] = [];
  productOrders: ProductOrder[] = [];
  loadOrdersThrottle: any;
  _customerId: number | undefined; // customer whose orders to show
  _companyId: number | undefined; // company whose orders to show
  _keyword: string | undefined;
  _startDate: string | undefined; // YYYY-MM-DD
  _endDate: string | undefined;
  pagination: any = {
    currentPage: 1,
    pageSize: 5,
    total: 0,
  };
  @Input() user: User; // user who is currently viewing the page. Either: admin|company|customer
  constructor(
    private productOrderProvider: ProductOrderProvider,
    private toastr: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.getProductOrders();
  }
  public ngAfterViewInit(): void {
    this.productOrderTableHeaders = [
      {name: 'Product', field: 'productLabel'},
      // isLink = false if this component is already on specific customer's page.
      {name: 'Order Id', field: 'number', isLink: !window.location.href.match(/\/customers\/[0-9]+/gi)},
      {name: 'Date', field: 'createdAt', date: 'MM/d/y'},
      {name: 'Price', field: 'price', currency: '$', isLink: true},
      {name: 'Status', field: 'statusLabel', style: {'white-space': 'break-spaces'}},
      {name: 'Actions', template: this.productOrderActionColumn},
    ];
    this.changeDetectorRef.detectChanges();
    if (this.user.role !== 'customer') {
      this.productOrderTableHeaders.splice(1, 0, {
          name: 'Customer',
          field: 'customerName',
          isLink: true,
        },
      );
    }
    if (this.user.role === 'admin') {
      this.productOrderTableHeaders.splice(1, 0, {
          name: 'Company',
          field: 'companyName',
        },
      );
    }
    if (this.user.role !== 'customer') {
      this.productOrderTableHeaders.splice(this.productOrderTableHeaders.length - 2, 0, {
          name: 'Notes',
          field: 'notes',
          template: this.productOrderNotesColumn,
        },
      );
    }
  }
  @Input() set companyId (id: number | undefined) {
    this._companyId = id;
    this.getProductOrdersThrottled();
  }
  @Input() set customerId (id: number | undefined) {
    this._customerId = id;
    this.getProductOrdersThrottled();
  }
  @Input() set keyword (txt: string | undefined) {
    this._keyword = txt;
    this.getProductOrdersThrottled();
  }
  @Input() set startDate (date: any) {
    if (date) {
      this._startDate = moment(date).format('YYYY-MM-DD');
    } else {
      this._startDate = undefined;
    }
    this.getProductOrdersThrottled();
  }
  @Input() set endDate (date: any) {
    if (date) {
      this._endDate = moment(date).format('YYYY-MM-DD');
    } else {
      this._endDate = undefined;
    }
    this.getProductOrdersThrottled();
  }

  private async getProductOrdersThrottled () {
    if (this.loadOrdersThrottle) {
      clearTimeout(this.loadOrdersThrottle);
    }
    setTimeout(this.getProductOrders.bind(this), 500);
  }
  private async getProductOrders () {
    this.loadingProductOrders = true;
    try {
      if (
        !this.user ||
        this.user.role === 'company' && this._companyId !== this.user.companyId ||
        this.user.role === 'customer' && this._companyId !== ((this.user) as Customer).deliveryCompanyId
      ) {
        this.toastr.error('Could not load product orders', 'Unauthorized!');
        return;
      }
      this.loadOrdersThrottle = undefined;
      const filters = {
        companyId: this._companyId,
        customerId: this._customerId,
        keyword: this._keyword,
        _limit: this.pagination.pageSize,
        _offset: (this.pagination.currentPage - 1) * this.pagination.pageSize,
        startDate: this._startDate,
        endDate: this._endDate,
      };
      const res = await this.productOrderProvider.list(filters);
      this.onOrdersLoaded.emit(res);
      if (res && res._metadata) {
        this.pagination.total = res._metadata.total || 0;
      }
      const getStatusLabel = (order) => {
        const paymentIntent = order.paymentIntent;
        let actionRequired = '';
        if (order.status === 'requires_action' && paymentIntent) {
          if (paymentIntent.next_action) {
            actionRequired = ': ' + paymentIntent.next_action.replace('_', ' ');
          } else {
            // i.e requires source
            actionRequired = ': ' + paymentIntent.status.replace('_', ' ');
          }
        }
        const statusCodes = {
          intent: 'Intent',
          processing: 'Processing',
          completed: 'Completed',
          payment_failed: 'Payment Failed',
          payment_succeeded: 'Payment Succeeded',
          canceled: 'Canceled',
          requires_action: 'Requires Action' + actionRequired,
        }
        return statusCodes[order.status] || 'N/A';
      }
      this.productOrders = res.list || [];
      this.productOrderTableRows = this.productOrders.filter(o => o.paymentIntent).map((order) => {
        return {
          id: order.id,
          number: order.number,
          date: moment(order.createdAt).toDate(),
          price: order.price,
          status: order.status,
          statusLabel: getStatusLabel(order),
          customerName: order.customer ? order.customer.firstName + ' ' + order.customer.lastName : '',
          companyName: order.company ? order.company.name : '',
          customerId: order.customer.id,
          productLabel: order.companyProduct.label || order.product.label,
          notes: order.notes,
          financeFee: this.getFee(order, 'financeFee'),
          transactionFee: this.getFee(order, 'transactionFee'),
          transactionTotal: this.getTransactionTotal(order),
          transactionNet: this.getTransactionNet(order),
          transferStatus: this.getTransferStatus(order),
          transactions: this.getOrderTransactions(order),
          payoutSummary: this.getPayoutSummary(order),
          _editingNotes: false,
        }
      });
    } catch (error) {
      console.error('Failed to load product orders:' + error.message);
    }
    this.loadingProductOrders = false;
  }
  private getOrderTransactions (order: ProductOrder) {
    const chargeIndexes = Object.keys(order.paymentIntent.charges.data);
    if (!chargeIndexes.length) {
      return [];
    }
    /*
    * Format all charges made to the customer into transaction rows.
    * */
    const rows: Array<{createdAt: string; amount: number; fee: number; status: string; availableAt: string; }> = [];
    chargeIndexes.forEach((index) => {
      const charge = order.paymentIntent.charges.data[index];
      const destinationPayment = charge?.transfer?.destinationPayment;
      const balanceTransaction = destinationPayment?.balanceTransaction;
      const row = {
        createdAt: moment.unix(charge.created).format('MM/DD/y'),
        availableAt: balanceTransaction?.availableOn ?
          moment.unix(balanceTransaction.availableOn).format('MM/DD/y') : '',
        amount: charge.amount / 100,
        fee: charge.applicationFeeAmount / 100,
        status: destinationPayment ? destinationPayment.status : charge.status,
        netAmount: ((charge.amountCaptured || charge.amount) - charge.applicationFeeAmount) / 100,
        fundsAvailable: balanceTransaction?.status === 'available',
        amountRefunded: charge.amountRefunded / 100,
        amountCaptured: charge.amountCaptured / 100,
      };
      if (charge.amountCaptured && charge.amount !== charge.amountCaptured) {
        row.status = 'partially_captured';
      }
      if (charge.amountRefunded && charge.amountCaptured !== charge.amountRefunded) {
        row.status = 'partially_refunded';
      } else if (charge.amountRefunded && charge.amountCaptured === charge.amountRefunded) {
        row.status = 'refunded';
      }
      rows.push(row);
    });
    return rows;
  }
  private getPayoutSummary (order: ProductOrder) {
    if (!order || !order.payout) {
      return '';
    }
    const payout = order.payout;
    const status = payout.status;
    const date = moment.unix(payout.arrivalDate).format('MM/DD/y');
    const amount = '$' + (payout.amount / 100).toFixed(2);
    if (status === 'paid') {
      return `${amount} deposited to ${this.user && this.user.role === 'admin' ? 'Their' : 'Your'} bank account on ${date}`;
    }
    if (status === 'failed') {
      return `Payout failed with error code "${payout.failureCode}"`;
    }
    if (status === 'pending') {
      return `${amount} expected to be available on ${date}`;
    }
    return status;
  }
  private getTransferStatus (order: ProductOrder) {
    if (!order?.paymentIntent?.charges) {
      return '';
    }
    let availableOn = 0;
    Object.keys(order.paymentIntent.charges.data).forEach((index) => {
      const charge = order.paymentIntent.charges.data[index];
      // show the status for most recent transfer
      const balanceTransaction = charge?.transfer?.destinationPayment?.balanceTransaction;
      if (!balanceTransaction) {
        return;
      }
      if (balanceTransaction.availableOn > availableOn) {
        availableOn = balanceTransaction.availableOn;
      }
    });
    if (!availableOn) {
      return '';
    }
    const dateStr = moment.unix(availableOn).format('MM/DD/y');
    return availableOn < moment().unix() ?
      `Paid to your Stripe account on ${dateStr}` :
      `Expected to be available on your Stripe account on ${dateStr}`;
  }
  private getTransactionTotal (order: ProductOrder) {
    return order.paymentIntent ? order.paymentIntent.amount / 100 : 0;
  }
  private getTransactionNet (order: ProductOrder) {
    // what was actually transferred to the company.
    // total minus all fees and refunds.
    if (!order || !order.paymentIntent) {
      // either an error or order intent is still in the process of being charged.
      return 0;
    }
    // ToDo: check how stripe platform fees are applied for AfterPay etc and subtract those too.
    let net = order.paymentIntent.amount;
    if (order.paymentIntent.applicationFeeAmount) {
      net -= order.paymentIntent.applicationFeeAmount;
    }
    return net / 100;
  }
  private getFee (order, feeName: string) {
    if (!order.fees) {
      return;
    }
    return order.fees.filter(fee => fee.name === feeName)[0];
  }
  public async setProductOrderStatus (order, status) {
    try {
      order._saving = true;
      const updatedOrder: any = await this.productOrderProvider.setStatus({
        companyId: this._companyId,
        orderId: order.id,
        status,
      });
      order.status = updatedOrder.status;
      this.toastr.success('Order updated successfully', 'Success!');
    } catch (error) {
      console.error('Failed to update product order status:' + error.message);
    }
    order._saving = false;
  }
  public async setProductOrderNotes (order) {
    try {
      order._saving = true;
      await this.productOrderProvider.setNotes({
        companyId: this._companyId,
        orderId: order.id,
        notes: order.notes,
      });
      order._editingNotes = false;
      this.toastr.success('Order notes updated successfully', 'Success!');
    } catch (error) {
      console.error('Failed to update notes:' + error.message);
    }
    order._saving = false;
  }
  /*
  * @Param {event: any, field: string, row: any} $event
  * */
  public cellClicked ($event) {
    if ($event.field === 'price') {
      $event.row.expanded = !$event.row.expanded;
    }
    if (this.onCellClick) {
      this.onCellClick.emit($event);
    }
  }
  public onPageChange(event: { page: number, itemsPerPage: number }) {
    this.pagination.currentPage = event.page;
    this.getProductOrdersThrottled();
  }
}
