import { filter } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthProvider } from './shared/providers/auth.provider';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import { Subscription } from 'rxjs';
import {environment} from '../environments/environment';
import {Affiliate} from './interfaces/affiliate.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AuthProvider],
})
export class AppComponent implements OnInit, OnDestroy {
  public user;
  public affiliate: Affiliate;
  public isPlainPage = false;
  public sideMenuLinks: Array<{
    href?: string;
    label: string;
    icon?: string;
    id?: string;
  }> = [];

  private subscriptions: Subscription[] = [];

  constructor(
    public authProvider: AuthProvider,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    const s1 = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // TODO: Add missing routes, should be more...
        const url = event.urlAfterRedirects;
        const plainPageUrls = [
          '/login',
          '/affiliate/login',
          '/affiliate/setup',
          '/logout',
          '/pricing',
          '/customer/new-order',
          '/company/setup',
          '/customer/setup/password',
          '/password-reset',
          '/company/activate',
          '/affiliate/activate',
          '/claim'
        ];
        if (url && url.match(/^\/[0-9]+\//)) {
          /* handle custom company uri's which begin with companyId
          * each company will have their own uri for customer login/order
          * /4/company-name/login
          * /4/company-name/order
          * /4/order  <- if company has not filled in their custom url
          * Note: Company name part is optional
          */
          const urlSegments = url.split('/').filter(e => e.length);
          const segment1 = urlSegments[1];
          const segment2 = urlSegments[2];
          const companyPlainUrls = ['login', 'logout', 'order', 'password-reset', 'register', 'product'];
          this.isPlainPage = !!companyPlainUrls.filter((uri) => {
            return (segment1 && segment1.match('^' + uri)) ||
              (segment2 && segment2.match('^' + uri))
          }).length;
        } else {
          this.isPlainPage = plainPageUrls.some((plainUrl) => {
            const plainRouteRegex = new RegExp('^' + plainUrl, 'i');
            return plainRouteRegex.test(url);
          });
        }
        this.user = this.authProvider.getUser();
        this.setMenuLinks();
      });

    this.subscriptions.push(s1);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  private setMenuLinks() {
    if (!this.user) {
      return;
    }

    switch (this.user.role) {
      case 'admin': {
        this.sideMenuLinks = this.getAdminMenuLinks();
        return;
      }

      case 'customer': {
        this.sideMenuLinks = this.getCustomerMenuLinks();
        return;
      }

      case 'company': {
        this.sideMenuLinks = this.getCompanyMenuLinks();
        return;
      }

      case 'affiliate': {
        this.sideMenuLinks = this.getAffiliateMenuLinks();
        return;
      }
    }
  }

  private getAdminMenuLinks() {
    return [
      {
        href: `/admin/dashboard`,
        label: 'Dashboard',
        icon: 'dashboard-computer.png',
      },
      {
        href: `/admin/customers`,
        label: 'Customers',
        icon: 'dashboard-customer.png',
      },
      {
        href: `/admin/products`,
        label: 'Products',
        icon: 'dashboard-product.png',
      },
      {
        href: `/admin/companies`,
        label: 'Companies',
        icon: 'dashboard-truck.png',
      },
      {
        href: `/admin/messaging`,
        label: 'Messaging',
        icon: 'dashboard-messaging.png',
        id: 'messaging-btn',
      },
    ];
  }

  private getCustomerMenuLinks() {
    return [
      {
        href: `customer/dashboard`,
        label: 'Dashboard',
        icon: 'dashboard-computer.png',
      },
      {
        href: `customer/dashboard/deliveries`,
        label: 'Deliveries',
        icon: 'dashboard-customer.png',
      },
      /*
      {
        href: `customer/usage`,
        label: "Usage",
        icon: "dashboard-product.png",
      },
      {
        href: `customer/my-tank`,
        label: "My Tank",
        icon: "dashboard-truck.png",
        id: "truck-btn",
      },*/
    ];
  }
  private getAffiliateMenuLinks() {
    return [
      {
        href: `affiliate/dashboard`,
        label: 'Dashboard',
        icon: 'dashboard-computer.png',
      }
    ];
  }

  private getCompanyMenuLinks() {
    return [
      {
        href: `company/dashboard`,
        label: 'Dashboard',
        icon: 'dashboard-computer.png',
      },
      {
        href: `company/customers`,
        label: 'Customers',
        icon: 'dashboard-customer.png',
      },
      {
        href: `company/products`,
        label: 'Products',
        icon: 'dashboard-product.png',
      },
      {
        href: `company/us`,
        label: 'Company',
        icon: 'dashboard-truck.png',
        id: 'truck-btn',
      },
      {
        href: `company/import-customer`,
        label: 'Activate',
        icon: 'dashboard-import.png',
        id: 'import-btn',
      },
      {
        href: `company/messages`,
        label: 'Messaging',
        icon: 'dashboard-messaging.png',
        id: 'messaging-btn',
      },
    ];
  }
}
