import { Injectable } from "@angular/core";
import { API } from "./api.provider";
import { AuthProvider } from "./auth.provider";

interface NewOrderPayload {
  customerId: number;
  tankId: number;
  product: 'oil' | 'propane' | 'offsite_diesel';
  orderedAmountType: 'gallons' | 'fill';
  deliveryNotes?: string;
  orderedAmount?: number;
  priceType?: 'pool'; // ToDo: remove pool references
  sameDayDelivery?: boolean;
  nextDayDelivery?: boolean;
  emergencyPrime?: boolean;
  tuneUp?: boolean;
  /* only admin/company can submit the values below */
  deliveryDate?: string;
  pricePerGallon?: number;
  inspection?: boolean;
  smallDeliveryFee?: boolean; // <- ToDo: should this be determined automatically based on order amount or
                             //    entered manually by company
}
@Injectable()
export class OrderProvider {
  constructor(private api: API, private authProvider: AuthProvider) {}

  list(search?) {
    return this.api.get("/order/list", search);
  }
  payments(search?) {
    return this.api.get("/order/payments", search);
  }
  getAll(search?) {
    return this.api.get("/order", search);
  }

  getOrder(id) {
    return this.api.get(`/order/${id}`);
  }

  newOrder(body) {
    return this.api.post("/order/admin", body);
  }

  postOrder(body: NewOrderPayload) {
    return this.api.post("/order", body);
  }

  /*
  * @Param {Object} args
  * @Param {String} args.deliveryNotes - optional
  * @Param {Number} args.additionalCharges - optional
  * @Param {Number} args.deliveredAmount - required when status = 'delivered'
  * */
  setOrderDeliveryStatus(id, status, args?: object) {
    if (!args) {
      args = {}
    }
    return this.api.patch(`/order/${id}/status`, { deliveryStatus: status, ...args });
  }
  retryProcessingFailedTransfer({orderId, transferEventId}) {
    return this.api.post(`/order/${orderId}/retry-transfer/${transferEventId}`, {});
  }
  retryProcessingCharge({orderId, chargeEventId}) {
    // charge was created successfully, but we failed to initialize the transfer
    return this.api.post(`/order/${orderId}/retry-processing-charge/${chargeEventId}`, {});
  }
  chargeOrder(id) {
    return this.api.patch(`/order/${id}/charge`, {});
  }

  /*
   * @param {int} companyId - company which would handle the delivery
   * */
  getDeliveryDates(opts?: {
    companyId: number;
    zip: string;
    sameDayDelivery?: boolean;
    nextDayDelivery?: boolean;
    product?: "oil" | "propane";
  }) {
    return this.api.get(`/order/delivery-dates`, opts);
  }
  getTransactionDetails (orderId: number) {
    return this.api.get(`/order/${orderId}/transactions`, {});
  }
}
