import {Component, OnInit, Output, Input, EventEmitter} from '@angular/core';
import { CustomerProvider } from '../../../providers/customer.provider';
import { AuthProvider } from '../../providers/auth.provider';
import {Customer} from '../../../interfaces/customer.interface';
import {User} from '../../../interfaces/user.interface';
import {AppHeaderService} from '../app-header/app-header.service';
import {environment} from '../../../../environments/environment';
import {UserProvider} from '../../../providers/user.provider';
import {OrderProvider} from '../../providers/order.provider';
import { ToastrService } from 'ngx-toastr';

declare var Stripe: any;

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss'],
  providers: [
    CustomerProvider,
    AuthProvider,
    UserProvider,
  ]
})
/*
* ToDo: Not used anymore: remove
* */
export class NewOrderComponent implements OnInit {
  @Input()
  customer: Customer;
  @Output()
  onNewOrderSuccess = new EventEmitter<{card: any, customer: any, order: any}>();
  @Output()
  onNewOrderError = new EventEmitter<{error: any, message: string}>();
  @Output()
  onCancel = new EventEmitter<any>();
  user: User;
  // customer: Customer = Customer();
  bsConfig  = { containerClass: 'theme-dark-blue' };
  products = [
    {label: 'Propane', name: 'propane'},
    {label: 'Heating Oil', name: 'oil'},
    /*{label: 'Tank Tracker', name: 'tanktracker'},*/
  ];
  additionalProducts = [
    {label: 'Next Day Delivery', name: 'next_day_delivery', enabled: false},
    {label: 'Same Day Delivery', name: 'same_day_delivery', enabled: false},
    {label: 'Prime', name: 'prime', enabled: false},
    {label: 'Tune Up', name: 'tune_up', enabled: false},
    {label: 'Small Delivery Fee', name: 'small_delivery_fee', enabled: false},
  ];
  order: any = {
    priceType : 'pool',
    orderedAmountType : 'gallons',
    product: '',
    amount: 0,
    pricePerGallon: 0,
    deliveryNotes: '',
    deliveryDate: '',
    additionalProducts: this.additionalProducts.slice()
  };
  public stripe: any;
  public card = null;
  public cards: any;
  public defaultCard: any;
  /*
  * Object {
  *   field: error message,
  *   field2: error message 2
  * }
  * */
  public validationErrors: any = {};
  constructor(
    private customerProvider: CustomerProvider,
    private authProvider: AuthProvider,
    private headerService: AppHeaderService,
    private userProvider: UserProvider,
    private orderProvider: OrderProvider,
    private toastr: ToastrService,
  ) {}
  ngOnInit() {
    this.user = this.authProvider.getUser();
    if (!this.customer) {
      this.customer = Customer();
    }
    this.initStripe();
  }
  initStripe() {
    this.stripe = Stripe(environment.Stripe.API_KEY);
    const elements = this.stripe.elements();
    this.getCards();
    const style = {
      base: {
        borderColor : '#000',
        color: '#303238',
        fontSize: '16px',
        lineHeight: '48px',
        fontSmoothing: 'antialiased'
      },
      invalid: {
        color: '#e5424d',
        ':focus': {
          color: '#303238',
        },
      },
    };
    this.card = elements.create('card', {style : style});
    this.card.mount('#card-element');
    this.card.addEventListener('change', function(event) {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }
  getCards() {
    if (!this.customer.id) {
      return ;
    }
    this.userProvider.getUserCards(this.customer.id)
      .then((response: any) => {
        this.cards = response.list;
        this.defaultCard = this.cards.some(card => card.isDefault) ? this.cards.filter(card => card.isDefault === true)[0] : this.defaultCard;
        if (this.defaultCard) {
          this.order.cardId = this.defaultCard.id;
        }
      });
  }
  async addCard() {
    const result = await this.stripe.createToken(this.card);
    return await this.userProvider.addCard(result.token.id);
  }
  onDateChange (evt) {
    console.log('onDateChange', evt);
  }
  customerChanged (customer: Customer) {
    this.customer = customer;
  }
  async processOrder () {
    try {
      console.log('processOrder');
      this.validationErrors = {};
      // todo: existing card select
      const card = await this.stripe.createToken(this.card);
      let customer;
      if (this.customer && this.customer.id) {
        customer = await this.updateCustomer({card});
      } else {
        customer = await this.addCustomer({card});
      }

      const orderPayload = Object.assign({}, this.order, {
        tankId: customer.tank.id,
        inspection: customer.tank.inspected,
        customerId: customer.id,
      });
      const order = await this.orderProvider.postOrder(orderPayload);
      this.toastr.success('New order created', 'Success!');
      this.onNewOrderSuccess.next({card, customer, order});
    } catch (error) {
      console.log('Post order error', error);
      this.onNewOrderError.next({error, message: error.message});
      if (error.error && error.error.errors) {
        const errors = error.error.errors;
        const fields = Object.keys(errors);
        fields.forEach((field) => {
          this.validationErrors[field] = errors[field].message;
        });
      }
    }
  }
  updateCustomer ({card}): Promise<Customer> {
    const update: any = ['email', 'firstName', 'lastName', 'phone', 'sendSms', 'role', 'billingAddress']
      .reduce((prev, current) => {
        prev[current] = this.customer[current];
        return prev;
      }, {});
    const address = this.customer.address;
    update.billingAddress = {
      city: address.city,
      state: address.state,
      zip: address.zip,
      line1: address.line1,
      line2: address.line2,
    };
    const tank = this.customer.tank;
    return this.userProvider.updateCustomer(this.customer.id, {
      ...update,
      tank: {
        size: tank.size,
        location: tank.location,
        description: tank.description
      },
      _stripe: {
        token: card.token.id
      }
    });
  }
  addCustomer ({card}): Promise<Customer> {
    this.customer.tank.address = this.customer.address;
    return this.userProvider.addCustomer({
      ...this.customer,
      sendSms: true,
      billingAddress: this.customer.address,
      _activationUrl: window.location.origin + '/customer/setup/password', /* todo: <-- */
      _stripe: {
        token: card.token.id
      }
    });
  }
  /*
  * Post user expected data: {
    "email": "rainer.plumer@hotmail.com",
    "firstName": "Rainer",
    "lastName": "Plumer",
    "phone": "53876229",
    "role": "customer",
    "existing": false,
    "billingAddress": {
        "line1": "England, maple street 3",
        "city": "Essex 18",
        "zip": "01945",
        "state": "Massachusetts",
        "line2": "aptunit"
    },
    "tank": {
        "location": "back",
        "description": "England, maple street 3"
    },
    "_activationUrl": "http://localhost:4200/signup/password",
    "priceType": "pool",
    "sendSms": false,
    "_stripe": {
        "token": "tok_1JVKs7JcsMl8lIii2kY6x2Em"
    }
  * */
  async submitOrder () {
    const card = await this.stripe.createToken(this.card);
    const user = await this.userProvider.addUser({
      ...this.customer,
      billingAddress: this.customer.address,
      priceType: 'pool',
      sendSms: true,
      _stripe: {
        token: card.token.id
      }
    });
  }
  onShowAdditionalProducts () {
    setTimeout(() => {
      const e = document.getElementById('product-additional');
      const lastChild: any = e.children[e.children.length - 1];
      if (lastChild.scrollIntoViewIfNeeded) {
        lastChild.scrollIntoViewIfNeeded();
      }
    }, 100);
  }
  cancel () {
    this.onCancel.next(true);
  }
  get additionalProductBtnText () {
    const selectedProducts = this.order.additionalProducts.filter(p => p.enabled);
    if (!selectedProducts.length) {
      return 'select products'
    }
    const s = selectedProducts.length > 1 ? 's' : '';
    return `${selectedProducts.length}  product${s} selected`;
  }
}
