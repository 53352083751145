<div
  class="additional-products-dropdown"
  dropdown [insideClick]="true"
  *ngIf="!alwaysOpen"
  (onShown)="onShowAdditionalProducts()"
>
  <app-loader class="size-2" [loading]="loadingProducts"></app-loader>
  <button
    id="button-basic"
    dropdownToggle
    type="button"
    class="btn btn-default-dark dropdown-toggle {{btnClass}} "
    aria-controls="dropdown-basic"
    *ngIf="triggerType !== 'link'"
  >
    {{additionalProductBtnText}}
    <span class="caret"></span>
  </button>
  <span
    class="link"
    dropdownToggle
    aria-controls="dropdown-basic"
    id="button-basic"
    *ngIf="triggerType === 'link'"
  >
    {{additionalProductBtnText}}
  </span>
  <ul id="product-additional"
    *dropdownMenu
    class="dropdown-menu dropdown-menu-right"
    role="menu"
    aria-labelledby="button-basic"
  >
    <li role="menuitem" *ngFor="let product of products">
      <label for="additional-{{product.name}}">
        {{product.label}}: +{{product.value | currency}}
      </label>
      <input
        id="additional-{{product.name}}"
        type="checkbox"
        [(ngModel)]="product.selected"
        (change)="onSelect(product)"
      >
    </li>
  </ul>
</div>
<table *ngIf="alwaysOpen">
  <tr *ngFor="let product of products">
    <td>
      <label for="{{product.name}}-checkbox">
        {{product.label}}: +{{product.value | currency}}
      </label>
    </td>
    <td>
      <input
        type="checkbox"
        id="{{product.name}}-checkbox"
        [(ngModel)]="product.selected"
      >
    </td>
  </tr>
</table>
