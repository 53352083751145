import { Injectable } from "@angular/core";
import { API } from "../shared/providers/api.provider";

@Injectable()
export class ConfigProvider {
  constructor(private api: API) {}

  deleteOne(opts: any) {
    const name = opts.name;
    const companyId = opts.companyId;
    const userId = opts.userId;
    if (userId) {
      return this.api.delete(`/customer/${userId}/config/${name}`);
    }
    return this.api.delete(`/company/${companyId}/config/${name}`);
  }
  getAllSelf() {
    return this.api.get('/customer/self/config');
  }
  getAll(opts: any = {}) {
    const companyId = opts.companyId;
    const userId = opts.userId;
    const path = `${
      userId ? "/customer/" + userId : companyId ? "/company/" + companyId : ""
    }/config`;
    return this.api.get(path);
  }
  getOne(opts: any, options?): Promise<any> {
    const name = opts.name;
    const companyId = opts.companyId;
    const userId = opts.userId;
    const query: any = parseInt(opts.zoneId, 10) > 0 ? {zoneId: opts.zoneId} : {};
    if (opts.zip) {
      query.zip = opts.zip;
    }
    return this.api.get(
      `${
        userId
          ? "/customer/" + userId
          : companyId
          ? "/company/" + companyId
          : ""
      }/config/${name}`,
      query,
      options
    );
  }

  createJson(data: any) {
    return this.api.post(`/config/product-price`, data);
  }

  createJsonCompany(companyId: any, data: any) {
    return this.api.post(`/company/${companyId}/config/product-price`, data);
  }

  update(opts: any) {
    const name = opts.name;
    const companyId = opts.companyId;
    const userId = opts.userId;
    const body: any = {
      value: opts.value
    }
    if (parseInt(opts.zoneId, 10) > 0) {
      body.zoneId = opts.zoneId;
    }
    return this.api.patch(
      `${
        userId
          ? "/customer/" + userId
          : companyId
          ? "/company/" + companyId
          : ""
      }/config/${name}`,
      body
    );
  }

  updateBulk(opts: {values: any; companyId?: number; userId?: number; serviceCompanyId?: number}) {
    const values = opts.values;
    const companyId = opts.companyId;
    const userId = opts.userId;
    const path = `${
      userId ? "/customer/" + userId : companyId ? "/company/" + companyId : ""
    }/config/batch`;
    return this.api.patch(path, values);
  }
  updateBulkSelf(values: any) {
    return this.api.patch('/customer/self/config/batch', values);
  }
  setProductStatus({
    companyId,
    productName,
    value,
  }: {
    companyId: number;
    productName: string;
    value: number;
  }) {
    const path = `/company/${companyId}/config/product/${productName}/status`;
    return this.api.patch(path, { value });
  }
  setProductFee({
   companyId,
   zoneId,
   productName,
   value,
   }: {
    companyId: number;
    zoneId?: any;
    productName: string;
    value: number;
  }) {
    const body: any = {value};
    if (parseInt(zoneId, 10) > 0) {
      body.zoneId = zoneId;
    }
    const path = `/company/${companyId}/config/product/${productName}/fee`;
    return this.api.patch(path, body);
  }
  /*
  * Sets fixed discount in dollars for users who use ACH payment method.
  * */
  setACHDiscount(value: number) {
    const path = `/config/achDiscount`;
    return this.api.patch(path, { value });
  }
  setFees({ companyId, fees }: { companyId: number; fees: any }) {
    const path = `/company/${companyId}/config/fees`;
    return this.api.patch(path, { fees });
  }
  toggleTankTrackerAutomaticInspections({ companyId, value }: { companyId: number; value: boolean }) {
    const path = `/company/${companyId}/config/tankTrackerAutomaticInspections`;
    return this.api.patch(path, { value });
  }
}
