import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Customer} from '../../../interfaces/customer.interface';
import {GasTank} from '../../../interfaces/gas-tank.interface';
import {TankProvider} from '../../providers/tank.provider';

import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-customer-gas-tank',
  templateUrl: './customer-gas-tank.component.html',
  styleUrls: ['./customer-gas-tank.component.scss'],
  providers: [TankProvider]
})

export class CustomerGasTankComponent implements OnInit {
  @Input() saveOnNext = false; // if true, clicking "Next" will save the tank before continuing
  @Input() nextBtnText = '';
  @Input() sizeInputDisabled = false;
  @Input() public title = '';
  @Input()
  public userRole: 'admin' | 'customer' | 'company'; // who is currently filling the form
  @Input()
  public customer: Customer;
  @Input()
  public classes: string;
  @Output()
  public onChange = new EventEmitter<GasTank>();
  @Output()
  public onNext = new EventEmitter<GasTank>();
  public saving = false;
  public validationErrors: any = {};
  public locationOptions = [
    {value: 'front', label: 'Front', icon: 'icn-houseBottom.svg'},
    {value: 'right', label: 'Right', icon: 'icn-houseRight.svg'},
    {value: 'back', label: 'Back', icon: 'icn-houseBack.svg'},
    {value: 'left', label: 'Left', icon: 'icn-houseLeft.svg'},
  ];
  constructor(
    public toastr: ToastrService,
    private tankProvider: TankProvider,
  ) {}
  ngOnInit() {
    if (!this.customer.gasTank) {
      this.customer.gasTank = GasTank({
        gas: 'propane',
        address: this.customer.address,
        userId: this.customer.id,
      });
    }
  }
  async nextBtnClick () {
    if (!this.customer.gasTank.size && !this.sizeInputDisabled) {
      return this.toastr.error('Please enter tank size', 'Error!');
    }
    if (this.saveOnNext) {
      if (this.saving) {
        return;
      }
      const nextBtnText = this.nextBtnText;
      try {
        this.validationErrors = {};
        this.nextBtnText = 'Saving, please wait...'
        this.saving = true;
        this.customer.gasTank = await this.tankProvider.createGasTank(this.customer.gasTank) as GasTank;
        this.saving = false;
        this.nextBtnText = nextBtnText;
      } catch (errorResponse) {
        if (errorResponse.error && errorResponse.error.errors) {
          this.validationErrors = errorResponse.error.errors;
        }
        this.saving = false;
        this.nextBtnText = nextBtnText;
        return this.toastr.error(errorResponse.error.message, 'Error!');
      }
    }
    this.onNext.next(this.customer.gasTank);
  }
  keyUp() {
    setTimeout(() => {
      this.onChange.emit(this.customer.gasTank);
    }, 32);
  }
  get isPowerUser () {
    return this.userRole === 'admin' || this.userRole === 'company';
  }
}
