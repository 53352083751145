<form id="payment-form">
  <div id="payment-element">
    <!-- Elements will create form elements here -->
  </div>
  <button
    id="submit"
    class="mt-4 btn btn-default-dark no-radius btn-lg w-100"
    *ngIf="!loading"
  >
    {{submitBtnText}}
  </button>
  <app-loader [loading]="loading" [center]="true"></app-loader>
  <div id="error-message">
    <!-- Display error message to your customers here -->
  </div>
</form>
