import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UserProvider} from '../../../providers/user.provider';
import {AuthProvider} from '../../providers/auth.provider';
import {Card} from '../../../interfaces/card.interface';
import {environment} from '../../../../environments/environment';
import {ToastrService} from 'ngx-toastr';
import moment from 'moment';
import {BsModalService} from 'ngx-bootstrap/modal';

declare var Stripe: any;
declare let Plaid: any;

@Component({
  selector: 'app-stripe-card-selector',
  templateUrl: './stripe-card-selector.component.html',
  styleUrls: ['./stripe-card-selector.component.scss'],
  providers: [UserProvider]
})

export class StripeCardSelectorComponent implements OnInit {
  @ViewChild('addCardModalTemplate', {static: true}) addCardModalTemplate;
  /*
  * @Param {Object<{name: string, value: number | string}>} achDiscount - if provided, user is incentivized to switch payment method
  * */
  @Input() achDiscount;
  /*
  * @Param {int} userId - user who will receive the new card. If undefined, then current user is assumed
  * */
  @Input() public userId: any;
  @Input() public classes: string;
  @Input() public cards: any;
  @Input() public selectedCard: Card | 'new';
  @Output()
  public onCardSelect = new EventEmitter<Card>();
  @Output() isAddingNewCard = new EventEmitter<boolean>();
  public plaid = null;
  public plaidData = null;
  public stripe: any;
  public cardElement: any;
  public cardError: string;
  public cardIsValid: boolean;
  public addCardModalRef: any;
  public addingCard = false;
  public connectedBankAccount: any;
  constructor(
    public authProvider: AuthProvider,
    public modalService: BsModalService,
    private userProvider: UserProvider,
    private toastr: ToastrService,
  ) {
    /*
    * Test Cards:
    * 4242424242424242 - visa
    * 5555555555554444 - mastercard
    * 378282246310005 -american express
    * 6011111111111117 - discover
    * 6200000000000005 - union pay
    * */
  }
  ngOnInit() {
    if (!this.selectedCard && this.cards && this.cards.length) {
      this.selectedCard = this.cards.filter(card => card.isDefault)[0];
    }
    if (this.cards) {
      this.connectedBankAccount = this.cards.filter(card => card.object === 'bank_account' && card.isDefault)[0];
    }
    this.modalService.onShow.subscribe(() => {
      this.isAddingNewCard.emit(true)
      this.initStripe();
      this.initPlaid();
    });
    this.modalService.onHide.subscribe(() => {
      this.isAddingNewCard.emit(false);
      setTimeout(() => {
        // if modal was closed and no new card was added, set selected card back to empty
        // so user can retry
        if (this.selectedCard === 'new') {
          this.selectedCard = undefined;
        }
      }, 100);
    });
  }
  initStripe() {
    setTimeout(() => {
      if (!document.getElementById('card-element')) {
        return ;
      }
      this.stripe = Stripe(environment.Stripe.API_KEY);
      const elements = this.stripe.elements();
      const style = {
        base: {
          borderColor : '#000',
          color: '#303238',
          fontSize: '16px',
          lineHeight: '48px',
          fontSmoothing: 'antialiased'
        },
        invalid: {
          color: '#e5424d',
          ':focus': {
            color: '#303238',
          },
        },
      };
      this.cardElement = elements.create('card', {style});
      this.cardElement.mount('#card-element');
      this.cardElement.addEventListener('change', (event) => {
        this.cardIsValid = event.complete && !event.error;
        this.cardError = event.error ? event.error.message : '';
      });
    }, 100);
  }
  initPlaid () {
    // enable connecting bank account to Stripe using Plaid
    if (typeof Plaid === 'object') {
      this.plaid = Plaid.create({
        selectAccount: true,
        env: environment.production ? 'production' : 'sandbox',
        clientName: 'Current',
        key: environment.Plaid.PUBLIC_KEY,
        product: ['auth'],
        onSuccess: (publicToken, metadata) => {
          this.plaidData = {
            publicToken: publicToken,
            accountId: metadata.account_id,
            metadata: metadata
          };
          this.addCard();
        },
        onExit: (err, metadata) => {
          this.toastr.error(err.message, 'Plaid Error!');
          console.log('err', err, 'metadata', metadata);
        },
      });
    } else {
      setTimeout(() => {
        this.initPlaid();
      }, 100);
    }
  }
  connectPlaid() {
    this.plaid.open();
  }
  onCardChange () {
    if (this.selectedCard === 'new') {
      this.openAddCardModal(this.addCardModalTemplate);
    } else {
      this.onCardSelect.emit(this.selectedCard as Card);
    }
  }
  getCardExpiry (card) {
    if (card.object === 'bank_account') {
      return '';
    }
    return moment().month(card.expMonth - 1).year(card.expYear).format('MM/YY');
  }
  addingNewCardEnabled () {
    // if user wants to select a new card or there are no cards to select from
    return (this.selectedCard === 'new' || !this.cards || !this.cards.length);
  }
  async addCard () {
    try {
      this.addingCard = true;
      const result = this.plaidData ?
        await this.userProvider.createPlaidToken(this.plaidData) :
        await this.stripe.createToken(this.cardElement);
      if (result.token && result.token.id) {
        const response: {err: any, data: Card} = await this.userProvider.addCard(result.token.id, this.userId) as any;
        if (response.data && response.data.id) {
          this.addingCard = false;
          this.addCardModalRef.hide();
          this.selectedCard = response.data;
          return this.onCardSelect.next(response.data);
        }
      }
      this.toastr.error('Error: Invalid CC token', 'Whoops!');
    } catch (error) {
      this.toastr.error('Error: ' + error.message, 'Whoops!');
    }
    this.addingCard = false;
  }
  openAddCardModal(template: any): void {
    this.addCardModalRef = this.modalService.show(template, {
      ignoreBackdropClick: true,
    });
  }
  public getCardDescription (card: Card) {
    if (!card || !card.id) {
      return 'n/a';
    }
    if (card.object === 'bank_account') {
      return `${card.bankName} | ${card.last4}`;
    }
    const exp = moment().month(card.expMonth - 1).year(card.expYear).format('MM/YY');
    return `${card.brand} | ${card.last4} | ${exp}`;
  }
  public get achDiscountEnabled () {
    if (this.connectedBankAccount || !this.achDiscount || !this.achDiscount.value) {
      return false;
    }
    return parseFloat(this.achDiscount.value) > 0
  }
}
