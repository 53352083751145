<div style="position: relative">
  <div class="input-group search" [class.w-100]="fullWidth">
    <input
      type="text"
      class="form-control"
      placeholder="{{placeholder}}"
      style="{{inputStyles}}"
      [(ngModel)]="searchValue"
      [typeahead]="autocompleteOptionsObservable"
      [typeaheadOptionField]="autocompleteOptionsLabel"
      [typeaheadAsync]="true"
      [typeaheadMinLength]="2"
      [typeaheadWaitMs]="500"
      [isAnimated]="true"
      [adaptivePosition]="true"
      [typeaheadGroupField]="groupingField"
      (ngModelChange)="onSearchValueChange($event)"
      (keyup.enter)="submitSearch()"
      (typeaheadOnSelect)="onTypeaheadSelect($event)"
    >
    <span class="input-group-text" (click)="submitSearch()" *ngIf="!enableClearBtn || !searchValue">
      <img src="./assets/img/search-icon.png" alt="search">
    </span>
    <div class="input-group-text"  *ngIf="enableClearBtn && searchValue">
      <button
        type="button"
        class="btn-close"
        (click)="searchValue = undefined;submitSearch();"
      >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
  </div>
</div>
