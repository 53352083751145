<section class="setup" style="text-align: center; " *ngIf="!company || loadingCustomer">
  <app-loader [loading]="true"></app-loader>
</section>
<section class="setup" *ngIf="company">
  <div class="logo-wrapper">
    <img alt="current logo" [attr.src]="company.logoUrl || '/assets/img/current.png'" class="logo">
  </div>
  <div class="setup-form default-border">
    <p *ngIf="showCheckEmailMessage" style="padding: 2em; margin-top: 2em;">
      Activate your account by clicking on the activation link sent to your email.<br><br>
      You may close this window.
    </p>
    <app-customer-register
      *ngIf="!showCheckEmailMessage"
      [customer]="customer"
      [companyId]="company.id"
      [product]="'oil'"
      [tankSizeInputDisabled]="false"
      [skipCCEnabled]="true"
      [askEstimatedTankLevel]="true"
      (onRegisterSuccess)="customerRegistered($event)"
    >
    </app-customer-register>
  </div>
</section>
