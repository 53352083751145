import {Component, OnInit} from '@angular/core';
import {UserProvider} from '../../providers/user.provider';
import {AuthProvider} from '../../shared/providers/auth.provider';

import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {CompanyProvider} from '../../providers/company.provider';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [UserProvider, CompanyProvider]
})

export class LoginComponent implements OnInit {
  public form: any = {
    email: '',
    password: ''
  };
  public formErrors: any = {};
  public recoverPassword = false;
  public requestingNewPwd = false;
  public passwordRecoveryLinkSent = false;
  public activating = false;
  public companyId: number;
  public logoUrl: string;
  constructor(
    private userProvider: UserProvider,
    private companyProvider: CompanyProvider,
    public authProvider: AuthProvider,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) {

  }

  submit(event) {
    if (event.keyCode === 13) {
      this.submitForm(event);
    }
  }

  submitForm(event) {
    localStorage.removeItem('JWT');
    this.userProvider.signIn({
      email: this.form.email,
      password: this.form.password,
      companyId: this.companyId,
    })
      .then((response: any) => {
        if (!response.err) {
          this.onSignInSuccess(response.data.token);
        } else {
          this.formErrors = response.err.errors;
        }
      });
  }
  onSignInSuccess (token: string, redirect?: string) {
    this.authProvider.setToken(token);
    this.userProvider.getUserInfo()
      .then((user: any) => {
        if (user.role) {
          this.authProvider.setUser(user);
          const postLoginRedirect = localStorage.getItem('postLoginRedirect');
          if (postLoginRedirect) {
            localStorage.removeItem('postLoginRedirect');
            window.location.href = postLoginRedirect;
          } else {
            if (redirect) {
              return window.location.href = redirect;
            }
            this.router.navigate([user.role + '/dashboard']);
          }
        } else {
          localStorage.clear();
          window.location.pathname = '/login';
        }
      });
  }
  async submitRecovery (event) {
    try {
      this.requestingNewPwd = true;
      await this.userProvider.requestNewPassword({
        email: this.form.email,
        companyId: this.companyId,
        _passwordResetUrl: window.location.origin + '/password-reset',
      });
      this.toastr.success('Password recovery link sent successfully', 'Success!');
      this.passwordRecoveryLinkSent = true;
    } catch (error) {
      this.toastr.error('Could not send password recovery link:' + error.message, 'Error!');
    }
    this.requestingNewPwd = false;
  }

  async ngOnInit() {
    const nonce = this.route.snapshot.queryParams.nonce;
    const redirect = this.route.snapshot.queryParams.redirect;
    if (nonce && redirect) {
      // user arrived on site via pre-authenticated link
      const response: any = await this.userProvider.signInUsingNonce(nonce);
      if (!response.err) {
        this.onSignInSuccess(response.data.token, decodeURIComponent(redirect));
      } else {
        this.toastr.error('Session token has expired', 'Authentication Error');
      }
    }
    this.companyId = this.route.snapshot.params.companyId;
    if (this.companyId) {
      try {
        const company: any = await this.companyProvider.getCompany(this.companyId);
        this.logoUrl = company.logoUrl;
      } catch (error) {
        console.error('Failed to load company data', error.message);
      }
      if (window.location.href.indexOf('/' + this.companyId + '/login') === -1) {
        // all non-matching company url's will be redirected to company login
        this.router.navigateByUrl('/' + this.companyId + '/login');
        return;
      }
    }
    if (!this.logoUrl) {
      this.logoUrl = '/assets/img/current.png';
    }
    if (
      window.location.href.match('/company/activate') ||
      window.location.href.match('/affiliate/activate')
    ) {
      // activate user before allowing login.
      // use timeout to give user some time to read "activating account message"
      this.activating = true;
      setTimeout(async () => {
        try {
          await this.userProvider.confirmEmail(nonce);
          this.toastr.success('Account activated, you may now log in', 'Success!');
        } catch (error) {
          console.error('Failed to confirm user email address:', error.message);
        }
        this.activating = false;
      }, 3000);
    }
  }
}
