<div class="order-amounts-dropdown">
  <app-loader class="size-2" [loading]="loadingAmounts"></app-loader>
  <select
    class="form-control"
    *ngIf="!otherOptionSelected && !loadingAmounts"
    [(ngModel)]="value"
    (change)="onValueSelect($event)"
  >
    <option value="choose">Choose</option>
    <option *ngIf="fillOptionEnabled" value="fill">Fill</option>
    <option
      value="{{option.amount}}"
      *ngFor="let option of orderAmounts"
    >
      {{option.amount}}
    </option>
    <option *ngIf="otherOptionEnabled" value="other">Other</option>
  </select>
  <input
    id="custom-order-amount"
    type="number"
    inputmode="numeric"
    placeholder="Amount Gal"
    title="Enter custom amount of gallons"
    class="form-control"
    #otherAmountInput
    *ngIf="otherOptionSelected"
    [(ngModel)]="value"
    (change)="onValueSelect($event)"
  >
</div>
