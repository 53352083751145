<div class="card-selector">
  <div *ngIf="cards && cards.length && selectedCard !== 'new'">
    <label for="customer-card">Select Payment Method:</label>
    <select
      id="customer-card"
      class="form-control"
      title="Select payment method or add new one"
      [(ngModel)]="selectedCard"
      (change)="onCardChange()"
    >
      <option [ngValue]="card" [selected]="selectedCard && card.id === selectedCard.id" *ngFor="let card of cards">
        {{getCardDescription(card)}}
      </option>
      <option value="new">add new payment method</option>
    </select>
  </div>
  <div *ngIf="(!cards || !cards.length) && selectedCard !== 'new'">
    <button
      type="button"
      class="btn btn-default-dark btn-sm px-4 me-2"
      (click)="selectedCard = 'new'; onCardChange();"
    >
      Add New Payment Method
    </button>
  </div>
</div>
<ng-template #addCardModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add New Payment Method</h4>
    <button
      type="button"
      class="btn-close close pull-right"
      (click)="addCardModalRef?.hide()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>

  <div class="modal-body nested-modal">
    <div>
      <img height="24px" class="d-inline-block float-right" src="assets/img/img-payment.svg" alt="">
      <a href="https://stripe.com/docs/security/stripe" target='_blank'>
        <img height="24px" class="d-inline-block float-right mr-3" src="assets/img/powered_by_stripe.svg" alt="">
      </a>
    </div>
    <div id="card-element" *ngIf="addingNewCardEnabled()">
      <!-- a Stripe Element will be inserted here. -->
    </div>

    <!-- Used to display Element errors -->
    <div id="card-errors" role="alert"></div>
    <ng-container *ngIf="achDiscountEnabled">
      <hr>
      <div style="margin: 0.4em auto;cursor: pointer" (click)="connectPlaid()">
        Or click here to connect your bank account and save {{achDiscount.value | currency}} on your first order
      </div>
    </ng-container>
  </div>

  <div class="modal-footer">
    <div class="w-100 text-end">
      <button
        type="button"
        class="btn btn-cancel btn-sm px-4"
        (click)="addCardModalRef.hide()"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-default-dark no-radius btn-sm px-4 me-2"
        (click)="addCard()"
        [disabled]="addingCard"
      >
        {{addingCard ? 'Saving...' : 'Add Card'}}
      </button>
    </div>
  </div>
</ng-template>
