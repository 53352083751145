import {Component, Input, Output, EventEmitter} from '@angular/core';
@Component({
  selector: 'app-tank-location-dropdown',
  templateUrl: './tank-location-dropdown.component.html',
  styleUrls: ['./tank-location-dropdown.component.scss'],
  providers: [],
})
export class TankLocationDropdownComponent {
  @Input() value: string;
  @Input() editEnabled = false;
  @Input() locationOptionIconHeight = '50px';
  @Output() onSelect = new EventEmitter();
  public locationOptions = {
    front: {label: 'Front', value: 'front', icon: 'icn-houseBottom.svg'},
    right: {label: 'Right', value: 'right', icon: 'icn-houseRight.svg'},
    back: {label: 'Back', value: 'back', icon: 'icn-houseBack.svg'},
    left: {label: 'Left', value: 'left', icon: 'icn-houseLeft.svg'},
  }
  public locationDropdownOpen = false;
  public locationOptionsArray = Object.values(this.locationOptions);
  constructor() {}
  selectValue (value) {
    this.value = value;
    this.onSelect.emit(this.value);
    this.locationDropdownOpen = false;
    console.log('value emitted');
  }
}
