import { Tank } from './tank.interface';
import { GasTank } from './gas-tank.interface';
import { User } from './user.interface';
import { Company } from './company.interface';
import { Card } from './card.interface';
import {Address} from './address.interface';

export interface Customer extends User {
  deliveryCompanyId: number;
  deliveryCompany?: Company;
  autofillEstimateParams: string;
  pendingOrderAmount: number;
  role: 'customer';
  tank?: Tank;
  gasTank?: GasTank;
  cards?: Card[];
  receiptCompany: any;
  customerNotes: string;
  billingAddress?: Address, // same as address, should get rid of it at some point
  // password setup properties, only present when adding new customers
  password?: string;
  passwordConfirm?: string;
  nonce?: string;
}
export const Customer = (fieldValues?): Customer => {
  const customer = User({
    deliveryCompanyId: null,
    autofillEstimateParams: null,
    pendingOrderAmount: 0,
    role: 'customer',
    tank: Tank(),
    gasTank: GasTank(),
    sendSms: true,
  }) as Customer;
  if (fieldValues) {
    Object.assign(customer, fieldValues);
  }
  return customer;
};
