<header class="header">
  <div class="row row-flex align-items-center gx-0">
    <div class="col-auto col-sm-4">
      <img
        src="/assets/img/current.png"
        class="logo img-fluid"
        style="max-height: 5em;"
        alt="current logo"
        [attr.src]="logoUrl"
      >
    </div>

    <div class="col col-sm-8 text-end">
      <h3 *ngIf="user" class="d-none d-lg-block">
        Welcome{{ user && user.firstName ? ", " + user.firstName + "!" : "!" }}
      </h3>

      <a
        routerLink="/logout"
        class="link d-none d-lg-inline mb-2"
        *ngIf="user"
      >
        Log out
      </a>

      <button
        type="button"
        class="btn btn-menu d-lg-none"
        aria-label="Main menu"
        (click)="showSidebar = true"
      >
        <i class="fas fa-bars"></i>
      </button>
    </div>
  </div>
</header>

<sidebar-overlay
  [show]="showSidebar"
  (onClose)="showSidebar = false"
></sidebar-overlay>
