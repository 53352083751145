import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthProvider } from '../shared/providers/auth.provider';
@Injectable()
export class AdminCanActivateGuard implements CanActivate {

  constructor(public authProvider: AuthProvider) {
  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const adminPath = route.url.filter((segment) => {
        return segment.path === 'admin';
      });
      if (adminPath.length) {
        const user = this.authProvider.getUser();
        if (!user || user.role !== 'admin') {
          alert('You are not authorised to visit this page');
          return reject(false);
        }
        resolve(true);
      }
      resolve(true);
    });
  }
}
