export interface Address {
  id?: number;
  userId?: number;
  companyId?: number;
  customerId?: number;
  line1: string;
  line2?: string;
  city: string;
  zip: string;
  state: string;
  googleMaps?: {
    placeId: string;
    location: {
      lat: number;
      lng: number;
    };
    placeIdRetrievedAt: string;
  }
  createdAt?: string;
  updatedAt?: string;
}
export const Address = (fieldValues?): Address => {
  const address: Address = {
    line1: '',
    line2: '',
    city: '',
    zip: '',
    state: '',
  };
  if (fieldValues) {
    Object.assign(address, fieldValues)
  }
  return address;
};
