import {Injectable} from '@angular/core';
import {API} from './api.provider';
import {AffiliateEvent} from '../../interfaces/affiliate-event';
import {Affiliate, AffiliateLink} from '../../interfaces/affiliate.interface';

export interface AffiliateDashboard {
  affiliateLinks: AffiliateLink[];
  events: AffiliateEvent[];
  statistics: {
    linkClicks: number;
    ordersSubmitted: number;
    ordersDelivered: number;
    priceChecks: number;
    userSignups: number;
    companyRewards: number;
    platformRewards: number;
  }
}

@Injectable()
export class AffiliateProvider {
  constructor(
    private api: API
  ) {}
  get(
    {affiliateId, companyId}:
    {affiliateId?: number, companyId?: number}
  ): Promise<Affiliate> | Promise<{_metadata: any, list: Affiliate[]}> {
    if (affiliateId) {
      // get specific affiliate
      return this.api.get(`/affiliate/${affiliateId}`, {}) as Promise<Affiliate>;
    }
    if (companyId) {
      // get all company affiliates
      return this.api.get(`/company/${companyId}/affiliate`, {}) as Promise<{_metadata: any, list: Affiliate[]}>;
    }
    // get all affiliates
    return this.api.get('/affiliate', {}) as Promise<{_metadata: any, list: Affiliate[]}>;
  }
  create(
    {name, phone, email, password, firstName, lastName}:
    {name: string; password?: string; phone?: string; email?: string; firstName: string, lastName: string}) {
    return this.api.post('/affiliate', {name, phone, email, password, firstName, lastName}).then((response: any) => {
      return response.data;
    });
  }
  update({id, name, phone, email}: {id: number; name: string; phone?: string; email?: string; }) {
    return this.api.put(`/affiliate/${id}`, {name, phone, email}).then((response: any) => {
      return response.data;
    });
  }
  /*
  * @param {int} id - affiliate id
  * @Return - all data needed to render dashboard.
  * */
  getDashboard(id?: number): Promise<AffiliateDashboard> {
    // load all dashboard statistics
    return this.api.get(`/affiliate/${id}/dashboard`, {}).then((res) => {
      console.log('result dash', res);
      return res as AffiliateDashboard;
    });
  }
}
