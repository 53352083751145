import {Component, Input} from '@angular/core';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  providers: [],
})
export class LoaderComponent {
  public _loading: boolean;
  @Input() style = '';
  @Input() class = '';
  @Input() center = false;
  @Input() set loading (value: boolean | Promise<any>) {
    if (!value) {
      this._loading = false;
      return ;
    }
    if (typeof value === 'boolean') {
      this._loading = value;
    } else {
      this._loading = true;
      value.then(() => {
        this._loading = false;
      }).catch(() => {
        this._loading = false;
      });
    }
  }
  constructor() {}
}
