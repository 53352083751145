import { Component, OnInit } from '@angular/core';
import {AuthProvider} from '../../shared/providers/auth.provider';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(public authProvider: AuthProvider) { }

  ngOnInit() {
    const user = this.authProvider.getUser();
    const loginUrl = (user && user.role === 'customer') ? `/${user.deliveryCompanyId}/login` : '/login';
    this.authProvider.logout();
    window.location.pathname = loginUrl;
  }
}
