<div class="login">
  <div style="text-align: center">
    <img alt="company logo" *ngIf="logoUrl" [attr.src]="logoUrl" class="logo img-fluid" style="max-height: 9em;">
  </div>
  <app-loader class="size-2" [loading]="!logoUrl"></app-loader>
  <section class="login-wrapper" *ngIf="activating">
    <div style="margin: 1em">Activating your account, please wait!</div>
    <div style="text-align: center; margin: 1em;">
      <app-loader [loading]="activating"></app-loader>
    </div>
  </section>
  <section class="login-wrapper" *ngIf="!recoverPassword && !activating">
    <div class="input-group" [ngClass]="{ 'has-danger': formErrors['email'] }">
      <input
        type="text"
        class="form-control mb-3"
        [(ngModel)]="form.email"
        placeholder="E-mail"
      />
    </div>
    <label *ngIf="formErrors.email">{{ formErrors.email.message }}</label>

    <div class="input-group" [ngClass]="{ 'has-danger': formErrors['password'] }">
      <input
        type="password"
        class="form-control mb-3"
        (keyup)="submit($event)"
        [(ngModel)]="form.password"
        placeholder="Password"
      />
    </div>
    <label *ngIf="formErrors.password">{{ formErrors.password.message }}</label>

    <button
      class="btn btn-submit btn-block w-100 mb-3"
      (click)="submitForm($event)"
      [disabled]="activating"
    >
      Login
    </button>

    <button class="btn btn-link w-100 text-center" (click)="recoverPassword = true">
      Forgot password?
    </button>
  </section>
  <section class="recover-pwd-wrapper" *ngIf="recoverPassword && !passwordRecoveryLinkSent">
    <div class="input-group" [ngClass]="{ 'has-danger': formErrors['email'] }">
      <input
        type="text"
        class="form-control mb-3"
        [(ngModel)]="form.email"
        placeholder="Recovery E-mail"
      />
    </div>
    <label *ngIf="formErrors.email">{{ formErrors.email.message }}</label>
    <button
      class="btn btn-submit btn-block w-100 mb-3"
      (click)="submitRecovery($event)"
      [disabled]="requestingNewPwd"
    >
      {{requestingNewPwd ? 'Please wait...' : 'Send Recovery Code'}}
    </button>

    <button
      class="btn btn-link w-100 text-center"
      (click)="recoverPassword = false"
      [disabled]="requestingNewPwd"
    >
      Log In
    </button>
  </section>
  <section class="recover-pwd-wrapper" *ngIf="passwordRecoveryLinkSent">
    <p>
      Password recovery link sent to your email.
    </p>
  </section>
</div>
