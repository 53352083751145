<app-data-table
  [hidden]="loadingProductOrders"
  [headers]="productOrderTableHeaders"
  [rows]="productOrderTableRows"
  [tableClasses]="tableClasses"
  [pagination]="pagination"
  [expandedTemplate]="expandedTemplate"
  [expandedColspan]="7"
  (onPageChange)="onPageChange($event)"
  (onCellClick)="cellClicked($event)"
  class="mt-4"
  minWidth="600"
></app-data-table>
<ng-template #productOrderActionColumn let-value="value">
  <button
    title="Click to mark this order as completed"
    type="button"
    class="btn btn-accept w-100 mb-1"
    *ngIf="value.status === 'payment_succeeded'"
    [disabled]="value._saving"
    (click)="setProductOrderStatus(value, 'completed')">
    {{value._saving ? 'saving' : 'COMPLETE'}}
  </button>
  <button
    title="Click to cancel the order and refund your customer if any charges have been made"
    type="button"
    class="btn btn-reject w-100"
    *ngIf="value.status !== 'canceled'"
    [disabled]="value._saving"
    (click)="setProductOrderStatus(value, 'canceled')">
    {{
    value._saving ? 'loading' :
      value.status === 'completed' || value.status === 'payment_succeeded' ?
        'REFUND' :
        'CANCEL'
    }}
  </button>
</ng-template>
<ng-template #productOrderNotesColumn let-value="value">
  <ng-container *ngIf="value._editingNotes">
    <textarea
      class="form-control"
      title="Enter notes about this order"
      placeholder="enter your notes"
      style="min-height: 6em;"
      [(ngModel)]="value.notes"
    >
  </textarea>
    <button
      class="btn btn-default-dark btn-sm w-100 mt-1"
      [disabled]="value._saving"
      (click)="setProductOrderNotes(value)"
    >save notes
    </button>
  </ng-container>
  <div *ngIf="!value._editingNotes" style="max-width: 12em; white-space: break-spaces;">
    {{value.notes}}
    <button class="btn btn-default-dark btn-sm xs" (click)="value._editingNotes = true;">
      {{value.notes ? 'edit' : 'add'}}
    </button>
  </div>
</ng-template>
<ng-template #expandedTemplate let-value="value">
  <div class="order-details">
    <div class="row">
      <div class="col-6">Transaction Total:</div>
      <div class="col-6">
        {{ value.transactionTotal | currency }}
      </div>
    </div>
    <div class="row" *ngIf="value.financeFee?.fee">
      <div class="col-6">Finance Fee Charged to You:</div>
      <div class="col-6">
        {{ value.financeFee.fee }}% | {{ value.financeFee.total | currency }}
      </div>
    </div>
    <div class="row" *ngIf="value.transactionFee?.total">
      <div class="col-6">Transaction Fee Charged to You:</div>
      <div class="col-6">{{ value.transactionFee.total | currency}}</div>
    </div>
    <div class="row">
      <div class="col-6">Total Net Transaction Value to You:</div>
      <div class="col-6">{{ value.transactionNet | currency}}</div>
    </div>
    <ng-container *ngIf="!value.payoutSummary && value.transactions?.length">
      <div class="row" *ngFor="let transaction of value.transactions">
        <ng-container *ngIf="transaction?.status">
          <div class="col-4">Transfer Status:</div>
          <div class="col-8" style="white-space: break-spaces">
          <span *ngIf="transaction.status === 'succeeded'">
            <ng-container *ngIf="transaction.fundsAvailable">
              {{transaction.netAmount | currency}} deposited to your Stripe account on {{transaction.availableAt}}
            </ng-container>
            <ng-container *ngIf="!transaction.fundsAvailable && transaction.availableAt">
              {{transaction.netAmount | currency}} expected to be available on {{transaction.availableAt}}
            </ng-container>
          </span>
            <span *ngIf="transaction.status === 'refunded'">
            {{transaction.amountRefunded | currency}} refunded to the customer.
          </span>
            <span *ngIf="transaction.status === 'partially_refunded'">
            {{transaction.amountRefunded | currency}} out of {{ transaction.amountCaptured | currency }} refunded to the customer.
          </span>
            <span *ngIf="transaction.status === 'partially_captured'">
            {{transaction.amountCaptured | currency}} out of {{ transaction.amount | currency }} captured.
          </span>
            <span *ngIf="!['refunded', 'succeeded', 'partially_refunded', 'partially_captured'].includes(transaction.status)">
            {{transaction.amount | currency}} {{transaction.status | capitalizefirst}}.
          </span>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <div class="row" *ngIf="value.payoutSummary">
      <div class="col-4">Payout Status:</div>
      <div class="col-8" style="white-space: break-spaces">
        {{value.payoutSummary}}
      </div>
    </div>
  </div>
</ng-template>
