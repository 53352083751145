import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable, Observer, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {API} from '../../providers/api.provider';
interface AutocompleteItem {
  id: number;
  label: string,
  type: 'user' | 'customer' | 'company' | 'order',
  value: any,
  group?: string,
}
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Input() clearOnSelect = false; // if value is selected, it gets emitted and input is cleared, so new search can be started
  @Input() placeholder = 'Search...';
  @Input() searchTypes = ['order'];
  @Input() fullWidth = false;
  @Input() inputStyles = '';
  @Input() autocompleteOptionsLabel = 'label';
  @Input() groupingField;
  @Input() customerId: number;
  @Input() enableClearBtn = false; // if true, then value is not automatically cleared on enter/submit
  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() onClear: EventEmitter<string> = new EventEmitter<string>();
  @Output() onValueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onAutocompleteSelect: EventEmitter<AutocompleteItem> = new EventEmitter<AutocompleteItem>();
  public autocompleteOptionsObservable = new Observable((observer: Observer<string | undefined>) => {
    observer.next(this.searchValue);
  }).pipe(
    switchMap((keyword: string) => {
      if (keyword) {
        return this.api.get('/search', {
          keyword: encodeURIComponent(keyword),
          types: this.searchTypes,
          customerId: this.customerId
        }).then((rows: any[]) => {
          rows.forEach((row) => {
            if (row.type === 'user') {
              row.group = 'Admins';
            } else if (row.type === 'company') {
              row.group = 'Companies';
            } else if (row.type === 'customer') {
              row.group = 'Customers';
            } else if (row.type === 'order') {
              row.group = 'Orders';
            }
          });
          return rows;
        });
      }
      return of([]);
    })
  );
  public searchValue = '';

  constructor(private api: API) {}

  public submitSearch(): void {
    if (!this.searchValue) {
      this.onClear.emit();
    } else {
      this.onSearch.emit(this.searchValue);
    }
  }

  public onTypeaheadSelect(event: {header: boolean, item: AutocompleteItem, value: string}) {
    this.onAutocompleteSelect.emit(event.item);
    if (this.clearOnSelect) {
      this.searchValue = '';
    }
  }

  public async onSearchValueChange (value) {
    this.onValueChange.emit(value);
  }
}
