import {Component, OnInit} from '@angular/core';
import {AuthProvider} from '../../providers/auth.provider';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
  public showSidebar = false;
  public user;

  constructor(public authProvider: AuthProvider) {
  }

  public ngOnInit(): void {
    this.user = this.authProvider.getUser();
  }

  public get logoUrl() {
    if (
      this.user && this.user.role !== 'company' && this.user.role !== 'admin' &&
      this.user.company && this.user.company.logoUrl
    ) {
      return this.user.company.logoUrl;
    }
    return '/assets/img/current.png';
  }
}
