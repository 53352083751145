<div class="overlay" [class.show]="show" (click)="closeSidebar()"></div>
<div class="sidebar" [class.show]="show">
  <div class="logo">
    <img src="/assets/img/current.png" class="img-fluid" alt="company logo" />
  </div>

  <nav class="nav flex-column">
    <a
      class="nav-link"
      *ngFor="let navLink of sideMenuLinks"
      routerLink="{{ navLink.href }}"
      (click)="closeSidebar()"
      [attr.id]="navLink.id"
    >
      <div class="img-wrapper">
        <img
          *ngIf="navLink.icon"
          class="img-fluid"
          src="assets/img/{{ navLink.icon }}"
          alt="{{ navLink.label }}"
        />
      </div>
      {{ navLink.label }}
    </a>
    <a routerLink="/logout" class="nav-link regular mt-5">Log out</a>
  </nav>
</div>
