<ng-container *ngIf="!center">
  <img *ngIf="_loading"
       src="/assets/img/ajax-loader-default.png"
       title="...loading"
       style="{{style}}"
       alt="loading"
       class="loader {{class}}"
  >
</ng-container>
<ng-container *ngIf="center">
  <div style="text-align: center">
    <img *ngIf="_loading"
         src="/assets/img/ajax-loader-default.png"
         title="...loading"
         style="{{style}}"
         alt="loading"
         class="loader {{class}}"
    >
  </div>
</ng-container>
