<section class="pricing-page">
  <div
    id="company-logo"
    [style.background-image]="'url(/assets/img/fuel-hero-logo.png)'"
  ></div>
  <div id="content">
    <h2 class="mt-4 mb-4">Save Your Heating Season</h2>
    <div style="text-align: center; position: relative">
      <app-loader [loading]="loading"></app-loader>
    </div>
    <section id="ask-zip" *ngIf="!notReadyToOrder && !subscribeToEvent">
      <div class="mt-2 mb-2">
        Find the lowest price on heating oil in seconds. 100% Free.
      </div>
      <input
        type="text"
        inputmode="numeric"
        class="form-control"
        style="max-width: 9em; margin: 1em auto;text-align: center;"
        placeholder="enter zip"
        [(ngModel)]="zip"
      >
      <button
        class="btn btn-default-dark no-radius"
        style="width: 6em;"
        (click)="onZipChange()"
        [disabled]="!zipIsValid"
      >
        Get Offer
      </button>
    </section>
    <section id="ask-reason" *ngIf="notReadyToOrder && !subscribeToEvent">
      Please let us know why chose not to order at this moment so we could notify you when things change.

      <button
        class="btn btn-default-dark no-radius w-100 mt-4"
        title="Currently offered price per gallon was too high."
        (click)="subscribeToEvent = 'price_drops_below'"
      >
        Price is too high
      </button>
      <button
        class="btn btn-default-dark no-radius w-100"
        title="Weather is warm enough that I don't need heating at this moment"
        (click)="subscribeToEvent = 'heating_season_begins'"
      >
        It's not heating season yet
      </button>
      <button
        class="btn btn-default-dark no-radius w-100"
        title="You have enough heating oil stored in your tank to get by for a while"
        (click)="subscribeToEvent = 'churn'"
      >
        I already have enough heating oil
      </button>
      <button
        class="btn btn-default-dark no-radius w-100"
        title="I can't order for some other reason, i.e I don't have the funds to order at this moment."
        (click)="subscribeToEvent = 'other'"
      >
        It's something else
      </button>
    </section>
    <section id="all-done" *ngIf="goodbyeMsg">
      <div class="text-center section-wrapper" style="margin: 1em;">
        {{goodbyeMsg}}
      </div>
    </section>
    <section id="ask-to-subscribe" *ngIf="!goodbyeMsg && ((notReadyToOrder && subscribeToEvent) || subscribeToEvent === 'area_serviced')">
      <h5 *ngIf="subscribeToEvent !== 'area_serviced'">We will notify you when:</h5>
      <div class="text-center section-wrapper" *ngIf="subscribeToEvent === 'area_serviced'">
        Sorry, but Fuel Hero hasn't reached
        your neighborhood yet!<br>
        Don't stress though...I'm sure we're coming soon.
        Leave us your email, and we'll let you know as
        soon as you can start saving!
      </div>
      <div class="row" *ngIf="subscribeToEvent === 'price_drops_below'">
        <div class="col-6 col-sm-8">
          <label for="notify-price">Price drops below</label>
        </div>
        <div class="col-6 col-sm-4">
          <input
            id="notify-price"
            type="text"
            inputmode="numeric"
            title="Enter price per gallon at which you would want to order"
            style="width: 4em;display: inline-block"
            class="form-control"
            placeholder="$/gal"
            [(ngModel)]="notifyPrice"
          >/gal
        </div>
      </div>
      <div class="row" *ngIf="subscribeToEvent === 'heating_season_begins'">
        <div class="col-6 col-sm-8">
          <label for="notification-date">
            Heating season begins
          </label>
        </div>
        <div class="col-6 col-sm-4">
          <input
            type="text"
            id="notification-date"
            class="form-control calendar"
            placeholder="pick a date"
            bsDatepicker
            title="Pick a date when you would like to be reminded of purchasing heating oil"
            [(ngModel)]="notificationDate"
            [bsConfig]="bsConfig"
            [minDate]="today"
            (bsValueChange)="onDateChange($event)"
          >
        </div>
      </div>
      <ng-container *ngIf="subscribeToEvent === 'churn'">
        <div class="row">
          <div class="col-12 col-sm-8">
            <label for="current-amount">
              You are about to run out of oil
            </label>
            <div style="font-size: 0.9em; margin: 0.6em;">
              Tell us how many gallons of oil do you have and our predictive algorithm will estimate when you will run out of oil and notifies you before it happens.
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <input
              id="current-amount"
              type="text"
              class="form-control"
              inputmode="numeric"
              placeholder="I have x gallons"
              title="Enter how much oil do you have in your tank now and we will estimate when you will be in danger of running out of oil"
              [(ngModel)]="currentAmount"
            >
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-6 col-sm-8">
            <label for="k-factor">
              K-Factor (optional)
            </label>
          </div>
          <div class="col-6 col-sm-4">
            <input
              id="k-factor"
              type="text"
              class="form-control"
              inputmode="numeric"
              placeholder="k factor"
              title="(optional) Your k factor"
              [(ngModel)]="kFactor"
            >
          </div>
        </div>
      </ng-container>
      <div class="row" *ngIf="subscribeToEvent === 'other'">
        <div class="col-6 col-sm-8">
          <label for="notification-date">
            Its more convenient for you
          </label>
        </div>
        <div class="col-6 col-sm-4">
          <input
            type="text"
            id="notification-date"
            class="form-control calendar"
            placeholder="pick a date"
            bsDatepicker
            title="Pick a date when you would like to be reminded of purchasing heating oil"
            [(ngModel)]="notificationDate"
            [bsConfig]="bsConfig"
            [minDate]="today"
            (bsValueChange)="onDateChange($event)"
          >
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <input
            id="customer-email"
            class="form-control"
            type="email"
            placeholder="your email"
            [(ngModel)]="customerEmail"
          >
        </div>
        <div class="col-5">
          <button
            class="btn btn-default-dark no-radius w-100"
            title="Click to set notification"
            (click)="setNotification()"
            [disabled]="!emailIsValid"
          >
            Notify Me
          </button>
        </div>
      </div>
    </section>
    <button
      class="btn btn-default-dark no-radius w-100"
      (click)="gotoOrder()"
      *ngIf="showNoOrderReasons"
    >
      Order
    </button>
  </div>
</section>



<ng-template #orderModalTemplate>
  <div class="modal-header">
    <div class="col-10">
      <div
        class="logo"
        *ngIf="bestPrice.companyLogoUrl"
        [style.background-image]="'url(' + bestPrice.companyLogoUrl + ')'">
      </div>
      <h3 *ngIf="!bestPrice.companyLogoUrl">Great News!</h3>
    </div>
    <div class="col-2" style="margin-top: -1em; text-align: right;">
      <button
        type="button"
        class="btn-close close pull-right"
        (click)="orderModalRef?.hide()"
      >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
  </div>

  <div class="modal-body">
    Great news! <br><br>
    <span class="highlight">{{bestPrice.companyName}}</span> has made the best offer in your area: <span class="highlight">{{bestPrice.oil.pricePerGallon | currency }}/gal</span>
    <br>
    <div *ngIf="deliveryDate">
      Free delivery by: <span class="highlight">{{deliveryDate}}</span>
    </div>
    <div class="expedited-delivery" *ngIf="deliveryOptions && deliveryOptions.length">
      <span class="highlight">Expedited delivery available</span>
      <div class="row" *ngFor="let option of deliveryOptions">
        <div class="col-9 label">{{ option.label }}</div>
        <div class="col-3 value">{{ option.value | currency }}</div>
      </div>
    </div>
    <br>
    <br>
    Are you ready to order?
  </div>

  <div class="modal-footer">
    <div class="w-100 text-end">
      <button
        type="button"
        class="btn btn-cancel btn-sm px-4"
        style="margin-right: 2em;"
        (click)="notReadyToOrder = true; orderModalRef.hide()"
      >
        No
      </button>
      <button
        type="button"
        class="btn btn-default-dark no-radius btn-sm px-4 me-2"
        (click)="gotoOrder()"
      >
        Yes
      </button>
    </div>
  </div>
</ng-template>
<ng-template #externalOrderModalTemplate>
  <div class="modal-header">
    <div class="col-10">
      <h3>Great News!</h3>
    </div>
    <div class="col-2" style="margin-top: -1em; text-align: right;">
      <button
        type="button"
        class="btn-close close pull-right"
        (click)="externalModalRef?.hide()"
      >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" *ngIf="savingExternalInfo || externalInfoSaved" style="margin-top: 1em;">
    <app-loader class="size-2" [loading]="savingExternalInfo"></app-loader>
    <p *ngIf="externalInfoSaved">
      Your low price is so close!<br><br>
      We’ll pass along your information to a few good companies and they’ll reach out to set things up!<br>
    </p>
  </div>
  <div class="modal-body" *ngIf="!savingExternalInfo && !externalInfoSaved">
    <span *ngIf="lowestPrice">We found you a price as low as ${{lowestPrice}}/gal.</span>
    <span *ngIf="!lowestPrice">We’ve found you a great low price!</span>
    <br>
    Enter your information below to unlock your access!
    <br>
    <section class="customer-info">
      <div class="row">
        <div class="col-4">
          <label for="first-name">First Name:</label>
        </div>
        <div class="col-8">
          <input
            id="first-name"
            class="form-control"
            type="text"
            placeholder="first name"
            [(ngModel)]="externalOrder.firstName"
          >
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <label for="first-name">Last Name:</label>
        </div>
        <div class="col-8">
          <input
            id="last-name"
            class="form-control"
            type="text"
            placeholder="last name"
            [(ngModel)]="externalOrder.lastName"
          >
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <label for="phone">Phone:</label>
        </div>
        <div class="col-8">
          <input
            id="phone"
            class="form-control"
            type="text"
            placeholder="phone number"
            title="optional if email is provided"
            [(ngModel)]="externalOrder.phone"
          >
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <label for="phone">Email:</label>
        </div>
        <div class="col-8">
          <input
            id="email"
            class="form-control"
            type="text"
            placeholder="(optional) email"
            title="optional if phone number is provided"
            [(ngModel)]="externalOrder.email"
          >
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <label for="amount">Amount(gal):</label>
        </div>
        <div class="col-8">
          <select
            id="amount"
            class="form-control"
            style="width: 7em;"
            title="Select the amount you would like to order. You can change this later."
            [(ngModel)]="externalOrder.amount"
          >
            <option value="">Choose</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="150">150</option>
            <option value="200">200</option>
            <option value="fill">Fill tank</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-10">
          <label for="permission">
            By submitting your information you are giving Fuel Hero and its affiliates permission to contact you regarding fuel purchases.
          </label>
        </div>
        <div class="col-2">
          <input
            id="permission"
            style="margin-top: 0.8em;width: 2em;height: 2em;"
            type="checkbox"
            [(ngModel)]="permission"
          >
        </div>
      </div>
    </section>
  </div>

  <div class="modal-footer" *ngIf="!externalInfoSaved">
    <div class="w-100 text-end">
      <button
        type="button"
        class="btn btn-cancel btn-sm px-4"
        style="margin-right: 2em;"
        (click)="externalModalRef.hide()"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-default-dark no-radius btn-sm px-4 me-2"
        (click)="submitExternalOrder()"
        [disabled]="!validateExternalOrder() || !permission"
      >
        Submit
      </button>
    </div>
  </div>
</ng-template>
