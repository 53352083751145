import {Injectable} from '@angular/core';
import {API} from './api.provider';
import {PriceSummary} from '../../interfaces/price-summary.interface';

type Product = 'oil' | 'propane';

@Injectable()
export class PriceProvider {

  constructor(private api: API) {
  }

  /*
  * @param {Object} opts
  * */
  getCustomerPrices(opts?: {amount?: number, zip?: string, companyId?: number, serviceCompanyId?: number}) {
    return this.api.get('/price/customer', opts).then((data: PriceSummary) => {
      if (typeof data === 'object') {
        Object.keys(data).forEach((field) => {
          if (data[field] === '') {
            delete data[field];
            return ;
          }
          data[field] = parseFloat(data[field]);
        });
      }
      return data;
    });
  }
  getPricingDetails(opts?: {
    product: Product,
    amount: number,
    companyId: number,
    sameDayDelivery?: boolean,
    nextDayDelivery?: boolean,
    pricePerGallon?: number,
    additionalFees?: number,
    zoneId?: any,
    zip?: any,
  }) {
    return this.api.get('/price', opts).then((data: PriceSummary) => {
      return data;
    });
  }
  getAverageZipPricing(zip: string) {
    return this.api.get('/price/avg', {zip}).then((data: any) => {
      return data;
    });
  }
  getExternalPricing(zip: string) {
    return this.api.get('/price/external', {zip}).then((data: any) => {
      return data;
    });
  }
}
