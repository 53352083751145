import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AffiliateCanActivateGuard } from '../../guards/affiliate-can-activate.guard';

import { AffiliateSetupComponent } from '../pages/affiliate-setup/affiliate-setup.component'
import { AffiliateDashboardComponent } from '../pages/dashboard/affiliate-dashboard.component';
import {LoginComponent} from '../../pages/login/login.component';

const routes: Routes = [
  {
    path: 'setup',
    component: AffiliateSetupComponent,
    canActivate: [],
  },
  {
    path: 'activate',
    component: LoginComponent,
    canActivate: [],
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: AffiliateDashboardComponent,
    canActivate: [AffiliateCanActivateGuard],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AffiliateRoutingModule {}
