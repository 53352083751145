<section class="setup" style="margin-top: 1em">
  <div class="logo-wrapper">
    <img alt="current logo" src="/assets/img/current.png" class="logo"/>
  </div>
  <div class="setup-form default-border">
    <h2 style="text-align: center; margin: 1em">Affiliate Account Setup</h2>
    <br/>

    <div>
      <!--
        Collect basic company info > go to stripe where profile is set up > return to set users pwd
      -->
      <label>Company Information</label>
      <div class="row">
        <div class="col-6 label">
          <label for="company-name">Name</label>
        </div>
        <div class="col-6">
          <input
            id="company-name"
            placeholder="company name"
            [class.error]="errors.name"
            class="form-control"
            type="text"
            [(ngModel)]="affiliate.name"
          />
          <span *ngIf="errors.name" class="error">{{
            errors.name.message
            }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-6 label">
          <label for="company-phone">Phone</label>
        </div>
        <div class="col-6">
          <input
            id="company-phone"
            placeholder="company phone"
            [class.error]="errors.phone"
            class="form-control"
            type="text"
            [(ngModel)]="affiliate.phone"
          />
          <span *ngIf="errors.phone" class="error">{{errors.phone.message}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-6 label">
          <label for="company-email">Email</label>
        </div>
        <div class="col-6">
          <input
            id="company-email"
            placeholder="company email"
            [class.error]="errors.email"
            class="form-control"
            type="email"
            [(ngModel)]="affiliate.email"
          />
          <span *ngIf="errors.email" class="error">{{errors.email.message}}</span>
        </div>
      </div>

      <label>Login Account Information</label>
      <div class="row">
        <div class="col-6 label">
          <label for="user-firstName">First Name</label>
        </div>
        <div class="col-6">
          <input
            id="user-firstName"
            placeholder="first name"
            [class.error]="errors.firstName"
            class="form-control"
            type="text"
            [(ngModel)]="affiliate.user.firstName"
          />
          <span *ngIf="errors['user,firstName']" class="error">{{errors["user,firstName"].message}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-6 label">
          <label for="user-lastName">Last Name</label>
        </div>
        <div class="col-6">
          <input
            id="user-lastName"
            placeholder="last name"
            [class.error]="errors.lastName"
            class="form-control"
            type="text"
            [(ngModel)]="affiliate.user.lastName"
          />
          <span *ngIf="errors['user,lastName']" class="error">{{
            errors["user,lastName"].message
            }}</span>
        </div>
      </div>

      <div class="row">
        <div class="col-6 label">
          <label for="user-pwd">Password</label>
        </div>
        <div class="col-6">
          <input
            id="user-pwd"
            placeholder="enter password"
            [class.error]="errors['password']"
            class="form-control"
            type="password"
            [(ngModel)]="affiliate.user.password"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6 label">
          <label for="confirm-pwd">Confirm Password</label>
        </div>
        <div class="col-6">
          <input
            id="confirm-pwd"
            placeholder="confirm password"
            [class.error]="errors['password']"
            class="form-control"
            type="password"
            [(ngModel)]="confirmPassword"
          />
          <span *ngIf="errors['password']" class="error">{{
            errors["password"].message
            }}</span>
        </div>
      </div>
      <br/>
      <div class="row" style="margin-top: 1.5em; text-align: center">
        <div class="col-12">
          <button
            [disabled]="loading || (affiliate && affiliate.id)"
            (click)="saveAffiliate()"
            style="margin: 0.4em 1em"
            type="button"
            class="btn btn-primary w-100"
          >
            {{ !loading ? "Next" : "Please wait..." }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
