import { Injectable } from '@angular/core';
import { API } from '../shared/providers/api.provider';
/*
* This class provides access to "Tank Tracker" services and functions.
* */
@Injectable()
export class TankTrackerProvider {
  constructor(
    private api: API
  ) {}
  getLoginToken({companyId}) {
    // returns jwt from tank tracker
    return this.api.get(`/company/${companyId}/tank-tracker/login`, {}).then((response: any) => {
      return response.token;
    });
  }
  getUrlForPath({companyId, path}) {
    // returns authenticated url for requested path on tanktracker
    return this.api.get(`/company/${companyId}/tank-tracker/url/${path}`, {}).then((response: any) => {
      return response.url;
    });
  }
  getSubscriptionFee({companyId}) {
    // returns jwt from tank tracker
    return this.api.get(`/company/${companyId}/tank-tracker/fee`, {}).then((response: any) => {
      console.log('response', response);
      return response.amount ? parseFloat(response.amount) : 0;
    });
  }
  getCustomer({companyId, customerId}) {
    // returns customer info from tank tracker
    return this.api.get(`/company/${companyId}/tank-tracker/customer/${customerId}`, {}, {disableToastr: true});
  }
  createCustomer({companyId, customerId}) {
    // creates or updates the customer on tank tracker
    return this.api.post(`/company/${companyId}/tank-tracker/customer/${customerId}`, {}).then((response: any) => {
      return response.data;
    });
  }
  syncCustomer({companyId, customerId}) {
    // creates or updates the customer on tank tracker
    return this.api.put(`/company/${companyId}/tank-tracker/customer/${customerId}`, {});
  }
  activateTankTracker ({companyId}) {
    return this.api.post(`/company/${companyId}/tank-tracker/activate`, {});
  }
  getTankTrackerSubscription ({companyId}) {
    return this.api.get(`/company/${companyId}/tank-tracker/subscription`, {});
  }
  getStartInspectionUrl ({companyId, customerId}) {
    return this.api.get(`/company/${companyId}/tank-tracker/customer/${customerId}/inspection/start-url`).then((res: any) => {
      return res.url;
    });
  }
  getInspectionUrl ({companyId, customerId, tankTrackerInspectionId}) {
    return this.api.get(`/company/${companyId}/tank-tracker/customer/${customerId}/inspection/${tankTrackerInspectionId}/url`).then((res: any) => {
      return res.url;
    });
  }
  getInspectionByCurrentInspectionId ({companyId, customerId, currentInspectionId}) {
    return this.api.get(`/company/${companyId}/tank-tracker/customer/${customerId}/inspection/${currentInspectionId}`,
      {},
      {disableToastr: true}
    ).then((res: any) => {
      return res.data;
    });
  }
}
