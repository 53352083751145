import {AfterViewInit, Component, OnInit} from '@angular/core';
import {UserProvider} from 'app/providers/user.provider';
import {BsModalService} from 'ngx-bootstrap/modal';
import {Customer} from '../../../interfaces/customer.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {Company} from '../../../interfaces/company.interface';
import {CompanyProvider} from '../../../providers/company.provider';
import {ToastrService} from 'ngx-toastr';
import {AuthProvider} from '../../../shared/providers/auth.provider';
import {Address} from '../../../interfaces/address.interface';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [UserProvider, BsModalService, CompanyProvider, AuthProvider],
})
export class RegisterComponent implements OnInit, AfterViewInit {
  public customer: Customer = Customer();
  public company: Company;
  public nonce: string;
  public loadingCustomer = false;
  public showCheckEmailMessage = false;
  constructor(
    private userProvider: UserProvider,
    private companyProvider: CompanyProvider,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private authProvider: AuthProvider,
  ) {
    this.customer.tank.estimatedOilAmount = undefined;
  }

  public async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe((params: any) => {
      this.nonce = params.nonce;
      if (this.nonce) {
        this.signIn(this.nonce);
      }
    });
    this.route.paramMap.subscribe(async (params) => {
      const companyId = parseInt(params.get('companyId'), 10);
      if (!companyId) {
        this.toastr.error('Error: Could not determine oil company', 'Invalid Registration URL');
        return;
      }
      this.company = await this.companyProvider.getCompany(companyId) as Company;
    });
  }

  public async signIn(nonce) {
    localStorage.removeItem('JWT');
    if (!nonce) {
      // new user
      return
    }
    this.loadingCustomer = true;
    try {
      const response: any = await this.userProvider.signInUsingNonce(nonce);
      if (!response.err) {
        this.authProvider.setToken(response.data.token);
        this.customer = (await this.userProvider.getUserInfo()) as Customer;
        // store nonce, so user could set password
        this.customer.nonce = nonce;
        if (!this.customer.address) {
          this.customer.address = Address();
        }
        await this.userProvider.getUserCards(this.customer.id);
        this.authProvider.setUser(this.customer);
      } else {
        this.toastr.error('Error: Session token may have expired');
      }
    } catch (error) {
      this.toastr.error('Error: Could not verify session token');
    }
    this.loadingCustomer = false;
  }

  public ngAfterViewInit(): void {

  }

  public customerRegistered(evt) {
    if (!this.nonce) {
      // new user registration complete.
      // user still needs to activate( check his email )
      this.showCheckEmailMessage = true;
    } else {
      this.router.navigateByUrl('/customer/dashboard');
    }
  }
}
