import { Component, OnInit, Injector, Injectable } from "@angular/core";
import { CompanyProvider } from "../../../providers/company.provider";
import { Customer } from "../../../interfaces/customer.interface";
import { OrderProvider } from '../../providers/order.provider';
import { PriceProvider } from '../../providers/price.provider';
import { ConfigProvider } from "../../../providers/config.provider";
import { ToastrService } from "ngx-toastr";
import { PriceSummary } from "../../../interfaces/price-summary.interface";
import { PriceConfig } from "../../../interfaces/price-config.interface";
import { Config } from '../../../interfaces/config.interface';

import moment from "moment";
import {User} from '../../../interfaces/user.interface';
import {Product} from '../../../interfaces/product.interface';
import {ActivatedRoute} from '@angular/router';
import {Card} from '../../../interfaces/card.interface';
import {UserProvider} from '../../../providers/user.provider';
import {Company} from '../../../interfaces/company.interface';
export type FuelProductName = 'oil' | 'propane' | 'offsite_diesel';
export interface FuelProduct {
  name: FuelProductName;
  label: string;
  enabled?: boolean;
  priceConfigKey: string;
  pricing?: PriceConfig; // will store pricing info from db once loaded
}
@Component({
  template: ''
})
export class OrderUtilsComponent implements OnInit {
  /*
  * Shared functionality between Company->order, Customer->new order and Customer->dashboard->order
  * */
  public deliveryOptions: Array<Product>;
  // tuneup, tankmonitoring, autofill etc
  public upsellProducts: Array<Product>;
  public deliveryDates: { spot?: string; pool: string; date?: string } = { pool: undefined };
  // amount user is charged for when processing "fill" orders
  public fillOrderAmount = 150;
  // customer data will be loaded later, either during login or provided by userProvider
  public customer: Customer = Customer();
  // user = company/current admin if order is being submitted by them, on behalf of customer.
  public user: User;
  public company: Company;
  // companyId is provided via route params or userProvider->customer->company->id
  public companyId: number;
  public order: {
    product: FuelProductName;
    orderedAmountType: 'gallons' | 'fill';
    orderedAmount: number;
    priceType: 'pool';
    sameDayDelivery: boolean;
    nextDayDelivery: boolean;
    /* Optional Admin/Company fields */
    pricePerGallon?: number,
    deliveryNotes?: string,
    deliveryDate?: string,
    cardId?: string,
  } = {
    product: 'oil',
    orderedAmountType: undefined,
    orderedAmount: undefined,
    priceType: 'pool',
    sameDayDelivery: false,
    nextDayDelivery: false,
  };
  // deliveryZip is not required for submitting the order, but
  // it is required for getting delivery date info
  public deliveryZip: string;
  public submittingOrder = false;
  // ACH discount config object where value is discount in dollars.
  // Having value > 0 doesnt mean that customer is eligible for discount.
  public achDiscount: {
    name: string;
    text: string;
    value: any;
    type: 'money';
  };
  /*
  * Holds price info for currently selected amount + additional options
  * */
  public priceSummary: PriceSummary = {
    name: '',
    text: '',
    sum: '0',
    pricePerGallon: '0',
  };
  public checkoutNotesConfig: Config;
  public fuelProducts: FuelProduct[] = [
    {
      name: 'oil',
      label: 'Heating Oil',
      priceConfigKey: 'companyOilPricing',
    }, {
      name: 'propane',
      label: 'Propane',
      priceConfigKey: 'companyPropanePricing',
    }, {
      name: 'offsite_diesel',
      label: 'Offsite Diesel',
      priceConfigKey: 'companyOffsiteDieselPricing',
    }
  ];
  public selectedFuelProduct: FuelProduct | undefined;
  protected orderProvider: OrderProvider;
  protected companyProvider: CompanyProvider;
  protected configProvider: ConfigProvider;
  protected userProvider: UserProvider;
  protected priceProvider: PriceProvider;
  protected toastr: ToastrService;
  protected route: ActivatedRoute;
  constructor(private injectorObj: Injector) {
    this.orderProvider = injectorObj.get(OrderProvider);
    this.companyProvider = injectorObj.get(CompanyProvider);
    this.configProvider = injectorObj.get(ConfigProvider);
    this.userProvider = injectorObj.get(UserProvider);
    this.toastr = injectorObj.get(ToastrService);
    this.priceProvider = injectorObj.get(PriceProvider);
    this.route = injectorObj.get(ActivatedRoute);
  }
  ngOnInit() {
    window['order'] = this;
    if (!this.companyId && this.route.snapshot.params.companyId) {
      // admin view of customer order passes companyId via route
      this.companyId = this.route.snapshot.params.companyId;
    }
    if (this.route.snapshot.queryParams.af) {
      // affiliate code
      this.userProvider.initAffiliateSession(this.route.snapshot.queryParams.af, this.companyId);
    }
    this.loadACHDiscount();
    this.loadCheckoutNotes();
  }
  /*
   * Returns an object with sameDay/nextDay/twoDay Allowed/Enabled options
   * */
  async getDeliveryOptions ({companyId, zip}: {companyId: number, zip?: any}): Promise<void> {
    if (this.deliveryOptions) {
      return;
    }
    try {
      this.deliveryOptions = await this.companyProvider
        .getDeliveryOptions({companyId, zip, parseList: true}) as any
    } catch (error) {
      console.error('Could not load delivery options', error.message);
    }
  }
  async getAdditionalOptions ({companyId, zip}: {companyId: number, zip?: any}): Promise<void> {
    if (this.upsellProducts) {
      return;
    }
    try {
      this.upsellProducts = await this.companyProvider
        .getAdditionalOptions({companyId, zip, parseList: true}) as any
    } catch (error) {
      console.error('Could not load additional options', error.message);
    }
  }
  public get pricing(): PriceConfig | void {
    const product = this.fuelProducts.filter(p => p.name === this.order.product)[0];
    return product.pricing;
  }
  public get enabledFuelProducts () {
    return this.fuelProducts.filter(p => p.enabled);
  }
  public getFuelProduct (name: FuelProductName) {
    return this.enabledFuelProducts.filter(p => p.name === name)[0];
  }
  public getCompanyId () {
    if (this.user && this.user.company) {
      return this.user.company.id;
    }
    return this.companyId || this.customer.company.id;
  }
  public getActiveRange() {
    const pricing: PriceConfig | void = this.pricing;
    if (!pricing) {
      return false;
    }
    const amount = this.order.orderedAmount;
    if (!amount || !pricing || !pricing.ranges) {
      return false;
    }
    return pricing.ranges.filter(
      (range) =>
        amount >= parseFloat(range.min) && amount <= parseFloat(range.max)
    )[0];
  }
  public formatDate(date, format) {
    if (!date) {
      return 'n/a';
    }
    return moment(date).format(format);
  }
  public isValidNumber(num) {
    return !Number.isNaN(parseFloat(num));
  }
  public isGreaterThan(num, gtValue) {
    return parseFloat(num) > gtValue;
  }
  public getPricePerGallon({range, pricing}) {
    if (!pricing && (!range || !range.pricePerGallon)) {
      return 0;
    }
    return range && this.isGreaterThan(range.pricePerGallon, 0)
      ? parseFloat(range.pricePerGallon)
      : parseFloat((pricing as PriceConfig).defaultPricePerGallon);
  }
  public getEstimatedTotal() {
    const range: any = this.getActiveRange();
    const ppg = this.getPricePerGallon({range, pricing: this.pricing});
    const fee =
      range && this.isGreaterThan(range.flatFee, 0)
        ? parseFloat(range.flatFee)
        : 0;
    const total = this.order.orderedAmount * ppg + fee;
    return Number.isNaN(total) ? '' : total;
  }
  public async loadACHDiscount () {
    const response: {success: boolean, data: any} = await this.configProvider.getOne({name: 'achDiscount'});
    if (response && response.data) {
      this.achDiscount = response.data;
    }
  }
  public async loadCheckoutNotes () {
    try {
      if (!this.companyId) {
        return;
      }
      const response: {success: boolean, data: Config} = await this.configProvider.getOne({
        name: 'checkoutNotes',
        companyId: this.companyId,
      });
      if (response && response.data && response.data.value) {
        this.checkoutNotesConfig = response.data;
        let notes = response.data.value;
        const phoneNr = notes.match(/\+1[0-9]{10}|[0-9]{10}/gi);
        const email = notes.match( /\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+/gi);
        if (phoneNr && phoneNr[0]) {
          notes = notes.replace(phoneNr[0], `<a href="tel:${phoneNr[0]}">${phoneNr[0]}</a>`);
        }
        if (email && email[0]) {
          notes = notes.replace(email[0], `<a href="mailto:${email[0]}">${email[0]}</a>`);
        }
        this.checkoutNotesConfig.value = notes;
      }
    } catch (error) {
      console.error('Failed to load checkout notes:' + error.message);
    }
  }
  public async loadPriceConfig(productName: FuelProductName): Promise<PriceConfig | void> {
    try {
      const product = this.getFuelProduct(productName);
      const response: any = await this.configProvider
        .getOne({
          name: product.priceConfigKey,
          companyId: this.getCompanyId(),
          zip: this.deliveryZip,
        });
      if (response.success && response.data && response.data.value) {
        const pricing = response.data.value as PriceConfig;
        pricing.ranges = pricing.ranges.filter((range) => {
          // throw away ranges without price info or valid min/max range
          return !(
            !this.isValidNumber(range.min) ||
            !this.isValidNumber(range.max) ||
            (!(parseFloat(range.pricePerGallon) > 0) &&
              !(parseFloat(range.flatFee) > 0))
          );
        });
        product.pricing = pricing;
        return pricing;
      } else {
        return ;
      }
    } catch (error) {
      this.toastr.error('Could not load pricing details:' + error.message, 'Error!');
    }
    return ;
  }
  public loadPriceConfigForEnabledProducts () {
    return Promise.all(this.enabledFuelProducts.map((fuelProduct) => {
      return this.loadPriceConfig(fuelProduct.name);
    }));
  }
  public get productNameCapitalized() {
    if (!this.order.product) {
      return '';
    }
    return (
      this.order.product.charAt(0).toUpperCase() + this.order.product.slice(1)
    );
  }
  public isOrderAmountValid() {
    if (this.order.product === 'propane') {
      this.order.orderedAmountType = 'fill';
    }
    if (this.order.orderedAmountType === 'fill') {
      // no amount needed
      return true;
    }
    return this.order.orderedAmount && this.order.orderedAmount >= 0;
  }
  public isZipValid() {
    const zip = this.deliveryZip;
    return zip && zip.toString().length === 5;
  }
  /*
   * This function gets called twice.
   * Once with product/companyId params to get the standard delivery dates.
   * Second time to update dates, taking account user rush delivery options.
   * */
  public getDeliveryDate() {
    if (!this.order.product) {
      return ;
    }
    const payload: any = {
      zip: this.deliveryZip,
      companyId: this.companyId,
      product: this.order.product,
    };
    this.addAdditionalProductsToPayload(payload, ['sameDayDelivery', 'nextDayDelivery', 'twoDayDelivery']);
    return this.orderProvider.getDeliveryDates(payload);
  }
  public isAdditionalOption (name, field: 'selected' | 'enabled', value = true) {
    let options = [];
    if (this.deliveryOptions) {
      options = options.concat(this.deliveryOptions);
    }
    if (this.upsellProducts) {
      options = options.concat(this.upsellProducts);
    }
    return !!(
      options &&
      options.filter(opt => opt.name === name && opt[field] === value)[0]
    );
  }
  public isRushDeliverySelected () {
    return this.isAdditionalOption('twoDayDelivery', 'selected') ||
      this.isAdditionalOption('sameDayDelivery', 'selected') ||
      this.isAdditionalOption('nextDayDelivery', 'selected')
  }
  public onDeliveryOptionChange (event: {product: any, products: any[]}) {
    this.onAdditionalOptionChange(event, this.deliveryOptions);
  }
  public onUpsellOptionChange (event: {product: any, products: any[]}) {
    this.onAdditionalOptionChange(event, this.upsellProducts);
  }
  public onAdditionalOptionChange (event: {product: Product, products: Product[]}, optionsList: Product[]) {
    if (!event || !event.products) {
      optionsList.forEach((opt) => {
        opt.selected = false;
      });
      this.checkPrice();
      return;
    }
    const {products} = event;
    optionsList.forEach((opt) => {
      opt.selected = !!(products.filter(p => p.name === opt.name && p.selected)[0]);
    });
    this.checkPrice();
  }
  public async checkPrice() {
    /*
    * Returns the price for given product, order amount and selected options
    * */
    if (!this.order.product || (!this.order.orderedAmount && this.order.orderedAmountType !== 'fill')) {
      this.priceSummary = undefined;
      return;
    }
    const opts: any = {
      companyId: this.getCompanyId(),
      orderedAmountType: this.order.orderedAmountType,
      product: this.order.product,
      zip: this.deliveryZip,
    };
    if (this.order.orderedAmount) {
      opts.orderedAmount = this.order.orderedAmount;
    }
    this.addAdditionalProductsToPayload(opts);
    const data: PriceSummary = await this.priceProvider.getPricingDetails(opts);
    if (!data || !data.sum) {
      this.toastr.error('Error: Could not load price data', 'Whoops!');
      return;
    }
    this.priceSummary = data;
    return data;
  }
  /*
  * If user has selected any additional products,
  * adds the key=value pairs to order payload
  * */
  public addAdditionalProductsToPayload (payload, additionalProducts?: string[]) {
    if (!additionalProducts) {
      additionalProducts = [
        'sameDayDelivery', 'nextDayDelivery', 'emergencyPrime',
        'twoDayDelivery', 'autoFill', 'tuneup'
      ];
    }
    additionalProducts.forEach((field) => {
      const value = this.isAdditionalOption(field, 'selected');
      if (value) {
        payload[field] = value;
      }
    });
    return payload;
  }
  public loadCompany(companyId: number) {
    return this.companyProvider
      .getCompany(companyId)
      .then((company: any) => {
        this.setCompany(company);
        return company;
      });
  }
  public setCompany (company: Company) {
    this.fuelProducts.forEach((product) => {
      product.enabled = (
        (company.offersHeatingOil && product.name === 'oil') ||
        (company.offersPropane && product.name === 'propane') ||
        (company.offersOffsiteDiesel && product.name === 'offsite_diesel')
      );
    });
    this.company = company;
  }
  public companyOffersFasterDelivery () {
    const opts = this.deliveryOptions;
    if (!opts || !Object.keys(opts).length) {
      return false;
    }
    return this.isAdditionalOption('sameDayDelivery', 'enabled') ||
      this.isAdditionalOption('nextDayDelivery', 'enabled') ||
      this.isAdditionalOption('twoDayDelivery', 'enabled')
  }
  async submitOrder() {
    try {
      const customer = this.customer;
      const orderPayload = Object.assign({}, this.order, {
        tankId: this.order.product === 'propane' ? customer.gasTank.id : customer.tank.id,
        inspection: false,
        customerId: customer.id,
        product: this.order.product as FuelProductName,
      });
      this.addAdditionalProductsToPayload(orderPayload);
      this.submittingOrder = true;
      await this.orderProvider.postOrder(orderPayload);
      this.submittingOrder = false;
      this.toastr.success('New order created', 'Success!');
    } catch (err) {
      console.error(err);
      this.submittingOrder = false;
    }
  }
  /*
  * Returns 1 sub-item from price summary object
  * */
  public getPriceSummaryItem(name) {
    if (!this.priceSummary || !name) {
      return {};
    }
    const priceRows = {};
    const collectRows = (data) => {
      if (!data) {
        return;
      }
      priceRows[data.name] = data;
      if (data.details) {
        data.details.forEach(collectRows);
      }
    };
    collectRows(this.priceSummary);
    return priceRows[name] ? priceRows[name] : {};
  }
  public getCardDescription (card: Card) {
    if (!card || !card.id) {
      return 'n/a';
    }
    if (card.object === 'bank_account') {
      return `${card.bankName} | ${card.last4}`;
    }
    const exp = moment().month(card.expMonth - 1).year(card.expYear).format('MM/YY');
    return `${card.brand}   |   ${card.last4}   |   ${exp}`;
  }
  public getCompanyPriceWithDiscounts () {
    const companyPrice = this.getPriceSummaryItem('companyPrice');
    const achDiscount = this.getPriceSummaryItem('achDiscount');
    if (!companyPrice) {
      return '';
    }
    const companyPriceSum = parseFloat(companyPrice.sum);
    if (!achDiscount || !achDiscount.sum) {
      return companyPriceSum;
    }
    const achDiscountSum = parseFloat(achDiscount.sum);
    const price = companyPriceSum - achDiscountSum;
    return (price < 0) ? 0 : price;
  }
}
