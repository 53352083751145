import { Injectable } from '@angular/core';
import { API } from './api.provider';
import { Tank } from '../../interfaces/tank.interface';
import { GasTank } from '../../interfaces/gas-tank.interface';

@Injectable()
export class TankProvider {
  constructor(
    private api: API
  ) {}

  createTank(tank: Tank) {
    const {size, location, description} = tank;
    const {line1, line2, city, state, zip} = tank.address;
    return this.api.post('/tank', {
      size, location, description,
      address: {line1, line2, city, state, zip}
    }).then((response: any) => {
      return response.data as Tank;
    });
  }
  createGasTank(tank: GasTank) {
    const {size, location, description, groundLevel, ownership} = tank;
    const {line1, line2, city, state, zip} = tank.address;
    return this.api.post('/gas-tank', {
      size, location, description, groundLevel, ownership,
      address: {line1, line2, city, state, zip}
    }).then((response: any) => {
      return response.data as GasTank;
    });
  }
  updateTank(tank: Tank) {
    const {size, location, description, estimatedOilAmount} = tank;
    return this.api.patch('/tank/' + tank.id, {
      size, location, description, estimatedOilAmount
    }).then((response: any) => {
      return response.data as Tank;
    });
  }
  updateGasTank(tank: GasTank) {
    const payload = {};
    ['size', 'location', 'description', 'groundLevel', 'ownership'].forEach((field) => {
      const value = tank[field];
      if (value === undefined || value === null) {
        return;
      }
      payload[field] = value;
    });
    return this.api.patch('/gas-tank/' + tank.id, payload).then((response: any) => {
      return response.data as GasTank;
    });
  }
  setOilAmount ({tankId, amount}: {tankId: number, amount: number}) {
    return this.api.patch(`/tank/${tankId}/amount`, {
      amount
    });
  }
  completeInspection ({tankId, companyId, completedAt}) {
    // ToDo: replace this temp function with TankTracker integration.
    return this.api.post('/tank-inspection/tank/' + tankId, {
      status: 'completed',
      completedAt,
      companyId,
    });
  }
}
