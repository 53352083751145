import { Injectable } from '@angular/core';
import { API } from './api.provider';

@Injectable()
export class TagsProvider {
  constructor(
    private api: API
  ) {}

  getCompanyTags(companyId) {
    return this.api.get(`/company/${companyId}/tags`, {});
  }
  tagCustomer(
    {companyId, customerId, tagId, label}:
    {companyId: number, customerId: number, tagId?: number, label?: string}
  ) {
    return this.api.post(`/company/${companyId}/customer/${customerId}/tag`, {tagId, label}).then((response: any) => {
      return response.data;
    });
  }
  untagCustomer(
    {companyId, customerId, tagId}:
    {companyId: number, customerId: number, tagId: number}
  ) {
    return this.api.delete(`/company/${companyId}/customer/${customerId}/tag/${tagId}`);
  }
  getTaggedCustomers ({companyId, tagId, count}: {companyId: number, tagId: number, count?: boolean}) {
    const search = count ? {count} : {};
    return this.api.get(`/company/${companyId}/tags/${tagId}/customers`, search);
  }
}
