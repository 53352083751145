<div class="loader w-100 text-center" *ngIf="loading">
  <h3>
    <i class="fas fa-spinner fa-spin me-2"></i>
    Loading...
  </h3>
</div>

<div class="data-table {{tableClasses}} " *ngIf="!loading">
  <div class="loader w-100 text-center" *ngIf="!rows.length && !showEmptyTable">
    <h3>No data to be displayed...</h3>
  </div>

  <table class="table" [style.min-width.px]="minWidth" *ngIf="rows.length || showEmptyTable">
    <thead>
      <tr>
        <td
          *ngFor="let header of headers"
          (click)="onHeaderClick(header)"
          [ngClass]="{link: header.click || header.sortable}"
          class="{{header.class ? header.class : ''}}"
        >
          <ng-container
            *ngTemplateOutlet="header.headerTemplate; context: { value: header.headerContext }"
          ></ng-container>
          <ng-template [ngIf]="!header.headerTemplate">
            {{ header.name }}
            <span [ngClass]="{'icn-arrow-up': header?.sort === 'asc', 'icn-arrow-down': header?.sort === 'desc'}"></span>
          </ng-template>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="!rows?.length && showEmptyTable">
        <td [attr.colspan]="headers.length">
          <div class="loader w-100 text-center">
            <h3>No data to be displayed...</h3>
          </div>
        </td>
      </tr>
      <ng-template ngFor let-row [ngForOf]="rows">
        <tr class="tr1 {{row.class ? row.class : ''}}">
          <td
            *ngFor="let header of headers"
            [class.link]="header.isLink"
            [title]="header.title ? header.title : ''"
            [ngStyle]="header.style"
            (click)="cellClicked($event, header.field, row)"
          >
            <ng-container
              *ngTemplateOutlet="header.template; context: { value: row }"
            ></ng-container>
            <ng-template [ngIf]="!header.template">
              <span *ngIf="!header.currency && !header.number && !header.capitalizeFirst && !header.date" >
                {{ row[header.field] && row[header.field].length ? (row[header.field] | titlecase) : row[header.field] }}
              </span>
              <span *ngIf="header.capitalizeFirst">
                {{ row[header.field] | capitalizefirst}}
              </span>
              <span *ngIf="header.currency">
                {{ row[header.field] | currency}}
              </span>
              <span *ngIf="header.number">
                {{ row[header.field] | number}}
              </span>
              <span *ngIf="header.date">
                {{ row.date | date: header.date}}
              </span>
            </ng-template>
          </td>
        </tr>
        <tr *ngIf="expandedTemplate && row.expanded">
          <td [attr.colspan]=expandedColspan>
            <ng-container
              *ngTemplateOutlet="expandedTemplate; context: { value: row }"
            ></ng-container>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
  <div class="row">
    <div class="col-12" style="text-align: center">
      <pagination
        *ngIf="pagination?.total && pagination.total > pagination.pageSize"
        [totalItems]="pagination.total"
        [itemsPerPage]="pagination.pageSize"
        [(ngModel)]="pagination.currentPage"
        [disabled]="loading"
        [maxSize]="5"
        (pageChanged)="pageChanged($event)"
        style="display: inline-block"
      ></pagination>
    </div>
  </div>
</div>
