import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Tank} from '../../../interfaces/tank.interface';
import {TankProvider} from '../../providers/tank.provider';
import {GasTank} from '../../../interfaces/gas-tank.interface';
import {BsModalService} from 'ngx-bootstrap/modal';
import moment from 'moment';
import {TankInspection} from '../../../interfaces/tank-inspection.interface';
import {ConfigProvider} from '../../../providers/config.provider';
import {ToastrService} from 'ngx-toastr';
import {CompanyProvider} from '../../../providers/company.provider';
import {Subscription} from '../../../interfaces/subscription';
import {TankTrackerProvider} from '../../../providers/tank-tracker.provider';
@Component({
  selector: 'app-tank-info',
  templateUrl: './tank-info.component.html',
  styleUrls: ['./tank-info.component.scss'],
  providers: [TankProvider, ConfigProvider, CompanyProvider, TankTrackerProvider],
})
/*
* This module allows company/customer/admin to select and view customer tank info.
* */
export class TankInfoComponent implements OnInit {
  @ViewChild('inspectionModalTemplate', {static: true}) inspectionModalTemplate;
  public inspectionModalRef: any;
  @Output() onSaveSuccess = new EventEmitter<{tank: Tank | GasTank, kFactor: number, product: string}>();
  @Input()
  public userRole: 'admin' | 'customer' | 'company'; // who is currently filling the form
  @Input() edit = false;
  @Input() classes = '';
  @Input() showDescription = false;
  @Input() companyId: number;
  // height of the house icon when selecting tank location
  @Input() locationOptionIconHeight = '50px';
  public selectedTank: {tank: GasTank | Tank, product: string, _saving?: boolean};
  public tankInspectionCompletedAt: Date;
  public savingTankInspection: boolean;
  public tomorrowDate = moment().add(1, 'day').toDate();
  public kFactor: string | number = 'n/a';
  public customTankSize = false;
  public newKFactor: string;
  public savingKFactor = false;
  public _tanks: Array<{tank: GasTank | Tank, product: string}>;
  public adjustedOilAmountPercent: number;
  public tankSizeOptions = [
    {value: '330', label: '330'},
    {value: '275', label: '275'},
    {value: 'custom', label: 'Custom'},
    /* default tank size is 275 */
    {value: '275', label: 'Not Sure'},
  ];
  public tankTrackerFee: number;
  public tankTrackerFeeLoaded = false;
  public tankInspectionMethod: '' | 'manual' | 'remote';
  public activatingTankTracker = false;
  public tankTrackerSubscription: Subscription;
  // if company tried to use remote inspection but hasn't subscribed to tank tracker yet
  public activatingTankTrackerRequired = false;
  public loadingTankTrackerCustomer = false;
  public loadingTankTrackerToken = false;
  public tankTrackerCustomer: any; // customer info from tank tracker.
  public loadingSubscription = false;
  public loadingRemoteInspection = false;
  public remoteInspection: any;
  @Input() set tanks (tanksArray: Array<{tank: GasTank | Tank, product: string}>) {
    this._tanks = tanksArray;
    this.selectedTank = this.tanks[0];
    if (this.selectedTank && this.selectedTank.tank) {
      const size = this.selectedTank.tank.size;
      if (!size) {
        this.selectedTank.tank.size = 275;
      } else if (![330, 275].includes(size)) {
        // custom size
        this.tankSizeOptions.splice(2, 0, {value: size.toString(), label: size.toString()})
      }
    }
    this.tanks.forEach((t) => {
      if (t.product === 'oil') {
        const tank = t.tank as Tank;
        this.adjustedOilAmountPercent = this.getAdjustedOilAmountPercent(tank);
        this.getKFactor(tank.userId);
      }
    });
  };
  constructor(
    private modalService: BsModalService,
    private tankProvider: TankProvider,
    private configProvider: ConfigProvider,
    private toastr: ToastrService,
    private companyProvider: CompanyProvider,
    private tankTrackerProvider: TankTrackerProvider,
  ) {}
  ngOnInit() {}
  get isPowerUser () {
    return this.userRole === 'admin' || this.userRole === 'company';
  }
  get tanks () {
    return this._tanks;
  }
  public async getTankTrackerFee () {
    try {
      this.tankTrackerFee = await this.tankTrackerProvider.getSubscriptionFee({
        companyId: this.companyId,
      });
    } catch (error) {
      this.toastr.error(error.message, 'Error!');
    }
    this.tankTrackerFeeLoaded = true;
  }
  public getKFactor (userId) {
    this.configProvider.getOne({
      name: 'autofillBaseKFactor',
      userId
    }, {disableToastr: true}).then((config) => {
      if (config && config.value) {
        this.kFactor = parseFloat(config.value).toFixed(2);
        this.newKFactor = this.kFactor;
      } else {
        this.kFactor = 'n/a';
      }
    });
  }
  public saveKFactor (userId) {
    if (this.newKFactor === this.kFactor) {
      return;
    }
    this.savingKFactor = true;
    this.configProvider.update({
      name: 'autofillBaseKFactor',
      userId,
      value: this.newKFactor
    }).then((config: any) => {
      this.kFactor = parseFloat(config.value);
    });
  }
  public getAdjustedOilAmountPercent(tank: Tank) {
    if (!tank || !tank.size || tank.estimatedOilAmount === null || tank.estimatedOilAmount === undefined) {
      return ;
    }
    if (tank.estimatedOilAmount >= tank.size) {
      return 100;
    }
    return Math.round((tank.estimatedOilAmount / tank.size) * 100);
  }
  public setAdjustedOilAmount (percent) {
    if (!this.selectedTank || !this.selectedTank.tank.size || this.selectedTank.product !== 'oil') {
      return ;
    }
    const tank = this.selectedTank.tank;
    (tank as Tank).estimatedOilAmount = Math.round((percent / 100) * tank.size);
  }
  public get tankSize () {
    if (!this.selectedTank || !this.selectedTank.tank.size) {
      return 'n/a';
    }
    return this.selectedTank.tank.size + ' Gal';
  }
  public get groundLevel () {
    if (!this.selectedTank || !(this.selectedTank.tank as GasTank).groundLevel) {
      return 'n/a';
    }
    return (this.selectedTank.tank as GasTank).groundLevel + ' ground';
  }
  public onTankOwnershipChange (customerOwnsTank: string | boolean) {
    (this.selectedTank.tank as GasTank).ownership = (customerOwnsTank === true || customerOwnsTank === 'true');
  }
  public async openInspectionModal() {
    this.loadingSubscription = true;
    await this.getTankTrackerFee();
    if (this.selectedTank.product === 'oil') {
      const tank: Tank = this.selectedTank.tank as Tank;
      if (tank.tankInspection && tank.tankInspection.id) {
        this.remoteInspection = undefined;
        this.loadingRemoteInspection = true;
        try {
          // check if we already started the inspection on tank tracker side.
          this.remoteInspection = await this.tankTrackerProvider.getInspectionByCurrentInspectionId({
            companyId: this.companyId,
            customerId: tank.userId,
            currentInspectionId: tank.tankInspection.id
          });
        } catch (httpErrorResponse) {
          if (httpErrorResponse.status === 404) {
            console.log('remoteInspection not started yet');
          }
        }
        this.loadingRemoteInspection = false;
      }
    }
    const subscription = await this.tankTrackerProvider.getTankTrackerSubscription({companyId: this.companyId}) as any;
    this.loadingSubscription = false;
    if (subscription && subscription.id) {
      this.tankTrackerSubscription = subscription;
    }
    this.inspectionModalRef = this.modalService.show(this.inspectionModalTemplate, {
      ignoreBackdropClick: true,
    });
  }
  public async completeTankInspection(): Promise<void> {
    try {
      this.savingTankInspection = true;
      const response: { err: any, data: TankInspection } = await this.tankProvider.completeInspection({
        tankId: this.selectedTank.tank.id,
        completedAt: this.tankInspectionCompletedAt,
        companyId: this.companyId,
      }) as any;
      if (response.data) {
        const tank = this.selectedTank.tank as Tank;
        tank.tankInspection = response.data;
      }
      this.inspectionModalRef?.hide();
    } catch (error) {
      console.error('Failed to complete inspection', error.message);
    }
    this.savingTankInspection = false;
  }
  onTankLocationSelect (location) {
    this.selectedTank.tank.location = location;
  }
  onTankSizeChange ($event) {
    const tankSize: any = this.selectedTank.tank.size;
    if (tankSize === 'custom') {
      this.customTankSize = true;
    }
  }
  async saveTank() {
    try {
      const tankSize: any = this.selectedTank.tank.size;
      if (tankSize === 'custom') {
        // user wanted to enter custom value but didn't. Just default back to "not sure" value.
        this.selectedTank.tank.size = 275;
      }
      this.selectedTank._saving = true;
      if (this.selectedTank.product === 'propane') {
        await this.tankProvider.updateGasTank(this.selectedTank.tank as GasTank);
        this.toastr.success('Propane tank updated successfully', 'Success!');
      } else if (this.selectedTank.product === 'oil') {
        await this.tankProvider.updateTank(this.selectedTank.tank as Tank);
        this.toastr.success('Heating oil tank updated successfully', 'Success!');
      } else {
        this.toastr.error('Invalid tank type', 'Error!');
      }
      await this.saveKFactor(this.selectedTank.tank.userId);
      this.onSaveSuccess.emit({
        tank: this.selectedTank.tank,
        kFactor: parseFloat(this.kFactor as string),
        product: this.selectedTank.product
      });
    } catch (error) {
      console.log('Could not save tank notes:', error);
    }
    this.selectedTank._saving = false;
  }
  cancelTankInspection () {
    this.inspectionModalRef.hide();
    setTimeout(() => {
      // small delay to avoid flicker when modal fades away
      this.activatingTankTrackerRequired = false;
      this.tankInspectionMethod = '';
    }, 1000);
  }
  onTankTrackerActivationSuccess () {
    this.activatingTankTrackerRequired = false;
    this.loadTankTrackerCustomer();
  }
  async activateTankTracker () {
    this.activatingTankTracker = true;
    const defaultErrorMsg = 'Failed to activate tank tracker';
    try {
      const response: any = await this.tankTrackerProvider.activateTankTracker({companyId: this.companyId}) as any;
      if (!response.data || !response.data.subscription) {
        throw new Error(response.msg || defaultErrorMsg);
      }
      const subscription = response.data.subscription;
      this.tankTrackerSubscription = subscription;
      if (subscription.status === 'active') {
        this.toastr.success('TankTracker Activated', 'Success!');
        this.onTankTrackerActivationSuccess();
      } else {
        throw new Error(subscription.errorMessage || defaultErrorMsg)
      }
    } catch (error) {
      this.toastr.error(error.message, 'Error!');
    }
    this.activatingTankTracker = false;
  }
  async startInspectionOnTankTracker () {
    const url = await this.tankTrackerProvider.getStartInspectionUrl({
      companyId: this.companyId,
      customerId: this.selectedTank.tank.userId,
    });
    window.open(url);
  }
  async openInspectionOnTankTracker () {
    const tank = this.selectedTank.tank as Tank;
    const url = await this.tankTrackerProvider.getInspectionUrl({
      companyId: this.companyId,
      customerId: this.selectedTank.tank.userId,
      tankTrackerInspectionId: tank.tankInspection.tankTrackerInspectionId,
    });
    window.open(url);
  }
  async navigateToRemoteInspection () {
    const tank = this.selectedTank.tank as Tank;
    if (this.inspectionPendingCustomerInitialization) {
      // we cant start new inspection or open existing one since it hasnt been created yet.
      // open customer´s page instead, so the company can resend the request if needed.
      try {
        const url = await this.tankTrackerProvider.getUrlForPath({
          companyId: this.companyId,
          path: 'customer'
        });
        console.log('url is', url);
        window.open(url);
      } catch (error) {
        console.error('Failed to login to tank tracker', error.message);
      }
    } else if (this.canStartTankInspection) {
      return this.startInspectionOnTankTracker();
    } else if (tank.tankInspection.tankTrackerInspectionId) {
      return this.openInspectionOnTankTracker();
    } else {
      this.toastr.error('Could not find remote inspection for this tank on tank tracker', 'Error!');
    }
  }
  async loadTankTrackerCustomer () {
    try {
      this.loadingTankTrackerCustomer = true;
      this.tankTrackerCustomer = await this.tankTrackerProvider.getCustomer({
        companyId: this.companyId,
        customerId: this.selectedTank.tank.userId
      }) as any;
      if (this.tankTrackerCustomer && this.tankTrackerCustomer.id) {
        // send the latest data from Current to Tank tracker.
        await this.tankTrackerProvider.syncCustomer({
          companyId: this.companyId,
          customerId: this.selectedTank.tank.userId
        });
      }
      this.loadingTankTrackerCustomer = false;
    } catch (httpErrorResponse) {
      if (httpErrorResponse.status === 404) {
        // looks like we haven't sent customer info to tank tracker yet.
        this.loadingTankTrackerCustomer = true;
        this.tankTrackerCustomer = await this.tankTrackerProvider.createCustomer({
          companyId: this.companyId,
          customerId: this.selectedTank.tank.userId
        }) as any;
        this.loadingTankTrackerCustomer = false;
      } else {
        this.loadingTankTrackerCustomer = false;
        this.toastr.error(httpErrorResponse.error.msg || 'Internal Server Error', 'Error!');
      }
    }
  }
  async onSelectRemoteInspection () {
    this.tankInspectionMethod = 'remote';
    this.activatingTankTrackerRequired = !this.tankTrackerSubscription || this.tankTrackerSubscription.status !== 'active';
    if (!this.activatingTankTrackerRequired) {
      this.loadTankTrackerCustomer();
    }
  }
  public formatDate(date, format) {
    if (!date) {
      return 'n/a';
    }
    return moment(date).format(format);
  }
  public get canStartTankInspection () {
    if (this.loadingTankTrackerToken || !this.selectedTank.tank || this.selectedTank.product !== 'oil') {
      return false;
    }
    const tank: Tank = this.selectedTank.tank as Tank;
    return !tank.tankInspection || (!this.loadingRemoteInspection && !this.remoteInspection);
  }
  public get canOpenTankInspection () {
    if (this.loadingTankTrackerToken || !this.selectedTank.tank || this.selectedTank.product !== 'oil') {
      return false;
    }
    const tank: Tank = this.selectedTank.tank as Tank;
    return tank.tankInspection && tank.tankInspection.tankTrackerInspectionId
  }
  public get inspectionPendingCustomerInitialization () {
    /*
    * On tank tracker, inspection entry is created when the customer accepts the inspection request
    * Until then, there is no inspection entry/url to be opened
    * remoteInspection entry is created on tank tracker when company starts the inspection process on Current.
    * tankInspection.tankTrackerInspectionId is set once the customer has accepted the request and tank inspection has
    * started on tanktracker.
    * */
    const tank: Tank = this.selectedTank.tank as Tank;
    return !this.loadingTankTrackerToken &&
    !this.loadingRemoteInspection &&
    this.remoteInspection &&
    !tank.tankInspection.tankTrackerInspectionId
  }
}
