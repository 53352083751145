<section class="tank-info {{classes}} " *ngIf="customer && customer.tank" (keyup)="keyUp()">
  <h5 *ngIf="title">{{title}}</h5>
  <div class="row" *ngIf="!sizeInputDisabled">
    <div class="col-6">
      <label for="tank-size">Size</label>
    </div>
    <div class="col-6">
      <select
        id="tank-size"
        class="form-control"
        style="width: 6em;"
        *ngIf="!customTankSize"
        [(ngModel)]="customer.tank.size"
        (ngModelChange)="onTankSizeChange($event)"
      >
        <option value="{{option.value}}" *ngFor="let option of tankSizeOptions">{{option.label}}</option>
      </select>
      <input
        type="number"
        inputmode="numeric"
        title="enter tank size"
        style="width: 8em;display: inline-block"
        class="form-control"
        placeholder="enter size"
        *ngIf="customTankSize"
        [(ngModel)]="customer.tank.size"
      >
    </div>
  </div>
  <div class="row" *ngIf="askEstimatedTankLevel">
    <div class="col-6">
      <label for="tank-level">How full is your tank?</label>
    </div>
    <div class="col-6">
      <select
        id="tank-level"
        class="form-control"
        [(ngModel)]="estimatedTankLevel"
        (ngModelChange)="onTankLevelChange($event)"
      >
        <option value="{{option.value}}" *ngFor="let option of tankLevelOptions">{{option.label}}</option>
      </select>
    </div>
  </div>
  <div class="location-wrapper">
    <div class="row">
      <div class="col-12">
        <label>Location</label>
      </div>
    </div>
    <div class="tank-location-options">
      <div class="location-option" *ngFor="let option of locationOptions">
        <img
          class="house"
          src="assets/img/{{option.icon}}"
          alt="{{option.label}}"
          height="50px"
          (click)="customer.tank.location = option.value"
        >
        <label>
          <input
            type="radio"
            name="tank"
            id="tank-{{option.value}}"
            value="front"
            (click)="customer.tank.location = option.value"
            [checked]="customer.tank.location === option.value"
          >
          {{option.label}}
        </label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label for="tank-description">Description</label>
    </div>
    <div class="col-12">
      <textarea placeholder="Please describe in detail where your fill pipe is located, including any helpful hints! Please provide the color of your home and the closest cross street." id="tank-description" [(ngModel)]="customer.tank.description" class="form-control" rows="2"></textarea>
    </div>
  </div>
  <div class="row" *ngIf="isPowerUser">
    <div class="col-6">
      <label for="tank-inspected" title="has customers' tank been inspected">Tank inspection completed</label>
    </div>
    <div class="col-6">
      <input id="tank-inspected" type="checkbox" class="form-check-inline" [(ngModel)]="customer.tank.inspected">
    </div>
  </div>
  <button *ngIf="nextBtnText" class="btn btn-default-dark no-radius btn-lg w-100" (click)="nextBtnClick()">
    {{nextBtnText}}
  </button>
</section>
