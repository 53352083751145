import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Customer} from '../../../interfaces/customer.interface';
import {Tank} from '../../../interfaces/tank.interface';
import {TankProvider} from '../../providers/tank.provider';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-customer-tank',
  templateUrl: './customer-tank.component.html',
  styleUrls: ['./customer-tank.component.scss'],
  providers: [TankProvider]
})

export class CustomerTankComponent implements OnInit {
  @Input() saveOnNext = false; // if true, clicking "Next" will save the tank before continuing
  @Input() sizeInputDisabled = false;
  @Input() askEstimatedTankLevel = false;
  @Input() nextBtnText = '';
  @Input() public title = '';
  @Input()
  public userRole: 'admin' | 'customer' | 'company'; // who is currently filling the form
  @Input()
  public customer: Customer;
  @Input()
  public classes: string;
  @Output()
  public onChange = new EventEmitter<Tank>();
  @Output()
  public onNext = new EventEmitter<Tank>();
  public saving = false;
  public customTankSize = false;
  public locationOptions = [
    {value: 'front', label: 'Front', icon: 'icn-houseBottom.svg'},
    {value: 'right', label: 'Right', icon: 'icn-houseRight.svg'},
    {value: 'back', label: 'Back', icon: 'icn-houseBack.svg'},
    {value: 'left', label: 'Left', icon: 'icn-houseLeft.svg'},
  ];
  public tankSizeOptions = [
    {value: 330, label: '330'},
    {value: 275, label: '275'},
    {value: 'custom', label: 'Custom'},
    /* default tank size is 275 */
    {value: 275, label: 'Not Sure'},
  ];
  public tankLevelOptions = [
    {value: 6.25, label: 'Less than 1/8th'},
    {value: 12.5, label: '1/8th'},
    {value: 25, label: '1/4'},
    {value: 50, label: '1/2'},
    {value: 75, label: '3/4'},
    {value: 100, label: 'Full'},
  ];
  public estimatedTankLevel: number;
  constructor(
    public toastr: ToastrService,
    private tankProvider: TankProvider,
  ) {}
  ngOnInit() {
    if (!this.customer.tank) {
      this.customer.tank = Tank({
        address: this.customer.address,
        userId: this.customer.id,
      });
    }
    const size = this.customer.tank.size;
    if (!size) {
      this.customer.tank.size = 275;
    } else if (![330, 275].includes(size)) {
      // custom size
      this.tankSizeOptions.splice(2, 0, {value: size.toString(), label: size.toString()})
    }
  }
  async nextBtnClick () {
    if (!this.customer.tank.size && !this.sizeInputDisabled) {
      return this.toastr.error('Please enter tank size', 'Error!');
    }
    if (this.saveOnNext) {
      if (this.saving) {
        return;
      }
      const nextBtnText = this.nextBtnText;
      try {
        this.nextBtnText = 'Saving, please wait...'
        this.saving = true;
        this.customer.tank = await this.tankProvider.createTank(this.customer.tank) as Tank;
        this.saving = false;
        this.nextBtnText = nextBtnText;
      } catch (error) {
        this.saving = false;
        this.nextBtnText = nextBtnText;
        return this.toastr.error(error.message, 'Error!');
      }
    }
    this.onNext.next(this.customer.tank);
  }
  onTankSizeChange ($event) {
    const tankSize: any = this.customer.tank.size;
    if (tankSize === 'custom') {
      this.customTankSize = true;
    }
  }
  onTankLevelChange ($event) {
    if (!this.customer.tank.size) {
      this.customer.tank.estimatedOilAmount = undefined;
      return;
    }
    if (!this.estimatedTankLevel) {
      this.customer.tank.estimatedOilAmount = 0;
      return;
    }
    this.customer.tank.estimatedOilAmount = Math.round((this.estimatedTankLevel / 100) * this.customer.tank.size);
  }
  keyUp() {
    setTimeout(() => {
      this.onChange.emit(this.customer.tank);
    }, 32);
  }
  get isPowerUser () {
    return this.userRole === 'admin' || this.userRole === 'company';
  }
}
