type Status = 'disabled' | 'active' | 'unverified' | 'pending';
type Role = 'customer' | 'admin' | 'company' | 'service_company' | 'affiliate' |'' | null;
import {Address} from './address.interface';
import {Company} from './company.interface';
import {Message} from './message.interface';
import {Affiliate} from './affiliate.interface';

export interface User {
  id?: number,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  password?: string,
  role: Role,
  companyId?: number,
  company?: Company,
  affiliateId?: number,
  affiliate?: Affiliate,
  status: Status,
  stripeId?: string,
  pendingZip?: string,
  active: boolean,
  createdAt?: string,
  updatedAt?: string,
  deletedAt?: string,
  sendSms?: boolean,
  sendEmail?: boolean,
  _token?: string, // login token
  address?: Address,
  messages?: Message[],
  tags?: Array<{id?: number, label: string, userTagId?: number, _selected?: boolean}>,
  _saving?: boolean,
  _loading?: boolean,
  _showMessages?: boolean,
  _message?: string,
  _nrUnreadMessages?: number;
}
export const User = (fieldValues?): User => {
  const user: User = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: '',
    status: 'disabled',
    active: false,
    address: Address()
  };
  if (fieldValues) {
    Object.assign(user, fieldValues);
  }
  return user;
};
