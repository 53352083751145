import { Component, OnInit, Input } from '@angular/core';
interface Tile {
  label: string;
  value: any;
  title?: string;
  routerLink?: string;
  queryParams?: any;
  isCurrency?: boolean;
}
@Component({
  selector: 'tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.scss'],
  providers: [],
})
export class TilesComponent implements OnInit {
  @Input() headerClass = '';
  @Input() valueClass = '';
  @Input() tileClass = '';
  @Input() valueStyle = '';
  _tiles: Tile[] = [];
  constructor() {}

  @Input() set tiles (tiles: Tile[]) {
    this._tiles = tiles;
  }

  ngOnInit() {
  }
}
