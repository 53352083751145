import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {User} from '../../interfaces/user.interface';
import {Company} from '../../interfaces/company.interface';

@Injectable()
export class AuthProvider {
  user: User;
  company: Company;
  companyId: number;
  userId: number;
  role: string;
  onAuthChange = new Subject<User|boolean>();
  constructor() {
    const user = this.getUser();
    if (user) {
      this.setUser(user);
    }
  }

  hasToken() {
    return !!localStorage.getItem('JWT');
  }

  hasUser() {
    return !!localStorage.getItem('user');
  }

  setToken(token) {
    localStorage.setItem('JWT', token);
  }

  setUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
    if (!user) {
      return this.logout();
    }
    this.user = user;
    this.company = user.company;
    this.companyId = user.companyId;
    this.role = user.role;
    this.userId = user.id;
    this.onAuthChange.next(user);
  }

  getToken() {
    return localStorage.getItem('JWT');
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('postLoginRedirect');
    localStorage.removeItem('JWT');
    localStorage.removeItem('affiliateJWT');
    this.user = undefined;
    this.company = undefined;
    this.companyId = undefined;
    this.role = undefined;
    this.userId = undefined;
    this.onAuthChange.next(false);
  }
}
