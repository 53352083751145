import {Component, Input, OnInit} from '@angular/core';
import {UserProvider} from '../../../providers/user.provider';
import {AuthProvider} from '../../providers/auth.provider';
import {Customer} from '../../../interfaces/customer.interface';
import {environment} from '../../../../environments/environment';
import {ToastrService} from 'ngx-toastr';

declare var Stripe: any;

@Component({
  selector: 'app-stripe-checkout',
  templateUrl: './stripe-checkout.component.html',
  styleUrls: ['./stripe-checkout.component.scss'],
  providers: [UserProvider]
})

export class StripeCheckoutComponent implements OnInit {
  @Input() public customer: Customer;
  @Input() public paymentIntent: any;
  @Input() public submitBtnText = 'Submit';
  @Input() public returnUrl = '';
  public stripe: any;
  public loading = false;
  constructor(
    public authProvider: AuthProvider,
    private userProvider: UserProvider,
    private toastr: ToastrService,
  ) {

  }
  ngOnInit() {
    this.initStripe();
  }
  initStripe() {
    const address = {
      line1: 'address.line1',
      line2: 'address.line2',
      city: 'address.city',
      state: 'address.state',
      country: 'US',
      postalCode: 'address.zip',
    }
    this.stripe = Stripe(environment.Stripe.API_KEY);
    const options = {
      amount: this.paymentIntent.amount,
      mode: 'payment',
      currency: 'usd',
      capture_method: 'automatic', // manual | null
      appearance: {},
    };
    const elements = this.stripe.elements(options);
    const paymentElement = elements.create(
      'payment',
    );
    const form = document.getElementById('payment-form');
    paymentElement.mount('#payment-element');
    form.addEventListener('submit', async (event) => {
      const handleError = (error: any) => {
        this.toastr.error(error.message, 'Payment Error!');
        const messageContainer = document.querySelector('#error-message');
        messageContainer.textContent = error.message;
      }
      this.loading = true;
      event.preventDefault();
      const submitResult = await elements.submit();
      if (submitResult.error) {
        handleError(submitResult.error);
        return;
      }
      const confirmResult = await this.stripe.confirmPayment({
        elements,
        clientSecret: this.paymentIntent.clientSecret,
        confirmParams: {
          return_url: this.returnUrl || window.location.href,
        },
      });
      this.loading = false;
      if (confirmResult.error) {
        handleError(confirmResult.error);
        return;
      }
    });
  }
}
