import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';

import { NeedleGaugeComponent } from './components/needle-gauge/needle-gauge.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';

import { PagesComponent } from './pages/pages.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { LandingComponent } from './pages/landing/landing.component';
import { LoginComponent } from './pages/login/login.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { CustomerLeadComponent } from './pages/customer-lead/customer-lead.component';

import { AuthProvider } from './shared/providers/auth.provider';
import { BaseProvider } from './shared/providers/base.provider';
import { FilterPipe } from './shared/pipes/filter.pipe';
import { SharedModule } from './shared/shared.module';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { CompanyCanActivateGuard } from './guards/company-can-activate.guard';
import { AdminGuard } from './guards/admin-can-load.guard';
import { AdminCanActivateGuard } from './guards/admin-can-activate.guard';
import { AffiliateCanActivateGuard } from './guards/affiliate-can-activate.guard';

import { AdminModule } from './admin/modules/admin.module';
import { CompanyModule } from './company/modules/company.module';
import { CustomerModule } from './customer/modules/customer.module';
import { OrderComponent } from './customer/pages/order/order.component';
import { RegisterComponent } from './customer/pages/register/register.component';
import { AffiliateModule } from './affiliate/modules/affiliate.module';
import { OrderUpsellItemComponent } from './customer/pages/order-upsell-item/order-upsell-item.component';
import {CarouselModule} from 'ngx-bootstrap/carousel';

// allow companies to define and use their own custom uri segments.
// e.g www.current.com/:companyId/:companyUri/order
//     www.current.com/5/knight-oil/order
// Custom uri segment is optional, so, if company has not provided a custom uri, then
// the url will be www.current.com/5/order
// Any url that begins with a number belongs to a company matching that id.
// If no sub-url matches, then direct user to /:companyId/login
// ToDo: remove custom uri option to keep things simple
const getCompanyId = (url: UrlSegment[]) => {
  if (!url.length) {
    return;
  }
  const companyIdSegment = url[0];
  if (companyIdSegment.path && companyIdSegment.path.match(/[0-9]+/)) {
    return companyIdSegment.path;
  }
}
const companyUriLoginMatcher = (url: UrlSegment[]) => {
  // all urls that start with company id and match nothing else are mapped to login page.
  const companyId = getCompanyId(url);
  return companyId ? {
    consumed: url,
    posParams: {
      companyId: new UrlSegment(url[0].path, {companyId: url[0].path}),
      page: new UrlSegment('login', {page: 'login'}),
    }
  } : null;
};
const companyUriOrderMatcher = (url: UrlSegment[]) => {
  const companyId = getCompanyId(url);
  if (!companyId) {
    return;
  }
  const path2 = url[1] ? url[1].path : null;
  const path3 = url[2] ? url[2].path : null;
  if ((!path3 && path2 === 'order') || (path3 === 'order')) {
    return {
      consumed: url,
      posParams: {
        companyId: new UrlSegment(url[0].path, {companyId: url[0].path})
      }
    };
  }
  return null;
};
const companyUriRegisterMatcher = (url: UrlSegment[]) => {
  const companyId = getCompanyId(url);
  if (!companyId) {
    return;
  }
  const path2 = url[1] ? url[1].path : null;
  const path3 = url[2] ? url[2].path : null;
  if ((!path3 && path2 === 'register') || (path3 === 'register')) {
    return {
      consumed: url,
      posParams: {
        companyId: new UrlSegment(url[0].path, {companyId: url[0].path})
      }
    };
  }
  return null;
};
const appRoutes: Routes = [
  { path: ':companyId/product/:productName', component: OrderUpsellItemComponent},
  { matcher: companyUriRegisterMatcher, component: RegisterComponent },
  { matcher: companyUriOrderMatcher, component: OrderComponent },
  { matcher: companyUriLoginMatcher, component: LoginComponent},
  { path: '', component: LandingComponent },
  { path: 'claim/c/:companyId/:token', component: CustomerLeadComponent },
  { path: 'login', component: LoginComponent },
  { path: 'affiliate/login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'password-reset', component: PasswordResetComponent },
  {
    path: 'admin',
    loadChildren: (): Promise<AdminModule> =>
      import('./admin/modules/admin.module').then((m) => m.AdminModule),
    canLoad: [AdminGuard],
  },
  {
    path: 'company',
    loadChildren: (): Promise<CompanyModule> =>
      import('./company/modules/company.module').then((m) => m.CompanyModule),
    canLoad: [],
  },
  {
    path: 'affiliate',
    loadChildren: (): Promise<CompanyModule> =>
      import('./affiliate/modules/affiliate.module').then((m) => m.AffiliateModule),
    canLoad: [],
  },
  {
    path: 'customer',
    loadChildren: (): Promise<CustomerModule> =>
      import('./customer/modules/customer.module').then(
        (m) => m.CustomerModule
      ),
    canLoad: [],
  },
  { path : 'pricing', component : PricingComponent},
  /*
  { path: "logout", component: LogoutComponent },
  */
];
// company routes

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PasswordResetComponent,
    PricingComponent,
    PagesComponent,
    LogoutComponent,
    LandingComponent,
    ConfirmModalComponent,
    FilterPipe,
    NeedleGaugeComponent,
    CustomerLeadComponent,
    OrderUpsellItemComponent,
  ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        RouterModule.forRoot(appRoutes, {relativeLinkResolution: 'legacy'}),
        MomentModule,
        SharedModule,
        AffiliateModule,
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        TimepickerModule.forRoot(),
        TypeaheadModule.forRoot(),
        CarouselModule.forRoot(),
    ],
  providers: [
    CompanyCanActivateGuard,
    BaseProvider,
    AuthProvider,
    AdminGuard,
    AdminCanActivateGuard,
    AffiliateCanActivateGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
