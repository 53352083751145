import {Injectable} from '@angular/core';
import {API} from '../shared/providers/api.provider';
import {Customer} from '../interfaces/customer.interface';
import {User} from '../interfaces/user.interface';

@Injectable()
export class UserProvider {
  constructor(private api: API) {
  }

  signIn({email, password, companyId}) {
    return this.api.post('/auth/password', {
      email: email,
      password: password,
      companyId,
    });
  }
  signInUsingNonce(nonce) {
    return this.api.post('/auth/nonce', {nonce});
  }
  setPassword(nonce, password) {
    return this.api.post('/user/activate', {
      nonce: nonce,
      password: password,
    });
  }

  resetPassword(nonce, password) {
    return this.api.post('/user/password', {
      nonce: nonce,
      password: password,
    });
  }

  confirmEmail(nonce) {
    return this.api.post('/user/confirm-email', {
      nonce: nonce,
    });
  }

  requestNewPassword({email, _passwordResetUrl, companyId}) {
    return this.api.post('/auth/password-reset', {
      email: email,
      companyId,
      _passwordResetUrl: _passwordResetUrl,
    });
  }

  getUserInfo() {
    return this.api.get('/user/self');
  }

  getAll(search) {
    return this.api.get('/user', search);
  }

  getById(id) {
    return this.api.get(`/user/${id}`);
  }
  getByEmail(email, deliveryCompanyId) {
    const params: any = {};
    if (deliveryCompanyId) {
      params.deliveryCompanyId = deliveryCompanyId;
    }
    return this.api.get(`/user/email/${email}`, params, {disableToastr: true});
  }
  setStatus(id, body) {
    return this.api.patch(`/user/${id}/status`, body);
  }

  updateInfo(id, body) {
    return this.api.patch(`/user/${id}`, body);
  }

  updateNotActiveReason(id, body) {
    return this.api.patch(`/user/${id}/not-active-reason`, body);
  }

  getUserCards(id?: number, opts?: any) {
    if (!id) {
      // self card
      return this.api.get('/card', {}, opts);
    }
    return this.api.get(`/card/${id}`, {}, opts);
  }

  getUserDefaultCard() {
    return this.api.get('/card/default');
  }

  setUserDefaultCard(cardId: number, userId?: number) {
    return this.api.post(`/card/${cardId}/default`, {userId});
  }

  addUser(body): Promise<User> {
    return this.api.post('/user', body).then((res: any) => {
      return res.data as User;
    });
  }

  addCustomer(body): Promise<Customer> {
    return this.api.post('/user', body).then((res: any) => {
      return res.data as Customer;
    });
  }

  updateCustomer(id, body): Promise<Customer> {
    return this.api.patch('/user/' + id, body).then((res: any) => {
      return res as Customer;
    });
  }
  deleteCustomer({companyId, customerId}: {companyId: number, customerId: number}): Promise<Customer> {
    return this.api.delete(`/company/${companyId}/customer/${customerId}`).then((res: any) => {
      return res as Customer;
    });
  }
  addCard(token, userId?: number) {
    return this.api.post('/card', {token: token, userId});
  }

  validateUser(body) {
    // validates basic user fields(firstName, lastName, phone, email
    return this.api.post('/user/validate', body);
  }

  updateNotifications({userId, sendSms, sendEmail}) {
    return this.api.patch(`/user/${userId}/notifications`, {
      sendSms,
      sendEmail,
    });
  }

  importCustomer(payload: {firstName: string, lastName: string, email: string, phone: string, _activationUrl?: string}) {
    return this.api.post('/user/import', payload);
  }
  importCustomerCSV(data: any) {
    return this.api.defaultPost('/user/import-csv', data);
  }
  createPlaidToken(plaidData: {publicToken: any, accountId: any}): Promise<any> {
    const {publicToken, accountId} = plaidData;
    return this.api.post('/user/plaid/', {publicToken, accountId}).then((response: any) => {
      return {err: response.err, token: response.data ? response.data.token : false};
    });
  }
  resendInvitation (userId: number) {
    // resend the invitation/activation link that is sent out during customer import.
    // invite link sends users to <companyId>/register page
    return this.api.post(`/user/${userId}/resend-customer-invite`, {});
  }
  /*
  * @Return {string} - affiliate token
  * */
  initAffiliateSession(code: string, companyId?: number) {
    const jwt = localStorage.getItem('affiliateJWT');
    if (jwt) {
      return jwt;
    }
    return this.api.get(`/user/affiliate/session/${code}`, {companyId}).then((res: any) => {
      if (res.token) {
        localStorage.setItem('affiliateJWT', res.token);
      }
    }).catch((error: any) => {
      console.error('Failed to init affiliate session', error.message);
    });
  }
}
