<section class="page customer-lead">
  <div style="text-align: center">
    <img alt="fuel hero logo" src="/assets/img/fuel-hero-logo.png" class="logo img-fluid" style="max-height: 9em;">
  </div>
  <app-loader class="size-2" [loading]="loadingCustomer"></app-loader>

  <section class="customer-info" *ngIf="customerLead">
    <span class="highlight">{{customerLead.firstName}} {{customerLead.lastName}}</span>
    <ng-container *ngIf="customerLead.fill">
      would like you to <span class="highlight">Fill</span> their heating oil tank.
    </ng-container>
    <ng-container *ngIf="!customerLead.fill">
      would like to order <span class="highlight">{{customerLead.amount}}</span> gallons of heating oil from you.
    </ng-container>
    <ng-container *ngIf="customerLead.phone && customerLead.email">
      You may call them at
      <span class="highlight">{{customerLead.phone}}</span> or email them at
      <span class="highlight">{{customerLead.email}}</span>.
    </ng-container>
    <ng-container *ngIf="customerLead.phone && !customerLead.email">
      You may call them at
      <span class="highlight">{{customerLead.phone}}</span>.
    </ng-container>
    <ng-container *ngIf="customerLead.phone && !customerLead.email">
      You may email them at
      <span class="highlight">{{customerLead.email}}</span>.
    </ng-container>
  </section>
  <section class="customer-info expired" *ngIf="customerLeadExpired">
    <h6>You are too late, this customer has already accepted an offer from another company</h6>
  </section>
  <hr>
  If you would like a more convenient way to process orders, consider
  <a href="/company/setup">joining Current</a>.
</section>
