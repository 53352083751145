import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserProvider} from '../../../providers/user.provider';
import {BsModalService} from 'ngx-bootstrap/modal';
import {Customer} from '../../../interfaces/customer.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {Company} from '../../../interfaces/company.interface';
import {CompanyProvider} from '../../../providers/company.provider';
import {ToastrService} from 'ngx-toastr';
import {AuthProvider} from '../../../shared/providers/auth.provider';
import {Territory} from '../../../interfaces/territory.interface';
import {TerritoryProvider} from '../../../shared/providers/territory.provider';
import {ProductOrder} from '../../../interfaces/product-order.interface';
import {ProductOrderProvider} from '../../../shared/providers/product-order.provider';
import {StandaloneProduct} from '../../../interfaces/standalone-product.interface';
import {User} from '../../../interfaces/user.interface';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import {ImageProvider} from '../../../company/providers/image.provider';


@Component({
  selector: 'app-order-upsell-item',
  templateUrl: './order-upsell-item.component.html',
  styleUrls: ['./order-upsell-item.component.scss'],
  providers: [
    UserProvider,
    BsModalService,
    CompanyProvider,
    AuthProvider,
    TerritoryProvider,
    ImageProvider,
    { provide: CarouselConfig, useValue: { interval: 5000, noPause: true, showIndicators: true } }
  ],
})
export class OrderUpsellItemComponent implements OnInit {
  public product: StandaloneProduct;
  public customer: Customer = Customer();
  public productOrder: ProductOrder | undefined;
  public company: Company;
  public nonce: string;
  public loadingProduct = false;
  public page: 'landing' | 'zip' | 'register' | 'payment_info' | 'product_available' | 'page_order_success'| 'page_order_failed' | 'area_not_serviced' = 'landing';
  public zip = '';
  public territoryNotServiced = false;
  public creatingOrderIntent = false;
  public updatingShipping = false;
  public loadingOrderIntent = false;
  public savingChanges = false;
  public user: User; // currently logged-in user. Not necessarily the customer.
  public isPowerUser = false;
  public edit: '' | 'description' | 'title' | 'images' | 'label' = '';
  public savedProduct: any;
  public uploadingImage = false;
  @ViewChild('fileUpload', { static: false }) fileUpload:
    | ElementRef
    | undefined;
  constructor(
    private userProvider: UserProvider,
    private companyProvider: CompanyProvider,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private authProvider: AuthProvider,
    private territoryProvider: TerritoryProvider,
    private productOrderProvider: ProductOrderProvider,
    private imageProvider: ImageProvider,
  ) {
    this.customer.tank.estimatedOilAmount = undefined;
  }

  public async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(async (params) => {
      const companyId = parseInt(params.get('companyId'), 10);
      const productName = params.get('productName');
      if (!productName) {
        this.toastr.error('Error: Invalid product id', 'Invalid Product');
        return;
      }
      if (!companyId) {
        this.toastr.error('Error: Company not found', 'Invalid URL');
        return;
      }
      this.user = this.authProvider.getUser();
      if (this.user && this.user.role === 'customer') {
        this.customer = this.user as Customer;
      } else {
        this.isPowerUser = this.user && (this.user.role === 'company' || this.user.role === 'admin')
      }
      this.company = await this.companyProvider.getCompany(companyId) as Company;
      const response: { list: Territory[] } = await this.territoryProvider.getCompanyTerritories(
        this.company.id
      );
      const paymentIntentId = this.route.snapshot.queryParams.payment_intent;
      this.product = await this.companyProvider.getStandaloneProductByName({
        companyId: this.company.id,
        productName,
      });
      // override fields customized by the company.
      ['title', 'label', 'description', 'images'].forEach((field) => {
        if (!this.product.companyProduct[field]) {
          return;
        }
        this.product[field] = this.product.companyProduct[field];
      });
      // copy product so we can track unsaved changes
      this.savedProduct = JSON.parse(JSON.stringify(this.product));
      if (this.productIsDisabled()) {
        this.page = 'area_not_serviced';
        return;
      }
      if (paymentIntentId && this.product) {
        localStorage.removeItem('orderSession');
        let i;
        for (i = 5; i > 0; i--) {
          /*
          * Load the product order with its payment intent and check if Stripe redirect was successful.
          * Note: For some reason, for some payment methods, Stripe will say "source_required" even if valid source is provided.
          * Subsequent webhook update will fix that in a few seconds. Poll a few times to make sure that
          * the "source_required" really is an error and not just a timing issue.
          * */
          await this.loadOrderByIntent(paymentIntentId);
          const status = this.productOrder?.paymentIntent?.status;
          if (!['requires_source', 'requires_payment_method'].includes(status)) {
            break;
          }
          this.loadingOrderIntent = true;
          await this.wait(1000);
        }
        this.loadingOrderIntent = false;
        this.handleIntentStatus();
      }
      this.company.territories = response.list || [];
      try {
        const session = JSON.parse(localStorage.getItem('orderSession'));
        localStorage.removeItem('orderSession');
        if (session) {
          const {time, zip, productEnabled, page} = session;
          if ((Date.now() - parseInt(time, 10)) > 60000) {
            console.log('Previous session expired:', session);
          } else if (productEnabled) {
            this.zip = zip;
            this.page = page;
            await this.createOrderIntent();
            this.nextPage();
          }
        }
      } catch (error) {
        console.error('Failed to parse stored session');
      }
    });
  }
  private wait (time: number) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, time);
    });
  }
  public productIsDisabled () {
    return !this.product ||
      !this.product.enabled || /* admin has disabled the product */
      !this.product.companyProduct ||
      !this.product.companyProduct.enabled || /* company has disabled the product */
      typeof this.product.companyProduct.price !== 'number';
  }
  public customerRegistered ($event: { customer: Customer; last4CCDigits: number }) {
    this.customer = $event.customer;
    if (this.customer._token) {
      // log user in
      this.authProvider.setToken(this.customer._token);
      this.authProvider.setUser(this.customer);
    }
    this.toastr.success('Account setup successful', 'Success!');
    this.createOrderIntent();
    this.nextPage();
  }
  public async createOrderIntent () {
    try {
      this.creatingOrderIntent = true;
      const response: any = await this.productOrderProvider.post({
        companyId: this.company.id,
        productId: this.product.id,
        userId: this.customer.id,
      });
      if (response.data && response.data.data && response.data.data.id) {
        this.productOrder = response.data.data as ProductOrder;
      }
    } catch (error) {
      this.toastr.error('Failed to submit order:' + error.msg);
    }
    this.creatingOrderIntent = false;
  }
  public async loadOrderByIntent (intentId: string) {
    try {
      this.loadingOrderIntent = true;
      const response: any = await this.productOrderProvider.get({
        companyId: this.company.id,
        intentId,
      });
      if (response && response.id) {
        this.productOrder = response as ProductOrder;
      }
    } catch (error) {
      this.toastr.error('Failed to load order:' + error.message);
    }
    this.loadingOrderIntent = false;
  }
  public handleIntentStatus () {
    if (!this.productOrder || !this.productOrder.paymentIntent) {
      this.toastr.error('Could not load payment intent', 'Error!');
      return;
    }
    const status = this.productOrder.paymentIntent.status;
    if (status === 'succeeded') {
      return this.page = 'page_order_success';
    } else if (
      status === 'requires_source' ||
      status === 'requires_payment_method' ||
      this.productOrder.paymentIntent.lastPaymentError
    ) {
      return this.page = 'page_order_failed';
    } else if (status === 'processing') {
      return this.page = 'page_order_success';
    }
    console.error('Unhandled Intent Status', status);
  }
  public async nextPage () {
    if (this.page === 'landing') {
      return this.goZipPage();
    } else if (this.page === 'zip') {
      // we know the zip - check if product is available for this zip
      return this.goProductAvailabilityPage();
    } else if (this.page === 'product_available') {
      // product is available, and we know the price - go ask customer address etc
      return this.goRegisterPage();
    } else if (this.page === 'register') {
      // user has created an account - now we need payment info
      return this.goCollectPaymentInfo();
    } else {
      // other pages are determined by the status of payment intent.
      // success/fail/error pages
      console.log('Unhandled next page');
    }
  }
  private goZipPage () {
    this.page = 'zip';
    if (this.zip) {
      // we already have zip - go to next page
      return this.nextPage();
    }
  }
  private goProductAvailabilityPage () {
    // either show that product is available and show the price...or tell customer that product is not available.
    this.page = 'product_available';
    if (
      this.productIsDisabled() ||
      !this.company.territories ||
      !this.company.territories.filter(territory => territory.zip === this.zip).length
    ) {
      this.page = 'area_not_serviced';
    }
  }
  private goRegisterPage () {
    this.page = 'register';
    if (this.customer && this.customer.id) {
      // we already have customer logged in - go to next page
      return this.createOrderIntent()
        .then(this.nextPage.bind(this));
    }
    // store order session, registration may reload the page if user has an existing account.
    localStorage.setItem('orderSession', JSON.stringify({
      time: Date.now(),
      zip: this.zip,
      productEnabled: !this.productIsDisabled(),
      page: this.page,
    }));
  }
  private async goCollectPaymentInfo () {
    try {
      this.updatingShipping = true;
      await this.productOrderProvider.updateShippingAddress({
        companyId: this.company.id,
        orderId: this.productOrder.id
      });
    } catch (error) {
      console.error('Failed to update order shipping address:' + error.message);
    }
    this.updatingShipping = false;
    this.page = 'payment_info';
    // payment info is the last page - from there, customer leaves the site
    // and returns to this page with query params: payment_intent
  }
  isZipValid () {
    const zip = this.zip;
    return zip && zip.toString().length === 5;
  }
  public openFileSelect(): void {
    if (this.uploadingImage) {
      return;
    }
    const fileUpload = this.fileUpload?.nativeElement;
    fileUpload.onchange = async () => {
      try {
        this.uploadingImage = true;
        const file = fileUpload.files[0];
        const formData = new FormData();
        formData.append('image', file);
        const response: any = await this.imageProvider.uploadImage(formData);
        if (response.data.success) {
          const url = response.data.data;
          if (!this.product.images) {
            this.product.images = [];
          }
          this.product.images.push({url, title: ''});
          this.uploadingImage = false;
        }
      } catch (error) {
          this.uploadingImage = false;
          this.toastr.error('Failed to upload image:' + error.message, 'Error!')
      }
    };

    fileUpload.click();
  }
  removeProductImage (image) {
    this.product.images = this.product.images.filter(i => i !== image);
  }
  async saveProductChanges () {
    if (this.edit !== '') {
      // first click stops editing, so we can preview changes
      this.edit = '';
      return;
    }
    try {
      this.savingChanges = true;
      await this.companyProvider.updateStandaloneProduct({
        companyId: this.company.id,
        productId: this.product.id,
        title: this.product.title,
        label: this.product.label,
        description: this.product.description,
        images: this.product.images || null,
      });
      this.savedProduct = JSON.parse(JSON.stringify(this.product));
      this.toastr.success('Product info updated', 'Success!');
    } catch (error) {
      console.error('Failed to save changes to product', error.message);
    }
    this.savingChanges = false;
  }
  get hasUnsavedChanges () {
    let result = false;
    ['label', 'description', 'title'].forEach((field) => {
      if (this.product[field] !== this.savedProduct[field]) {
        result = true;
      }
    });
    if (JSON.stringify(this.product.images) !== JSON.stringify(this.savedProduct.images)) {
      return true;
    }
    return result;
  }
  getCheckoutReturnUrl () {
    return window.location.href;
  }
  get checkoutButtonText () {
    if (!this.productOrder || !this.productOrder.paymentIntent) {
      return 'Submit';
    }
    return 'Pay $' + (this.productOrder.paymentIntent.amount / 100).toFixed(2);
  }
  get descriptionHtml () {
    if (!this.product || !this.product.description) {
      return '';
    }
    return this.product.description.replace(/\n|\n\r|\r/gi, '<br>');
  }
}
