import {Component, ViewChild, OnInit} from '@angular/core';
import {PriceProvider} from '../../shared/providers/price.provider';
import {BsModalService} from 'ngx-bootstrap/modal';
import {API} from '../../shared/providers/api.provider';
import {ToastrService} from 'ngx-toastr';
import moment from 'moment';
import {OrderProvider} from '../../shared/providers/order.provider';
import {CompanyProvider} from '../../providers/company.provider';
import {CustomerLeadProvider} from '../../shared/providers/customer-lead.provider';
import {CustomerLead} from '../../shared/providers/customer-lead.provider';
import {ActivatedRoute} from '@angular/router';
import {UserProvider} from '../../providers/user.provider';
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  providers: [PriceProvider, CompanyProvider, UserProvider],
})
export class PricingComponent implements OnInit {
  /*
  * component that gives a brief introduction of Current and allows visitors to check the current price
  * If the price is right, they can move over to Order page.
  * */
  @ViewChild('orderModalTemplate', {static: true}) orderModalTemplate;
  @ViewChild('externalOrderModalTemplate', {static: true}) externalOrderModalTemplate;
  loading = false;
  zip: string;
  pricing: any[]; // pricing info for many companies, lowest first
  bestPrice: {
    companyId: number;
    companyName: string;
    companyLogoUrl: string;
    oil: {pricePerGallon: number};
    propane: {pricePerGallon: number};
  };
  showNoOrderReasons = false;
  notReadyToOrder = false;
  orderModalRef: any;
  externalModalRef: any;
  customerEmail: string;
  notifyPrice: string;
  currentAmount: number;
  kFactor: number;
  notificationDate: any;
  deliveryOptions: any;
  goodbyeMsg: string;
  deliveryDate: any;
  today = new Date();
  bsConfig  = { containerClass: 'theme-dark-blue' };
  subscribeToEvent: 'price_drops_below' | 'heating_season_begins' | 'churn' | 'other' | 'area_serviced';
  externalCompany: any; // reference to a company that is outside our network but services the area
  externalOrder: CustomerLead = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    amount: 0,
    fill: false,
    zip: '',
  };
  savingExternalInfo = false;
  externalInfoSaved = false;
  permission = true;
  affiliateToken = '';
  constructor(
    private priceProvider: PriceProvider,
    private modalService: BsModalService,
    private api: API,
    private orderProvider: OrderProvider,
    private companyProvider: CompanyProvider,
    private toastr: ToastrService,
    private customerLeadProvider: CustomerLeadProvider,
    private route: ActivatedRoute,
    private userProvider: UserProvider,
  ) {}
  ngOnInit () {
    if (this.route.snapshot.queryParams.af) {
      // affiliate code
      this.affiliateToken = this.route.snapshot.queryParams.af;
      this.userProvider.initAffiliateSession(this.route.snapshot.queryParams.af);
    }
  }
  gotoOrder () {
    const affiliateJwt = this.affiliateToken ? '?af=' + this.affiliateToken : '';
    window.location.href = '/' + this.bestPrice.companyId + '/order' + affiliateJwt;
  }
  get zipIsValid () {
    if (!this.zip) {
      return;
    }
    const zip = this.zip.trim().replace('-', '').match(/^\d+$/)[0];
    if (!zip || (zip.length !== 5 && zip.length !== 9)) {
      return;
    }
    return true;
  }
  get emailIsValid () {
    if (!this.customerEmail) {
      return ;
    }
    return !!this.customerEmail.match(/^(.+)@(.+)\.(.+)$/);
  }
  async onZipChange () {
    if (!this.zipIsValid) {
      console.log('invalid zip');
      return;
    }
    const wait = (time: number) => {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, time);
      });
    }
    this.loading = true;
    const result: any = await Promise.all([
      wait(1500), /* minimum waiting time to show we are crunching numbers */
      this.priceProvider.getAverageZipPricing(this.zip)
    ]);
    this.pricing = result[1];
    this.loading = false;
    if (!this.pricing.length) {
      // check if we have external companies who would be interested in this customer.
      this.externalCompany = await this.priceProvider.getExternalPricing(this.zip);
      if (this.externalCompany) {
        this.openExternalOrderModal();
        return;
      }
      this.subscribeToEvent = 'area_serviced';
      return;
    }
    this.bestPrice = this.pricing[0];
    await this.getDeliveryDates();
    await this.getDeliveryOptions();
    this.orderModalRef = this.modalService.show(this.orderModalTemplate, {
      ignoreBackdropClick: true,
    });
  }
  get lowestPrice () {
    if (!this.externalCompany) {
      return 0;
    }
    let price = this.externalCompany.defaultOilPricePerGallon;
    if (
      this.externalCompany.oilPricePerGallon &&
      moment.utc(this.externalCompany.updatedAt).isAfter(moment().subtract(2, 'days'))
    ) {
      // use ext company ppg if since it is up-to-date.
      price = this.externalCompany.oilPricePerGallon;
    }
    return price;
  }
  openExternalOrderModal () {
    this.externalModalRef = this.modalService.show(this.externalOrderModalTemplate, {
      ignoreBackdropClick: true,
    });
  }
  async setNotification () {
    // throw away dollar in case user entered $3.x
    this.notifyPrice = this.notifyPrice ? this.notifyPrice.trim().replace('$', '') : undefined;
    if (this.bestPrice && this.bestPrice.oil.pricePerGallon < parseFloat(this.notifyPrice)) {
      this.toastr.success('Current price is already lower than the price ($' + this.notifyPrice + '/gal) you are looking for.', 'Great News!')
      this.notReadyToOrder = false;
      this.subscribeToEvent = undefined;
      setTimeout(() => {
        this.orderModalRef = this.modalService.show(this.orderModalTemplate, {
          ignoreBackdropClick: true,
        });
      }, 2500);
      return;
    }
    try {
      this.loading = true;
      const {err, data} = await this.api.post('/event-subscription/' + this.subscribeToEvent, {
        email: this.customerEmail,
        zip: this.zip,
        date: this.notificationDate,
        pricePerGallon: this.notifyPrice,
        estimatedOilAmount: this.currentAmount,
        kFactor: this.kFactor,
      }) as any;
      if (data && data.id) {
        const messages = {
          'price_drops_below': 'We will notify you when price drops below $' + this.notifyPrice + '/gal',
          'heating_season_begins': 'We will notify you when heating season begins: ' + moment(this.notificationDate).format('Do MMM, YYYY'),
          'churn' : 'We will notify you when you are in danger of running out of heating oil!',
          'other': 'We will remind you to order heating oil at ' + moment(this.notificationDate).format('Do MMM, YYYY'),
          'area_serviced': 'We will notify you when we start providing service in your area!'
        };
        this.goodbyeMsg = messages[this.subscribeToEvent];
        this.toastr.success(this.goodbyeMsg, 'Success!');
      }
      if (err && err.msg) {
        this.toastr.error(err.msg, 'Error!');
      }
    } catch (error) {
      if (error.msg && error.msg.toLowerCase().includes('validation')) {
        this.toastr.error('Error: ' + error.message, 'Error!');
      }
    }
    this.loading = false;
  }
  async getDeliveryDates () {
    this.deliveryDate = undefined;
    const dates: any = await this.orderProvider.getDeliveryDates({
      companyId: this.bestPrice.companyId,
      product: 'oil',
      zip: this.zip
    });
    if (dates && dates.date) {
      this.deliveryDate = moment.utc(dates.date).format('Do MMM');
    }
  }
  async getDeliveryOptions (): Promise<void> {
    this.deliveryOptions = undefined;
    try {
      this.deliveryOptions = await this.companyProvider
        .getDeliveryOptions({
          companyId: this.bestPrice.companyId,
          zip: this.zip,
          parseList: true,
        }) as any
    } catch (error) {
      console.error('Could not load delivery options', error.message);
    }
  }
  onDateChange (evt) {
    console.log('onDateChange', evt);
  }
  async submitExternalOrder () {
    if (!this.validateExternalOrder(true)) {
      return;
    }
    const payload: any = {...this.externalOrder};
    payload.zip = this.zip;
    if (payload.email && !payload.email.match(/^(.+)@(.+)\.(.+)$/)) {
      return this.toastr.error('Invalid email address', 'Error!');
    }
    if (
      payload.phone && !payload.phone
        .replace(/\D/gi, '')
        .replace(/^1([+0-9]{10})$/i, '$1')
        .match(/^[+0-9]{10}$/i)
    ) {
      return this.toastr.error('Invalid phone number', 'Error!');
    }
    if (payload.amount === 'fill') {
      payload.fill = true;
      delete payload.amount;
    }
    if (!payload.fill && !payload.amount) {
      return this.toastr.error('Please enter the number of gallons you would like to order');
    }
    // delete empty keys
    Object.keys(payload).forEach((field) => {
      if (!payload[field]) {
        delete payload[field];
      }
    });
    this.savingExternalInfo = true;
    const response: any = await this.customerLeadProvider.createCustomerLead(payload);
    this.toastr.success('Info submitted successfully', 'Success!');
    this.savingExternalInfo = false;
    this.externalInfoSaved = true;
  }
  validateExternalOrder (toast = false) {
    const {email, phone, firstName, lastName} = this.externalOrder;
    if (!this.permission) {
      if (toast) {
        this.toastr.error('We need your permission to contact you!', 'Error');
      }
      return false;
    }
    if (!email && !phone) {
      if (toast) {
        this.toastr.error('Please enter email or phone', 'Error!');
      }
      return false;
    }
    if (!firstName && !lastName) {
      if (toast) {
        this.toastr.error('Please enter first name or last name', 'Error!');
      }
      return false;
    }
    return true;
  }
}
