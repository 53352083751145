import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserProvider} from '../../../providers/user.provider';
import {AuthProvider} from '../../providers/auth.provider';
import {Customer} from '../../../interfaces/customer.interface';
import {environment} from '../../../../environments/environment';
import {ToastrService} from 'ngx-toastr';

declare var Stripe: any;
declare let Plaid: any;

@Component({
  selector: 'app-stripe-card',
  templateUrl: './stripe-card.component.html',
  styleUrls: ['./stripe-card.component.scss'],
  providers: [UserProvider]
})

export class StripeCardComponent implements OnInit {
  @Input() achDiscount;
  @Input() skipCCEnabled = false;
  @Input() nextBtnText = '';
  @Input() skipBtnText = 'Skip';
  @Input() public classes: string;
  @Input() public title: '';
  @Input() public card: any;
  @Input() public customer: Customer;
  @Output()
  public onNext = new EventEmitter<{ card: any, token: any }>();
  @Output()
  public onSkip = new EventEmitter<void>();
  public connectedBankAccount: any;
  public stripe: any;
  public cardError: string;
  public cardIsValid: boolean;
  public cardElement: any;
  public plaid = null;
  public plaidData = null;
  private cards: any;
  constructor(
    public authProvider: AuthProvider,
    private userProvider: UserProvider,
    private toastr: ToastrService,
  ) {

  }
  ngOnInit() {
    this.initStripe();
    this.initPlaid();
  }
  getCards() {
    if (!this.customer.id) {
      return ;
    }
    // ToDo: create card selection
    this.userProvider.getUserCards(this.customer.id)
      .then((response: any) => {
        this.cards = response.list;
        this.card = this.cards.some(card => card.isDefault) ? this.cards.filter(card => card.isDefault === true)[0] : this.card;
        this.connectedBankAccount = this.cards.filter(card => card.object === 'bank_account')[0];
        this.cardIsValid = true;
      });
  }
  initStripe() {
    this.stripe = Stripe(environment.Stripe.API_KEY);
    const elements = this.stripe.elements();
    this.getCards();
    if (this.card) {
      this.onNext.next({card: this.card, token: false});
    }
    const style = {
      base: {
        borderColor : '#000',
        color: '#303238',
        fontSize: '16px',
        lineHeight: '48px',
        fontSmoothing: 'antialiased'
      },
      invalid: {
        color: '#e5424d',
        ':focus': {
          color: '#303238',
        },
      },
    };
    this.cardElement = elements.create('card', {style});
    this.cardElement.mount('#card-element');
    this.cardElement.addEventListener('change', (event) => {
      this.cardIsValid = event.complete && !event.error;
      this.cardError = event.error ? event.error.message : '';
      if (this.cardIsValid && !this.nextBtnText) {
        this.emitCard();
      }
    });
  }
  initPlaid () {
    // enable connecting bank account to Stripe using Plaid
    if (typeof Plaid === 'object') {
      this.plaid = Plaid.create({
        selectAccount: true,
        env: environment.production ? 'production' : 'sandbox',
        clientName: 'Current',
        key: environment.Plaid.PUBLIC_KEY,
        product: ['auth'],
        onSuccess: (publicToken, metadata) => {
          this.plaidData = {
            publicToken: publicToken,
            accountId: metadata.account_id,
            metadata: metadata
          };
          this.emitCard();
        },
        onExit: (err, metadata) => {
          this.toastr.error(err.message, 'Plaid Error!');
          console.log('err', err, 'metadata', metadata);
        },
      });
    } else {
      setTimeout(() => {
        this.initPlaid();
      }, 100);
    }
  }
  connectPlaid() {
    this.plaid.open();
  }
  skip () {
    this.onSkip.emit();
  }
  async emitCard () {
    try {
      const result = this.plaidData ?
        await this.userProvider.createPlaidToken(this.plaidData) :
        await this.stripe.createToken(this.cardElement);
      if (result.token && result.token.id) {
        return this.onNext.next({card: result.token.card, token: result.token});
      }
      this.toastr.error('Error: Invalid CC token', 'Whoops!');
    } catch (error) {
      this.toastr.error('Error: ' + error.message, 'Whoops!');
    }
  }
  public get achDiscountEnabled () {
    if (this.connectedBankAccount || !this.achDiscount || !this.achDiscount.value) {
      return false;
    }
    return parseFloat(this.achDiscount.value) > 0
  }
}
