import { Injectable } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import {AuthProvider} from '../shared/providers/auth.provider';
@Injectable()
export class AdminGuard implements CanLoad {

  constructor(private router: Router, public authProvider: AuthProvider) {
  }

  canLoad(route: Route): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (route.path === 'admin') {
        const user = this.authProvider.getUser();
        if (!user || user.role !== 'admin') {
          alert('You are not authorised to visit this page');
          return reject(false);
        }
        resolve(true);
      }
      resolve(true);
    });
  }
}
