import { Injectable } from '@angular/core';
import { API } from '../shared/providers/api.provider';

@Injectable()
export class CompanyProvider {
  constructor(
    private api: API
  ) {}

  getCompany(id) {
    return this.api.get(`/company/${id}`);
  }
  getCompanies(): Promise<{ _metadata: any, list: Array<any> }> {
    return this.api.get('/company') as any;
  }

  updateCompany(id, body) {
    return this.api.patch(`/company/${id}`, body);
  }

  approveCompany(id) {
    return this.api.patch(`/company/${id}/status`, {status : 'approved'});
  }
  unApproveCompany(id) {
    return this.api.patch(`/company/${id}/status`, {status : 'unapproved'});
  }

  getDeliveryOptions(
    {companyId, zoneId, zip, parseList = false}:
    {companyId: number, zoneId?: any, zip?: any, parseList?: boolean}
  ) {
    const search: any = {
      parseList
    }
    if (parseInt(zoneId, 10) > 0) {
      search.zoneId = zoneId;
    }
    if (zip) {
      search.zip = zip;
    }
    return this.api.get(`/company/${companyId}/delivery-options`, search);
  }
  getAdditionalOptions(
    {companyId, zoneId, zip, parseList = false}:
    {companyId: number, zoneId?: any, zip?: any, parseList?: boolean}
  ) {
    const search: any = {
      parseList
    }
    if (parseInt(zoneId, 10) > 0) {
      search.zoneId = zoneId;
    }
    if (zip) {
      search.zip = zip;
    }
    return this.api.get(`/company/${companyId}/additional-products`, search);
  }
  updateDeliveryOptions(id, body) {
    return this.api.patch(`/company/${id}/delivery-options`, body);
  }

  bindTerritory(id, zip) {
    return this.api.put(`/company/${id}/territory/${zip}`, {});
  }

  unbindTerritory(id, zip) {
    return this.api.delete(`/company/${id}/territory/${zip}`);
  }
  getStripeLoginLink (id) {
    return this.api.get(`/company/${id}/stripe-login`);
  }
  addZone ({companyId, name, state}) {
    return this.api.put(`/company/${companyId}/zone`, {name, state});
  }
  addZoneTerritory ({companyId, zoneId, territoryId}) {
    return this.api.put(`/company/${companyId}/zone/${zoneId}/territory/${territoryId}`, {});
  }
  removeZoneTerritory ({companyId, zoneId, territoryId}) {
    return this.api.delete(`/company/${companyId}/zone/${zoneId}/territory/${territoryId}`, {});
  }
  deleteZone (id, zoneId) {
    return this.api.delete(`/company/${id}/zone/${zoneId}`);
  }
  getZones ({companyId}) {
    return this.api.get(`/company/${companyId}/zone`, {});
  }
  getStandaloneProducts ({companyId}: {companyId: number}) {
    return this.api.get(`/company/${companyId}/product/list`, {}).then((res: any) => {
      return res.list.map((product) => {
        if (!product.companyProduct) {
          return product;
        }
        product.companyProduct._savedPrice = product.companyProduct.price;
        return product;
      }) || [];
    });
  }
  getStandaloneProductByName ({companyId, productName}: {companyId: number; productName: string; }) {
    return this.api.get(`/company/${companyId}/product`, {productName}).then((product: any) => {
      if (!product.companyProduct) {
        return product;
      }
      product.companyProduct._savedPrice = product.companyProduct.price;
      return product;
    });
  }
  updateStandaloneProduct (
    {companyId, productId, enabled, price, title, label, description, images}:
    {companyId: number; productId: number; enabled?: boolean; price?: number; title?: string; label?: string; description?: string; images?: any}
  ) {
    return this.api.patch(`/company/${companyId}/product/${productId}`, {
      enabled, price, title, label, description, images
    });
  }
}
