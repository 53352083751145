import { Injectable } from '@angular/core';
import { API } from '../shared/providers/api.provider';
import {Customer} from '../interfaces/customer.interface';

@Injectable()
export class CustomerProvider {
  constructor(
    private api: API
  ) {}

  getAll({companyId, params}: {companyId?: number, params?: Object}) {
    const url = companyId ? `/company/${companyId}/customer` : '/admin/customer';
    return this.api.get(url, params);
  }

  getCustomer({companyId, customerId}) {
    const path = companyId ? `/company/${companyId}/customer/${customerId}` : `/admin/customer/${customerId}`;
    return this.api.get(path);
  }
  assignCustomer({companyId, customerId}) {
    return this.api.put(`/admin/customer/${customerId}/assign-to/${companyId}`, {});
  }
  getNewCustomers({companyId, _limit, _offset, _sort}) {
    const path = companyId ? `/company/${companyId}/customer/new` : `/admin/customer/new`;
    return this.api.get(path, {_offset, _limit, _sort});
  }
  getCustomerByEmail(email) {
    email = encodeURIComponent(email);
    return this.api.get(`/customer/email/` + email).then((res: Customer) => {
      return res;
    });
  }
  getCustomerByPhone({phone}) {
    phone = encodeURIComponent(phone);
    return this.api.get(`/customer/phone/` + phone).then((res: Customer) => {
      return res;
    });
  }
  /*
  * @return {Object} - {
  *   customer: Customer,
  *   token: string <- for logging in the user after they have proven their identity via cc + phone.
  * }
  * */
  getCustomerByLastCCDigits({phone, ccDigits}) {
    phone = encodeURIComponent(phone);
    return this.api.get('/customer/cc', {phone, ccDigits});
  }
  updateCustomer({companyId, customerId, params}: {companyId?: number, customerId?: number, params?: Object}) {
    return this.api.patch(`/company/${companyId}/customer/` + customerId, params);
  }
  updateCustomerNotes({companyId, customerId, notes}: {companyId?: number, customerId?: number, notes: string}) {
    return this.api.patch(`/company/${companyId}/customer/${customerId}/customer-notes`, {notes});
  }
  updateNotifications ({userId, sendSms, sendEmail}) {
    return this.api.patch(`/user/${userId}/notifications`, {sendSms, sendEmail});
  }
  getCustomerStats({customerId, startDate, endDate}: {customerId: number, startDate?: string, endDate?: string}) {
    return this.api.get(`/customer/${customerId}/statistics`, {startDate, endDate});
  }
}
