import { map } from "rxjs/operators";
import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import "rxjs/Rx";
import { AuthProvider } from "./auth.provider";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../../environments/environment";

const API_HOST = environment.apiURL;

@Injectable()
export class API {
  private token: any;

  constructor(
    public httpClient: HttpClient,
    public authProvider: AuthProvider,
    public toastr: ToastrService
  ) {}
  private getHeaders () {
    const headers = {
      "Content-Type": "application/json",
      Authorization: this.authProvider.getToken() || "",
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
    };
    const affiliateJWT = localStorage.getItem('affiliateJWT');
    if (affiliateJWT) {
      headers['Affiliate-JWT'] = affiliateJWT;
    }
    return new HttpHeaders(headers);
  }
  get(query, search = {}, options = {}) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get<any>(API_HOST + query, {
          headers: this.getHeaders(),
          params: API.AddHttpParams(search),
          observe: "response",
          responseType: "json",
        })
        .pipe(
          map((res: any) => {
            if (res.status < 200 || res.status >= 300) {
              this.handleError(res, options);
              console.log(
                "Problem with " + query + " " + this.authProvider.getToken()
              );
            } else {
              return res.body;
            }
          })
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.handleError(err, options);
            console.log(
              "Problem with " + query + " " + this.authProvider.getToken()
            );
            reject(err);
          }
        );
    });
  }

  post(query, body, options = {}) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<any>(API_HOST + query, API.deleteUndefinedKeys(body), {
          headers: this.getHeaders(),
          observe: "response",
          responseType: "json",
        })
        .pipe(
          map((res: any) => {
            if (res.status < 200 || res.status >= 300) {
              this.handleError(res, options);
            } else {
              return res.body;
            }
          })
        )
        .subscribe(
          (data) => {
            resolve({ err: null, data: data });
          },
          (err) => {
            this.handleError(err, options);
            reject(err);
          }
        );
    });
  }

  defaultPost(query, body, options = {}) {
    const headers = new HttpHeaders({
      Authorization: this.authProvider.getToken() || "",
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
    });

    return new Promise((resolve, reject) => {
      this.httpClient
        .post<any>(API_HOST + query, API.deleteUndefinedKeys(body), {
          headers,
          observe: "response",
          responseType: "json",
        })
        .pipe(
          map((res: any) => {
            if (res.status < 200 || res.status >= 300) {
              this.handleError(res, options);
            } else {
              return res.body;
            }
          })
        )
        .subscribe(
          (data) => {
            resolve({ err: null, data: data });
          },
          (err) => {
            this.handleError(err, options);
            reject(err);
          }
        );
    });
  }

  delete(query, options = {}) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .delete<any>(API_HOST + query, {
          headers: this.getHeaders(),
          observe: "response",
          responseType: "json",
        })
        .pipe(
          map((res: any) => {
            if (res.status < 200 || res.status >= 300) {
              this.handleError(res, options);
            } else {
              return res.body;
            }
          })
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.handleError(err, options);
            reject(err);
          }
        );
    });
  }

  put(query, body, options = {}) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put<any>(API_HOST + query, API.deleteUndefinedKeys(body), {
          headers: this.getHeaders(),
          observe: "response",
          responseType: "json",
        })
        .pipe(
          map((res: any) => {
            if (res.status < 200 || res.status >= 300) {
              this.handleError(res, options);
            } else {
              return res.body;
            }
          })
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.handleError(err, options);
            reject(err);
          }
        );
    });
  }

  patch(query, body, options = {}) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .patch<any>(API_HOST + query, API.deleteUndefinedKeys(body), {
          headers: this.getHeaders(),
          observe: "response",
          responseType: "json",
        })
        .pipe(
          map((res: any) => {
            if (res.status < 200 || res.status >= 300) {
              this.handleError(res, options);
            } else {
              return res.body;
            }
          })
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            this.handleError(err, options);
            reject(err);
          }
        );
    });
  }

  handleError(err, { disableToastr }: { disableToastr? } = {}) {
    if (!disableToastr) {
      try {
        const error = err.body || err.error || err;
        let validationMsg = '';
        if (error.msg && error.msg.indexOf('Validation') !== -1 && error.errors) {
          // get the first failed validation error msg.
          const validationError: any = Object.values(error.errors)[0];
          if (validationError && validationError.message) {
            validationMsg = ': ' + validationError.message;
          }
        }
        if (typeof error.error === 'string') {
          this.toastr.error(error.error, "Whoops!");
        } else {
          this.toastr.error(error.msg + validationMsg, "Whoops!");
        }
      } catch (err) {
        console.log(err);
      }
    }
    /*
    if (err.status === 401) {
      // ToDo: uncomment
      localStorage.clear();
      setTimeout(() => {
        // give the user some time to read error msg
        window.location.pathname = "/login";
      }, 3000);
    }*/
  }
  static deleteUndefinedKeys(obj) {
    Object.keys(obj).forEach((field) => {
      if (obj[field] === undefined) {
        delete obj[field];
      }
    });
    return obj;
  }
  static AddHttpParams(search) {
    if (typeof search === "object") {
      // delete undefined values or they will become strings "undefined"
      API.deleteUndefinedKeys(search);
      return new HttpParams({ fromObject: search });
    }
    if (typeof search === "string") {
      return new HttpParams({ fromString: search });
    }
    throw new Error("Unhandled Http Params type");
  }
  static compileSearchString(search) {
    let searchString = "";
    Object.keys(search).forEach((key) => {
      const value = search[key];
      if (value === undefined) {
        return;
      }
      if (Array.isArray(value)) {
        value.forEach((_value) => {
          searchString += `&${key}=${encodeURIComponent(_value)}`;
        });
        return;
      }
      if (!!value && typeof value === "object") {
        Object.keys(value).forEach((subkey) => {
          searchString += `&${key}[${subkey}]=${encodeURIComponent(
            value[subkey]
          )}`;
        });
        return;
      }
      searchString += `&${key}=${encodeURIComponent(value)}`;
    });
    return searchString.substr(1);
  }
}
