import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {CompanyProvider} from '../../../providers/company.provider';
import {Product} from '../../../interfaces/product.interface';
@Component({
  selector: 'app-additional-products-dropdown',
  templateUrl: './additional-products-dropdown.component.html',
  styleUrls: ['./additional-products-dropdown.component.scss'],
  providers: [],
})
export class AdditionalProductsDropdownComponent implements OnInit {
  /*
  * This component loads and provides additional products for selection:
  *   next_day_delivery,
  *   same_day_delivery,
  *   prime,
  *   tune_up,
  *   small_delivery_fee
  * */
  @Input() btnClass = '';
  /*
  * At the moment, there are 2 kinds of "products" that users can select in addition to ordered gallons.
  * Delivery options: one day, two day, next day rush delivery
  * Upsell options: tune up, emergency prime
  * */
  @Input() productListType: 'delivery' | 'upsell' = 'delivery';
  @Input() buttonClosedText = 'select products';
  @Input() triggerType: 'button' | 'link' = 'button';
  @Input() alwaysOpen = false;
  @Input() hideDisabledOptions = true;
  @Input() products: Array<Product> = [];
  @Input() companyId: number;
  @Output() onChange = new EventEmitter<{product: Product, products: Product[]}>();
  // Emits product list once its loaded, for use outside this component
  @Output() onProductsLoaded = new EventEmitter<Product[]>();
  public loadingProducts = false;
  constructor(private companyProvider: CompanyProvider) {}
  async ngOnInit() {
    if (!this.products || !this.products.length) {
      if (!this.companyId) {
        console.log('Additional product dropdown requires companyId or products array');
        return;
      }
      this.loadingProducts = true;
      if (this.productListType === 'delivery') {
        this.products = await this.companyProvider.getDeliveryOptions({
          companyId: this.companyId,
          parseList: true
        }) as any;
      } else if (this.productListType === 'upsell') {
        this.products = await this.companyProvider.getAdditionalOptions({
          companyId: this.companyId,
          parseList: true
        }) as any;
      } else {
        return console.error('Additional product dropdown is missing required parameter productListType');
      }

      this.loadingProducts = false;
      this.onProductsLoaded.emit(this.products);
    }
    if (this.hideDisabledOptions) {
      this.products = this.products.filter(p => p.enabled);
    }
  }

  onSelect (product: Product) {
    this.onChange.emit({
      product,
      products: this.products
    });
  }
  onShowAdditionalProducts () {
    setTimeout(() => {
      const e = document.getElementById('product-additional');
      const lastChild: any = e.children[e.children.length - 1];
      if (lastChild && lastChild.scrollIntoViewIfNeeded) {
        lastChild.scrollIntoViewIfNeeded();
      }
    }, 200);
  }
  get additionalProductBtnText () {
    if (!this.products || !this.products.length) {
      return 'no options available';
    }
    const selectedProducts = this.products.filter(p => p.selected);
    if (!selectedProducts.length) {
      return this.buttonClosedText;
    } else if (selectedProducts.length === 1) {
      return selectedProducts[0].label;
    }
    const s = selectedProducts.length > 1 ? 's' : '';
    return `${selectedProducts.length}  product${s} selected`;
  }
}
