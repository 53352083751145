import {Address} from './address.interface';

type TankLocation = 'left' | 'back' | 'right' | 'front';
type GroundLevel = 'above' | 'below';
type GasType = 'propane' | 'natural_gas' | 'methane';
export interface GasTank {
  id?: number,
  size: number | null,
  userId?: number,
  companyId?: number,
  addressId?: number,
  location: TankLocation,
  description: string,
  groundLevel: GroundLevel,
  gas: GasType,
  estimatedAmount: number | null,
  daysSinceChurned: number | null,
  manuallyAdjusted: boolean,
  estimatedAt?: string,
  createdAt?: string,
  updatedAt?: string,
  address?: Address,
  inspected?: boolean,
  ownership?: boolean, // true if customer owns the tank
}
export const GasTank = (fieldValues?): GasTank => {
  const tank: GasTank = {
    size: 0,
    userId: undefined,
    companyId: undefined,
    addressId: undefined,
    location: 'left',
    groundLevel: undefined,
    gas: 'propane',
    description: '',
    estimatedAmount: undefined,
    daysSinceChurned: undefined,
    manuallyAdjusted: false,
    address: Address()
  };
  if (fieldValues) {
    Object.assign(tank, fieldValues)
  }
  return tank;
};
