import { Injectable } from "@angular/core";
import { API } from "../../shared/providers/api.provider";
import { BaseProvider } from "../../shared/providers/base.provider";

@Injectable()
export class ImageProvider extends BaseProvider {
  constructor(private api: API) {
    super();
  }
  uploadImage(data: any) {
    return this.api.defaultPost(`/image`, data);
  }
}
