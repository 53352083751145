import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AuthProvider } from "app/shared/providers/auth.provider";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

@Component({
  selector: "sidebar-overlay",
  templateUrl: "./sidebar-overlay.component.html",
  styleUrls: ["./sidebar-overlay.component.scss"],
})
export class SidebarOverlayComponent {
  @Input() show: boolean = false;
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  public user;
  public sideMenuLinks: Array<{
    href?: string;
    label: string;
    icon?: string;
    id?: string;
  }> = [];

  private subscriptions: Subscription[] = [];

  constructor(private router: Router, private authProvider: AuthProvider) {}

  public ngOnInit(): void {
    const s1 = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.user = this.authProvider.getUser();
        this.setMenuLinks();
      });

    this.subscriptions.push(s1);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  public closeSidebar(): void {
    this.show = false;
    this.onClose.emit();
  }

  private setMenuLinks() {
    if (!this.user) {
      return;
    }

    switch (this.user.role) {
      case "admin": {
        this.sideMenuLinks = this.getAdminMenuLinks();
        return;
      }

      case "customer": {
        this.sideMenuLinks = this.getCustomerMenuLinks();
        return;
      }

      case "company": {
        this.sideMenuLinks = this.getCompanyMenuLinks();
        return;
      }
    }
  }

  private getAdminMenuLinks() {
    return [
      {
        href: `/admin/dashboard`,
        label: "Dashboard",
        icon: "dashboard-computer.png",
      },
      {
        href: `/admin/customers`,
        label: "Customers",
        icon: "dashboard-customer.png",
      },
      {
        href: `/admin/products`,
        label: "Products",
        icon: "dashboard-product.png",
      },
      {
        href: `/admin/companies`,
        label: "Companies",
        icon: "dashboard-truck.png",
      },
    ];
  }

  private getCustomerMenuLinks() {
    return [
      {
        href: `customer/dashboard`,
        label: "Dashboard",
        icon: "dashboard-computer.png",
      },
      {
        href: `customer/dashboard/deliveries`,
        label: "Deliveries",
        icon: "dashboard-customer.png",
      },
      /*
      {
        href: `customer/usage`,
        label: "Usage",
        icon: "dashboard-product.png",
      },
      {
        href: `customer/my-tank`,
        label: "My Tank",
        icon: "dashboard-truck.png",
        id: "truck-btn",
      },*/
    ];
  }

  private getCompanyMenuLinks() {
    return [
      {
        href: `company/dashboard`,
        label: "Dashboard",
        icon: "dashboard-computer.png",
      },
      {
        href: `company/customers`,
        label: "Customers",
        icon: "dashboard-customer.png",
      },
      {
        href: `company/products`,
        label: "Products",
        icon: "dashboard-product.png",
      },
      {
        href: `company/us`,
        label: "Company",
        icon: "dashboard-truck.png",
        id: "truck-btn",
      },
      {
        href: `company/import-customer`,
        label: "Activate",
        icon: "dashboard-import.png",
        id: "import-btn",
      },
      {
        href: `company/messages`,
        label: "Messaging",
        icon: "dashboard-messaging.png",
        id: "messaging-btn",
      },
    ];
  }
}
