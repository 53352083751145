import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'phone' })
export class PhoneNumberPipe implements PipeTransform {
  transform(value: any): any {
    if (!value) { return; }
    if (typeof value !== 'string') {
      value = value.toString();
    }
    const origValue = value;
    // get last 10 digits, ignoring +1 prefix if present
    value = value.match(/[0-9]+/g).join('').slice(-10);
    const prefix = '+1';
    // format +1 (xxx) 222-6575
    if (value.length === 10) {
      return `${prefix} (${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`
    }
    // partial/faulty number - return it as it is.
    return origValue;
  }
}
