<div class="steps d-block">
  <div class="stepper-wrapper">
    <div
      class="stepper-item"
      *ngFor="let step of steps; let i = index"
      [class.completed]="i >= currentStep"
      [class.active]="i === currentStep"
    >
      <img
        src="./assets/img/icn-truck.svg"
        class="truck"
        alt="truck icon"
        *ngIf="i === currentStep"
      />

      <div class="step-counter"></div>
      <div class="step-name">{{ step.label }}</div>
    </div>
  </div>
</div>
