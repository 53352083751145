import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthProvider} from '../../shared/providers/auth.provider';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  providers: [AuthProvider],
})
export class LandingComponent implements OnInit {
  public companyId: number;

  constructor(
    private authProvider: AuthProvider,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  public ngOnInit() {
    this.companyId = this.route.snapshot.params.companyId;
    const user = this.authProvider.getUser();

    if (!user) {
      this.router.navigateByUrl(this.companyId ? `/${this.companyId}/login` : '/login');
      return;
    }

    switch (user.role) {
      case 'admin': {
        this.router.navigateByUrl('/admin/dashboard');
        return;
      }

      case 'company': {
        this.router.navigateByUrl('/company/dashboard');
        return;
      }

      case 'customer': {
        this.router.navigateByUrl('/customer/dashboard');
        return;
      }
    }
  }
}
